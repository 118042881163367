import React from 'react';
import EmployeeFeatures from './EmployeeFeatures';
import AssetAndLiabilityFeatures from './AssetAndLiabilityFeatures';
import CustomGrid from '../CustomGrid';
import { Stack } from '@mui/system';
import BuyNowPayLater from './BuyNowPayLater';

const InsuranceFeatures = () => {
	return (
		<CustomGrid>
			<Stack rowGap={6} my={4}>
				<AssetAndLiabilityFeatures />
				<EmployeeFeatures />
				<BuyNowPayLater />
			</Stack>
		</CustomGrid>
	);
};

export default InsuranceFeatures;
