import React from 'react';
import { useTranslation } from 'react-i18next';
import ApplicantDetailsOthers from '../../component/insuranceFormComponents/applicantDetails/others';
import ContactDetails from '../../component/insuranceFormComponents/contact/ContactDetails';
import TypeOfInsurance from '../../component/insuranceFormComponents/typeOfInsurance/TypeOfInsurance';
import { INSURANCE_TYPE, QUOTATION_FORM_TABS } from '../../constant/insurance';
import { useInsuranceFormContext } from '../../context/InsuranceFormContext';

const Others = () => {
	const { t } = useTranslation();
	const { activeMenu }: any = useInsuranceFormContext();

	const renderFormType = () => {
		switch (activeMenu) {
			case QUOTATION_FORM_TABS.TYPE_OF_INSURANCE:
				return <TypeOfInsurance type={INSURANCE_TYPE.ASSET_LIABILITY} nextTab={QUOTATION_FORM_TABS.APPLICATION_DETAILS} />;
			case QUOTATION_FORM_TABS.APPLICATION_DETAILS:
				return <ApplicantDetailsOthers prevTab={QUOTATION_FORM_TABS.TYPE_OF_INSURANCE} nextTab={QUOTATION_FORM_TABS.CONTACT_DETAILS} />;
			case QUOTATION_FORM_TABS.CONTACT_DETAILS:
				return <ContactDetails prevTab={QUOTATION_FORM_TABS.APPLICATION_DETAILS} />;
		}
	};

	return <>{renderFormType()}</>;
};

export default Others;
