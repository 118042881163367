/* eslint-disable react/no-unescaped-entities */
import { Box, Card, CardContent, Divider, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Background from '../assets/webP/background.webp';
import BackgroundMobile from '../assets/webP/mobileBackground.webp';
import StarIcon from '@mui/icons-material/Star';
import { useScreenViews } from '../effects/useScreenViews';
import CustomGrid from './CustomGrid';
import CarouselAutoplay from './CarouselAutoplay';
import { CUSTOMER_FEEDBACK_SLIDESHOW } from '../constant/carouselSlides';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()((theme) => ({
	backWrap: {
		width: '100%',
		backgroundImage: `url(${Background})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		overflowX: 'visible',
		paddingBottom: '64px',
		[theme.breakpoints.down('sm')]: {
			backgroundImage: `url(${BackgroundMobile})`
		}
	},
	wrapCard: {
		rowGap: '1rem',
		textAlign: 'left',
		padding: '2.5rem',
		[theme.breakpoints.down('sm')]: {
			padding: '1rem'
		}
	},
	cardHeight: {
		height: '100%',
		minHeight: 400,
		[theme.breakpoints.down('md')]: {
			minHeight: 470
		}
	},
	img: {
		width: 80,
		height: 80
	},
	divider: {
		height: '21px',
		borderRightWidth: 2,
		color: theme.surfaceVariants?.secondary?.onSurface
	}
}));

const CustomerFeedbacks = (props: any) => {
	const { subTitle, mainTitle, slots } = props || {};
	const { classes } = useStyles();
	const { belowTabletView } = useScreenViews();
	const theme = useTheme();
	const { t } = useTranslation();

	const renderStars = (rating) => {
		const stars: any = [];
		for (let i = 0; i < rating; i++) {
			stars.push(<StarIcon key={i} color="primary" />);
		}
		return stars;
	};

	return (
		<Box className={classes.backWrap}>
			<CustomGrid>
				<Grid container direction="column" marginTop={4}>
					<Typography variant="h6" color={theme.surfaceVariants?.secondary?.onSurface}>
						{subTitle}
					</Typography>
					<Typography variant="h3" padding="8px 0px 32px 0px">
						{mainTitle}
					</Typography>
				</Grid>

				<Box>
					<Typography variant="h6">{t('CUSTOMER_STORIES_TITLE')}</Typography>
					<Typography variant="h3">{t('CUSTOMER_STORIES_SUBTITLE')}</Typography>
					<CarouselAutoplay>
						{CUSTOMER_FEEDBACK_SLIDESHOW?.map((res, index) => (
							<Card key={index} className={classes.cardHeight}>
								<CardContent>
									<Grid container className={classes.wrapCard} justifyContent="flex-start">
										<Grid item>
											<img src={res.img} className={classes.img} alt={res.name} />
										</Grid>
										<Grid item>
											<Typography variant={belowTabletView ? 'body1' : 'h5'}>"{t(res.feedback)}"</Typography>
										</Grid>
										<Grid item xs={12}>
											<Typography variant="h6">{t(res.name)}</Typography>
											<Grid container direction="row" alignItems="center">
												<Grid item>
													<Typography variant="body1">{t(res.descLeft)} </Typography>
												</Grid>
												<Grid item padding="6px">
													<Divider orientation="vertical" className={classes.divider} />
												</Grid>
												<Grid item>{res?.rating && <>{renderStars(res?.rating)}</>}</Grid>
											</Grid>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						))}
					</CarouselAutoplay>
				</Box>
			</CustomGrid>
		</Box>
	);
};

export default CustomerFeedbacks;
