import { FormHelperText, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	textInput: {
		marginBottom: 10
	}
}));

const DateInput = (props) => {
	const { onChange, value, label, outlinedLabel, dateFormat, error, helperText, ...rest } = props || {};
	const { t } = useTranslation();
	const { classes } = useStyles();

	const onChangeDate = (e) => {
		onChange && onChange(e);
	};

	useEffect(() => {
		if (value) {
			onChange && onChange(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<>
			<Typography variant="h6">{t(label)}</Typography>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					{...rest}
					label={outlinedLabel}
					value={value}
					format={dateFormat || 'yyyy/MM/dd'}
					onChange={onChangeDate}
					renderInput={(params) => <TextField {...params} />}
				/>
			</LocalizationProvider>
			{error && <FormHelperText style={{ color: 'red' }}>{helperText}</FormHelperText>}
		</>
	);
};

export default DateInput;
