import { createContext, useContext } from 'react';
import { QUOTATION_FORM_TABS } from '../constant/insurance';
import { useStateSafe } from '../effects/useStateSafe';

export const InsuranceFormContext = createContext({});

const InsuranceFormContextProvider = (props) => {
	const [isBuyNowPayLater, setIsBuyNowPayLater] = useStateSafe<boolean>(false);
	const [hasExistingPolicy, setHasExistingPolicy] = useStateSafe<boolean>(false);

	const [insuranceType, setInsuranceType] = useStateSafe('');
	const [applicantDetails, setApplicantDetails] = useStateSafe({});
	const [contactDetails, setContactDetails] = useStateSafe({});
	const [isAppDetailsComplete, setIsAppDetailsComplete] = useStateSafe(false);
	const [isTypeOfInsComplete, setIsTypeOfInsComplete] = useStateSafe(false);
	const [activeMenu, setActiveMenu] = useStateSafe(QUOTATION_FORM_TABS.TYPE_OF_INSURANCE);

	const resetQuotationForm = () => {
		setIsBuyNowPayLater(false);
		setHasExistingPolicy(false);
		setInsuranceType('');
		setApplicantDetails({});
		setContactDetails({});
		setIsAppDetailsComplete(false);
	};

	const contextProps = {
		isBuyNowPayLater,
		setIsBuyNowPayLater,
		hasExistingPolicy,
		setHasExistingPolicy,

		insuranceType,
		setInsuranceType,
		applicantDetails,
		setApplicantDetails,
		contactDetails,
		setContactDetails,
		isAppDetailsComplete,
		setIsAppDetailsComplete,
		resetQuotationForm,
		activeMenu,
		setActiveMenu,
		isTypeOfInsComplete,
		setIsTypeOfInsComplete
	};

	return <InsuranceFormContext.Provider value={contextProps}>{props.children}</InsuranceFormContext.Provider>;
};

export const useInsuranceFormContext = () => {
	return useContext(InsuranceFormContext);
};

export default InsuranceFormContextProvider;
