import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import Header from '../../component/header/Header';
import Tabs from '../../component/Tabs';
import { INSURANCE_TYPE, QUOTATION_FORM_TABS } from '../../constant/insurance';
import InsuranceFormContextProvider, { useInsuranceFormContext } from '../../context/InsuranceFormContext';
import GeneralLayout from '../../layouts/GeneralLayout';
import theme from '../../theme/globalTheme';
import Employee from './Employee';
import Others from './Others';

const useStyles = makeStyles()(() => ({
	container: {
		width: '40%',
		paddingBottom: 50,
		[theme.breakpoints.down('md')]: {
			width: '90%'
		}
	}
}));

const InsuranceFormContent = () => {
	const { insurance_type } = useParams();
	const { classes } = useStyles();
	const { activeMenu, setActiveMenu }: any = useInsuranceFormContext();

	const renderFormType = () => {
		switch (insurance_type) {
			case INSURANCE_TYPE.EMPLOYEE:
				return <Employee />;
			case INSURANCE_TYPE.OTHERS:
				return <Others />;
			default:
				return <></>;
		}
	};
	const allTabs = [QUOTATION_FORM_TABS.TYPE_OF_INSURANCE, QUOTATION_FORM_TABS.APPLICATION_DETAILS, QUOTATION_FORM_TABS.CONTACT_DETAILS];

	return (
		<Grid item xs={12} sx={{ paddingTop: '88px' }}>
			<Grid container justifyContent="center" alignItems="center" style={{ width: '100%' }}>
				<Tabs onActiveMenuChanged={setActiveMenu} activeMenu={activeMenu} allTabs={allTabs} />
				<Grid container direction="column" className={classes.container}>
					{renderFormType()}
				</Grid>
			</Grid>
		</Grid>
	);
};

const InsuranceForms = () => {
	return (
		<InsuranceFormContextProvider>
			<GeneralLayout header={<Header />} content={<InsuranceFormContent />} />
		</InsuranceFormContextProvider>
	);
};

export default InsuranceForms;
