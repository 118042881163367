import { Card, Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as BuyNowPayLaterSVG } from '../assets/svg/insurance/whyChooseUs/buyNowPayLater.svg';
import { ReactComponent as EnterpriseSVG } from '../assets/svg/insurance/whyChooseUs/enterprise.svg';
import { ReactComponent as TakafulSVG } from '../assets/svg/insurance/whyChooseUs/takaful.svg';
import { ReactComponent as WinCouponsSVG } from '../assets/svg/insurance/whyChooseUs/winCoupons.svg';
import CustomGrid from './CustomGrid';

const useStyles = makeStyles()((theme) => ({
	mainContainer: {
		padding: '32px 0px'
	},
	leftContainer: {
		textAlign: 'left'
	},
	cardContainer: {
		borderRadius: '4px',
		backgroundColor: '#262bc614',
		padding: 32,
		[theme.breakpoints.down('md')]: {
			padding: 24
		}
	},
	card: {
		boxShadow: 'none',
		borderRadius: '4px',
		maxWidth: 446,
		width: '100%',
		minHeight: 462
	},
	underline: {
		borderBottom: ' 8px solid #F7E21E;'
	}
}));

const Left = () => {
	const { classes } = useStyles();
	const { t } = useTranslation();

	return (
		<Grid container gap={1} className={classes.leftContainer} direction="column" justifyContent="center" alignItems="flex-start">
			<Grid item>
				<Typography variant="h2">{t('WHY_CHOOSE_US')}</Typography>
			</Grid>
			<Grid item>
				<Typography variant="h3">
					<Trans i18nKey={'BUILT_BUSINESS_IN_MIND'} components={[<span className={classes.underline}></span>]} />
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="h5">
					<Trans i18nKey={'CHOOSE_US_DESC'} />
				</Typography>
			</Grid>
		</Grid>
	);
};

const CardRow = (props) => {
	const { img, title, desc } = props;

	return (
		<Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
			<Grid item>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item>{img}</Grid>
				</Grid>
			</Grid>
			<Grid item xs>
				<Grid container direction="column">
					<Grid item>
						<Typography gutterBottom variant="h4">
							{title}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="body1" color={'#757575'}>
							{desc}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

const Right = () => {
	const { classes } = useStyles();
	const { t } = useTranslation();

	return (
		<Card className={classes.card}>
			<Grid container className={classes.cardContainer} direction="row" alignItems="center" gap={3}>
				<Grid item xs={12}>
					<CardRow img={<BuyNowPayLaterSVG />} title={t('BUY_NOW_PAY_LATER_TITLE')} desc={t('BUY_NOW_PAY_LATER_SUBTITLE')} />
				</Grid>
				<Grid item xs={12}>
					<CardRow img={<TakafulSVG />} title={t('CONVENTIONAL_TITLE')} desc={t('CONVENTIONAL_SUBTITLE')} />
				</Grid>
				<Grid item xs={12}>
					<CardRow img={<EnterpriseSVG />} title={t('ENTERPRISE_TITLE')} desc={t('ENTERPRISE_SUBTITLE')} />
				</Grid>
				<Grid item xs={12}>
					<CardRow img={<WinCouponsSVG />} title={t('WIN_COUPON_TITLE')} desc={t('WIN_COUPON_SUBTITLE')} />
				</Grid>
			</Grid>
		</Card>
	);
};

const WhyChooseUs = () => {
	const { classes } = useStyles();

	return (
		<CustomGrid>
			<Grid
				container
				rowGap={4}
				columnGap={'20px'}
				className={classes.mainContainer}
				direction={{ xs: 'column', md: 'row' }}
				justifyContent={{ xs: 'center', md: 'space-between' }}
				alignItems="center">
				<Grid item xs={12} md={5} lg={6}>
					<Left />
				</Grid>
				<Grid item>
					<Right />
				</Grid>
			</Grid>
		</CustomGrid>
	);
};

export default WhyChooseUs;
