import { Divider, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { INSURANCE_EMPLOYEE_MONTHLY_AMOUNT_DATA_SRC } from '../../../../constant/insurance';
import { useInsuranceFormContext } from '../../../../context/InsuranceFormContext';
import { parseDateStringOnFormik } from '../../../../helpers/Date';
import i18n from '../../../../i18n';
import { BasicAutoComplete } from '../../../generalFormComponents/BasicAutoComplete';
import BasicTextInput from '../../../generalFormComponents/BasicTextInput';
import DateInput from '../../../generalFormComponents/DateInput';
import NavigationButtons from '../NavigationButtons';

const useStyles = makeStyles()((theme) => ({
	title: {
		color: '#D1D1D1'
	},
	dateInput: {
		width: '100%'
	}
}));

const SumInsuredDetails = (props) => {
	const { currentStep, setCurrentStep, prevTab, nextTab } = props;
	const { classes } = useStyles();
	const { t } = useTranslation();
	const MAX_CHARS = 120;
	const { applicantDetails, setActiveMenu, setApplicantDetails, setIsAppDetailsComplete }: any = useInsuranceFormContext();
	const {
		noEmpCovered: ctxNoEmpCovered,
		monthAmountPerEmp: ctxMonthAmountPerEmp,
		policyStartAt: ctxPolicyStartAt,
		additionalInfo: ctxAdditionalInfo
	}: any = applicantDetails || {};

	const handlePrev = () => {
		if (currentStep === 2) {
			setCurrentStep(1);
			return;
		}
		setActiveMenu(prevTab);
	};
	const handleNext = async () => {
		formik.handleSubmit();
	};

	const validationSchema = yup.object({
		numberOfEmployees: yup.number().min(1).positive(i18n.t('PRICE_MUST_POSITIVE_ERR')).integer().required(i18n.t('REQUIRED')),
		monthlyCommitmentAmount: yup.object().typeError(i18n.t('REQUIRED')).required(i18n.t('REQUIRED')),
		policyStartDate: yup.date().typeError(i18n.t('INVALID_DATE_ERR')).transform(parseDateStringOnFormik).required(i18n.t('REQUIRED')),
		additionalInfo: yup.string().max(MAX_CHARS, t('MAX_CHARS'))
	});

	const formik = useFormik({
		initialValues: {
			numberOfEmployees: ctxNoEmpCovered ?? 0,
			monthlyCommitmentAmount: ctxMonthAmountPerEmp ?? '',
			policyStartDate: ctxPolicyStartAt ?? null,
			additionalInfo: ctxAdditionalInfo ?? ''
		},
		validationSchema,
		onSubmit: async (values) => {
			const { numberOfEmployees, monthlyCommitmentAmount, policyStartDate, additionalInfo } = values || {};

			setApplicantDetails((prev) => {
				return {
					...prev,
					noEmpCovered: +numberOfEmployees,
					monthAmountPerEmp: monthlyCommitmentAmount?.value,
					policyStartAt: policyStartDate,
					additionalInfo
				};
			});
			setIsAppDetailsComplete(true);
			setActiveMenu(nextTab);
		}
	});
	return (
		<Grid item>
			<Grid container spacing={2} direction="column" justifyContent="space-evenly">
				<Grid item xs={12}>
					<Typography variant="h6" className={classes.title}>
						{t('SUM_INSURED_DETAILS_LBL')}
					</Typography>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<BasicTextInput
						label="EMPLOYEE_NUM_COVERED_LBL"
						type="number"
						value={formik.values.numberOfEmployees}
						onChange={(e) => {
							formik.setFieldValue('numberOfEmployees', e.target.value);
						}}
						error={formik.touched.numberOfEmployees && Boolean(formik.errors.numberOfEmployees)}
						helperText={formik.errors.numberOfEmployees}
					/>
				</Grid>

				<Grid item xs={12}>
					<BasicAutoComplete
						label={'EMPLOYEE_INSURED_MONTHLY_AMT_LBL'}
						placeholder={'PLEASE_SPECIFY'}
						options={INSURANCE_EMPLOYEE_MONTHLY_AMOUNT_DATA_SRC}
						value={formik.values.monthlyCommitmentAmount}
						onChange={(value) => {
							formik.setFieldValue('monthlyCommitmentAmount', value);
						}}
						error={formik.touched.monthlyCommitmentAmount && Boolean(formik.errors.monthlyCommitmentAmount)}
						helperText={formik.errors.monthlyCommitmentAmount}
					/>
				</Grid>
				<Grid item xs={12}>
					<DateInput
						label={'EMPLOYEE_INSURED_POLICY_START_LBL'}
						dateFormat={'yyyy/MM/dd'}
						value={formik.values.policyStartDate}
						onChange={(value) => {
							formik.setFieldValue('policyStartDate', value);
						}}
						error={formik.touched.policyStartDate && Boolean(formik.errors.policyStartDate)}
						helperText={formik.errors.policyStartDate}
						className={classes.dateInput}
					/>
				</Grid>
				<Grid item xs={12}>
					<BasicTextInput
						label={'ADDITIONAL_INFO_LBL'}
						inputProps={{ maxLength: MAX_CHARS }}
						minRows="3"
						multiline
						value={formik.values.additionalInfo}
						placeholder={t('ADDITIONAL_INFO_PLACEHOLDER')}
						showHelperText
						helperText={`${formik.values.additionalInfo.length}/${MAX_CHARS}`}
						onChange={(e) => {
							formik.setFieldValue('additionalInfo', e.target.value);
						}}
					/>
				</Grid>
				<NavigationButtons handlePrev={handlePrev} handleNext={handleNext} />
			</Grid>
		</Grid>
	);
};

export default SumInsuredDetails;
