/* eslint-disable max-len */
const landing = {
	HEADER_TAB_TRANSFER: 'Transfer',
	HEADER_TAB_INSURANCE: 'Insurance',
	HEADER_TAB_SUPPORT: 'Support Center',
	HEADER_TAB_CONTACT: 'Contact Us',

	NEW: 'NEW',
	BUY_NOW_PAY_LATER: '*Buy Now Pay Later.',
	CONTACT_TO_CHECK: 'Contact us to check eligibility',
	SME_INSURANCE: 'SME Insurance',
	PROTECT_FRM: 'Protect your business from',
	PROTECT_FRM_1: 'workplace hazards.',
	PROTECT_FRM_2: 'unwanted occurrences.',
	PROTECT_FRM_3: 'unforeseen accidents.',
	GET_QUOTE_BTN: 'Get a Quote',
	CONTACT_US_BTN: 'Contact Us',
	CONTACT_A_CONSULTANT: 'Contact a Consultant',
	OKAY_GOT_IT: 'Okay, Got it!',
	SUBMITTING: 'Submitting..',

	CHOOSE_N_CUSTOMIZE_TITLE: 'Choose and Customize',
	CHOOSE_N_CUSTOMIZE_SUBTITLE: 'Conventional or Takaful products from the best insurers for your business.',

	EMPLOYEE_BEN_TITLE: 'Employee Benefits',
	EMPLOYEE_BEN_SUBTITLE: 'Protect one of your company’s most valuable assets — People.',

	GET_FREE_QUOTE_BTN: 'Get a Free Quote',

	EMPLOYEE_BEN_DESC_TITLE: 'Various plans tailored for your needs',
	EMPLOYEE_BEN_DESC_1: 'Personal Accident',
	EMPLOYEE_BEN_DESC_2: 'Accidental Death and Disablement',
	EMPLOYEE_BEN_DESC_3: 'Critical Illness',
	EMPLOYEE_BEN_DESC_4: 'Hopsital and Surgical',
	EMPLOYEE_BEN_DESC_5: 'Foreign Workers Insurance',
	EMPLOYEE_BEN_DESC_6: 'Workemen’s Compensation',
	EMPLOYEE_BEN_DESC_7: 'Medical Insurance',
	EMPLOYEE_BEN_DESC_8: 'Out-Patient Clinical',
	EMPLOYEE_BEN_DESC_9: 'Term Life',

	ASSET_LIABILITY_TITLE: 'Asset & Liability Protection',
	ASSET_LIABILITY_SUBTITLE: 'Securing your business assets from unwanted occurrences.',

	ASSET_LIABILITY_DESC_TITLE: 'Protect your business against unfortunate events',
	ASSET_LIABILITY_DESC_1: 'Fire',
	ASSET_LIABILITY_DESC_2: 'Flood',
	ASSET_LIABILITY_DESC_3: 'Burglary',
	ASSET_LIABILITY_DESC_4: 'Marine',
	ASSET_LIABILITY_DESC_5: 'Machinery Breakdown',
	ASSET_LIABILITY_DESC_6: 'Money',
	ASSET_LIABILITY_DESC_7: 'Consequential Loss',
	ASSET_LIABILITY_DESC_8: 'Motor',
	ASSET_LIABILITY_DESC_9: 'Public Liability',

	WHY_CHOOSE_US: 'Why Choose Us? ',
	BUILT_BUSINESS_IN_MIND: 'Built with <0>businesses</0> in mind.',
	CHOOSE_US_DESC: 'We strive to protect your business and provide solutions to your insurance needs, so you can focus on growing your business.',

	BUY_NOW_PAY_LATER_TITLE: 'Buy Now, Pay Later ',
	BUY_NOW_PAY_LATER_SUBTITLE: 'Get full protection instantly and split your cost into three equal 0% interest payments.',
	BUY_NOW_PAY_LATER_DESC: 'Renew your insurance by splitting the cost into 3 interest-free payments.',

	CONVENTIONAL_TITLE: 'Conventional or Takaful',
	CONVENTIONAL_SUBTITLE: `Conventional or Takaful, we help you find the desired insurance for your business' needs.`,

	ENTERPRISE_TITLE: 'Enterprise Focused',
	ENTERPRISE_SUBTITLE: 'Our dedicated relationship managers are committed to provide you with the best service.',

	WIN_COUPON_TITLE: 'Win Coupons and more',
	WIN_COUPON_SUBTITLE: 'Policy holders get rewarded with discounts for remittance transfers.',

	MORE_FEATURE_TITLE: 'More features on the way...',
	MORE_FEATURE_SUBTITLE: 'Get more than just insurance with MoneyMatch.',

	SMART_REMINDER_TITLE: 'Smart Reminders',
	SMART_REMINDER_DESC: `Don't miss out on important payments and renewals anymore`,

	SAVE_POLICY_TITLE: 'Save Policy on Cloud',
	SAVE_POLICY_DESC: 'Easily manage your policies with MoneyMatch Pulse',

	EASY_CLAIM_TITLE: 'Easy Claims',
	EASY_CLAIM_DESC: 'Let our AI-assistant take care of the manual claims entry, while you focus on growing your business.',

	CUSTOMIZED_PERSONAL_PLAN_TITLE: 'Customized Personal Insurance',
	CUSTOMIZED_PERSONAL_PLAN_SUBTITLE: 'Not a Business? Get personal insurances for you and your family!',

	INS_PLAN_CAPTION: '{{i}} of 6',
	INS_PLAN_0_TITLE: 'Motorcycle Plus',
	INS_PLAN_0_DESC: 'Get protection for you & your bike',

	INS_PLAN_1_TITLE: 'Car & Motor Comprehensive',
	INS_PLAN_1_DESC: 'Reliable protection for peace on road',

	INS_PLAN_2_TITLE: 'Travel Care',
	INS_PLAN_2_DESC: 'Enjoy overseas & domestic travels without fear',

	INS_PLAN_3_TITLE: 'Smart Home Cover',
	INS_PLAN_3_DESC: 'Freedom to choose & customise your coverage',

	INS_PLAN_4_TITLE: 'Lifestyle Protect',
	INS_PLAN_4_DESC: `The all-in-one plan for your everyday lifestyle`,

	INS_PLAN_5_TITLE: `Shield Plus`,
	INS_PLAN_5_DESC: `Comprehensive protection for unexpected life events`,

	OUR_PARTNERS: 'Our Partners',

	FAQ: 'Frequently Asked Questions',
	FAQ_TITLE_1: 'What are the insurances offered?',
	FAQ_SUBTITLE_1: 'You can get insured with these insurances:',
	FAQ_DESC_1:
		'Employee Benefits such as Group Hospitalization and Surgical, Fire insurance, Flood insurance, Burglary Insurance, Public Liability Insurance & other customisable insurances.',
	FAQ_TITLE_2: 'Who are the insurance providers?',
	FAQ_SUBTITLE_2: 'Our insurance providers include:',
	FAQ_DESC_2: 'PruBSN,  Allianz Life, Allianz General, and more to come!',
	FAQ_TITLE_3: 'Do we offer Takaful products?',
	FAQ_DESC_3:
		// eslint-disable-next-line
		'Yes, we offer Takaful insurance policies that are Shariah compliant such as Takaful Life or Medical Insurance. Our team consists of MTA registered Takaful agents, authorised to promote and distribute Takaful products.',
	FAQ_TITLE_4: 'What is the premium charged?',
	FAQ_DESC_4:
		// eslint-disable-next-line
		'The premium is calculated based on the insurance policy selected. You can contact our friendly Customer Relationship Manager for more information. Alternatively, you can send us your queries to <0>insurance@moneymatch.co.</0>',

	GOTO_SUPPORT_BTN: 'Go to Support Center',

	GET_A_FREE_CONSULTATION: 'Get a Free Consultation',
	GET_A_FREE_CONSULTATION_DESC:
		'It’s time to take your business to the next level. We are ready to discuss which of our solutions can help you scale.',

	CTA_NAME: 'Your Name',
	CTA_NAME_PH: 'e.g. John Doe',

	CTA_COMPANY_NAME: 'Company Name',
	CTA_COMPANY_NAME_PH: 'e.g. ABC Company Sdn Bhd',

	CTA_CONTACT_NUM: 'Contact Number',
	CTA_CONTACT_NUM_PH: 'e.g. +6012 345 6789',

	CTA_CONTACT_EMAIL: 'Email Address',
	CTA_CONTACT_EMAIL_PH: 'e.g. abc@example.com',

	CTA_COMPANY_SIZE: 'Company Size',
	CTA_COMPANY_SIZE_PH: 'e.g. 50-100 employees',

	CTA_PROTECTION: 'What kind of protection are you looking for?',

	CTA_TELL_US_MORE: 'Tell us what you need',
	CTA_TELL_US_MORE_PH: 'Include more details of your request and any specifics we should take into consideration.',
	CTA_TELL_US_MORE_TXTCOUNTER: '{{len}}/240',
	FORM_SUBMIT: 'Contact Us',

	CTA_SUBMISSION_OK_TITLE: 'Submission Complete!',
	CTA_SUBMISSION_OK_DESC: ' Thank you for your interest. Our team will reach out to you within 3 working days!',

	CONTACT_US_FOOTER: 'Contact Us!',
	CONTACT_US_FOOTER_SUBTITLE: 'We are just one call away.',

	OFFICE_HQ: 'MoneyMatch HQ:',
	OFFICE_HQ_ADDRESS: `Unit 3.03, Menara LGB, 1 Jalan Wan Kadir, <0/> Taman Tun Dr Ismail, Kuala Lumpur, 60000, Malaysia.`,

	OPERATING_INFO: 'Operating Hours',
	OPERATING_HOURS: '9:00 AM - 5:30PM',
	OPERATING_DAYS: '(Weekdays only, except Public Holidays)',

	CALL_OR_TEXT: 'Call or Text Us',
	CALL_CONVENTIONAL_LBL: 'Conventional',
	CALL_CONVENTIONAL_1: '+6019 223 0826 (EN, CN)',
	CALL_CONVENTIONAL_2: '+6019 209 6221 (EN, BM)',
	CALL_CONVENTIONAL_3: '+6019 223 0961 (EN, BM)',

	CALL_TAKAFUL_LBL: 'Takaful',
	CALL_TAKAFUL_1: '+6019 223 0180 (EN, BM)',

	EMAIL_LBL: 'Email Address',
	EMAIL_1: 'insurance@moneymatch.co',
	EMAIL_2: 'customer.support@moneymatch.co',

	// TODO: BELLOW ARE RECENTLY ADDED LISTS. ONCE DONE CHECK ABOVE LIST ^ IF NEEDED/NOT (CLEAN UP)

	COPYRIGHT_FOOTER: 'MoneyMatch Sdn.Bhd. {{year}}.All rights reserved.',
	SITEMAP: 'Sitemap',
	LEGAL: 'Legal',
	PRIVACY_POLICY: 'Privacy Policy',
	MM_INSURTECH: 'MM Insurtech Sdn. Bhd. 202201005704 (1451401-K)',
	MM_REGULATORY:
		'MoneyMatch Sdn.Bhd. is regulated by Central Bank (licensee providing digital services) under MY Licence Serial Number 00249 in Malaysia. <0/> Perniagaan Perkhidmatan Wang Berlesen | Pengirim Wang',
	PERSONAL: 'Personal',
	BUSINESS: 'Business',
	RESOURCES: 'Resources',
	ENTERPRISE: 'Enterprise',
	COMPANY: 'Company',
	CONTACT_US: 'Contact us',
	CUSTOMER_SUPPORT: 'CUSTOMER SUPPORT',
	CUSTOMER_SUPPORT_SUBTITLE: 'We are here for you',
	CUSTOMER_SUPPORT_DESC: 'Our customer support team is ready to help ease your claims process.',
	CONTACT_OUR_SUPPORT: 'Contact our experts',
	CONTACT_INFO_1: '+6019 209 6221 (Shaun)',
	CONTACT_INFO_2: '+6019 223 0180 (Lokman)',
	CONTACT_INFO_3: '+603 3099 3889 (Support)',
	OPERATING_TIME: '9.00 am - 5:30 pm',
	SUPPORT_CENTER: 'Support center',
	SUPPORT_HOURS: 'Support hours',
	SUPPORT_CENTER_FOR_BUSINESS: 'Our support center is always for business.',
	VIEW_ARTICLES: 'View articles',

	CUSTOMER_STORIES_TITLE: 'Customer Stories',
	CUSTOMER_STORIES_SUBTITLE: 'Hear what our customers have to say',
	CUSTOMER_STORIES_FEEDBACK1: `MM Insurance offers an affordable fire insurance policy without compromising on quality. Their customer service is top-notch, and they explain everything clearly. I'm happy with the level of protection they provide for my property.`,
	CUSTOMER_STORIES_NAME1: 'Malaysia’s Local Contemporary Modest Fashion Brand',
	CUSTOMER_STORIES_DESCLEFT1: 'Fire Insurance',
	CUSTOMER_STORIES_FEEDBACK2: `I recently purchased fire insurance from MoneyMatch, and I couldn't be happier. They provided a straightforward and cost-effective policy with great coverage. The customer service team was extremely helpful, making the entire process a breeze. Highly recommended!`,
	CUSTOMER_STORIES_NAME2: 'Local Medical Equipment Supplier',
	CUSTOMER_STORIES_DESCLEFT2: 'Fire Insurance',
	CUSTOMER_STORIES_FEEDBACK3: `I recently worked with MoneyMatch to set up our company's employee benefits package, and the experience was excellent. They offered a wide range of options tailored to our specific needs. The team was knowledgeable and responsive, making the process seamless. Our employees are now thrilled with the comprehensive benefits package. Highly recommend MoneyMatch for your company's needs.`,
	CUSTOMER_STORIES_NAME3: 'Malaysia’s Innovative Water Purifier Manufacturer',
	CUSTOMER_STORIES_DESCLEFT3: 'Employee Benefits',
	CUSTOMER_STORIES_FEEDBACK4: `MoneyMatch offers a stress-free solution for managing employee benefits. Their platform is intuitive, and they have a responsive support team that guided us through the entire process. The customizable options allowed us to create a benefits package that suits our diverse workforce. We've had positive feedback from our employees, and I'm grateful to MoneyMatch for their expertise and assistance.`,
	CUSTOMER_STORIES_NAME4: 'Home’s Furniture Seller',
	CUSTOMER_STORIES_DESCLEFT4: 'Employee Benefits',

	SLIDE_BANNER_SUBTITLE1: 'BUSINESS INSURANCE',
	SLIDE_BANNER_MAINTITLE1: 'SME Insurance',
	SLIDE_BANNER_DESCRIPTION1: 'Protect your business from workplace hazards, unwanted occurrences, and unforeseen accidents.',
	LEARN_MORE_BTN: 'Learn More',
	SLIDE_BANNER_SUBTITLE2: 'PERSONAL INSURANCE',
	SLIDE_BANNER_MAINTITLE2: 'For you and your family',
	SLIDE_BANNER_DESCRIPTION2: 'Protect you and your loved ones against unexpected events.',
	SLIDE_BANNER_SUBTITLE3: 'NEW',
	SLIDE_BANNER_MAINTITLE3: 'Buy Now, Pay Later',
	SLIDE_BANNER_DESCRIPTION3:
		'Increase your financial flexibility and get the coverages you need for your business without facing immediate substantial costs.',
	FIND_OUT_MORE_BTN: 'Find Out More',
	SLIDE_BANNER_SUBTITLE4: 'NEW',
	SLIDE_BANNER_MAINTITLE4: 'Customise your coverage',
	SLIDE_BANNER_DESCRIPTION4: 'Not sure what is needed? We help you to find and personalized the ideal insurance plans for your business needs.',
	GET_A_FREE_CONSULTANT_BTN: 'Get a Free Consultation',

	BNPL_BULLET_POINT_1: 'ZERO % interest rate at a 3-month installment',
	BNPL_BULLET_POINT_2: 'NO processing fee',
	BNPL_BULLET_POINT_3: 'NO maximum limit',
	BACK_TO_TOP: 'Back to Top'
};

export default landing;
