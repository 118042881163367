import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import zeroInterestRatePng from '../../assets/images/landing/zeroInterestRate.png';
import { useInsFeatureStyles } from './useInsFeatureStyles';

export const useStyles = makeStyles()((theme) => ({
	bnplBulletPoint: {
		paddingLeft: '1.56rem'
	}
}));

const BuyNowPayLater = () => {
	const { t } = useTranslation();
	const { classes: insFeatureClasses } = useInsFeatureStyles();
	const { classes } = useStyles();

	return (
		<Stack className={insFeatureClasses.insuranceStackContainer}>
			<img src={zeroInterestRatePng} alt="employee-benefits-insurance" className={insFeatureClasses.img} />
			<Stack className={insFeatureClasses.insuranceCtaContainer}>
				<Typography variant="h6" color="textSecondary" gutterBottom>
					{t('NEW')}
				</Typography>
				<Typography variant="h3" gutterBottom>
					{t('BUY_NOW_PAY_LATER_TITLE')}
				</Typography>
				<Typography variant="h5">{t('BUY_NOW_PAY_LATER_DESC')}</Typography>
				<ul className={classes.bnplBulletPoint}>
					<li>{t('BNPL_BULLET_POINT_1')}</li>
					<li>{t('BNPL_BULLET_POINT_2')}</li>
					<li>{t('BNPL_BULLET_POINT_3')}</li>
				</ul>
				<Typography variant="h5" color="textSecondary">
					{`*${t('CONTACT_US_TO_CHECK_ELIGIBILITY')}`}
				</Typography>
			</Stack>
		</Stack>
	);
};

export default BuyNowPayLater;
