import { Button, Grid, Typography } from '@mui/material';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import MMLogoSVG from '../../assets/svg/mmLogo.svg';
import { LANDING_HEADER_COMPANY, LANDING_HEADER_PRODUCTS, LANDING_HEADER_PROMOTIONS, LANDING_HEADER_SUPPORT } from '../../constant/landing';
import { PATHS_BY_ENV } from '../../constant/paths';
import { useScreenViews } from '../../effects/useScreenViews';
import { useStateSafe } from '../../effects/useStateSafe';
import { env } from '../../helpers/EnvHelper';
import CollapsibleMenu from '../CollapsibleMenu';
import MobileHeader from './MobileHeader';

const useStyles = makeStyles<{ isFixed?: any }>()((theme, { isFixed }) => ({
	headerContainer: {
		height: 56,
		zIndex: 1500,
		position: 'fixed',
		boxShadow: isFixed ? '0 8px 24px rgb(0 0 0 / 13%)' : '',
		backdropFilter: isFixed ? 'blur(60px)' : 'blur(0px)',
		backgroundColor: isFixed ? 'rgba(255, 255, 255, 0.5)' : 'transparent'
	},
	headerSubContainer: {
		width: 1030,
		[theme.breakpoints.down('lg')]: {
			width: '90%'
		},
		[theme.breakpoints.down('md')]: {
			width: '100%'
		},
		height: 56
	},
	mmLogo: {
		cursor: 'pointer'
	},
	contactButton: {
		marginTop: 8,
		height: 41,
		borderRadius: '320px',
		padding: '12px 24px',
		background: theme.surfaceVariants.primary.onSurface,
		color: theme.surfaceVariants.primary.surface,
		'&:hover': {
			background: theme.palette.primary.main,
			color: theme.surfaceVariants.primary.onSurface
		}
	},
	tabButton: {
		paddingTop: 17,
		color: theme.accent.main,
		paddingBottom: '7px',
		'&:hover': {
			paddingBottom: 3,
			background: 'none',
			borderBottom: `solid 4px ${theme.accent.main}`,
			borderRadius: 0
		}
	}
}));

const Header = (props) => {
	const { openContactDialogForm } = props || {};
	const [isFixed, setIsFixed] = useStateSafe(false);
	const [lastScrollY, setLastScrollY] = useStateSafe(0);
	const { classes } = useStyles({ isFixed });
	const { t } = useTranslation();
	const { belowTabletView } = useScreenViews();

	const controlNavbar = useCallback(() => {
		if (typeof window !== 'undefined') {
			if (window.scrollY > 5) {
				setIsFixed(true);
			} else {
				setIsFixed(false);
			}
			setLastScrollY(window.scrollY);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', controlNavbar);
			return () => {
				window.removeEventListener('scroll', controlNavbar);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastScrollY]);

	if (!belowTabletView) {
		return (
			<Grid
				container
				className={classes.headerContainer}
				sx={{ display: { xs: 'none', md: 'flex' } }}
				direction="row"
				justifyContent="center"
				alignItems="center">
				<Grid item container justifyContent="space-between" className={classes.headerSubContainer}>
					<Grid item>
						<Grid container alignItems="center" justifyContent="center" sx={{ height: '100%', margin: 'auto', alignItems: 'center' }}>
							<Link to={PATHS_BY_ENV[env]?.landingPageLink} style={{ textDecoration: 'none' }} color="inherit">
								<img loading="lazy" src={MMLogoSVG} alt="MoneyMatch Logo" width={160} height={40} className={classes.mmLogo} />
							</Link>
						</Grid>
					</Grid>
					<Grid item>
						<Grid item container sx={{ height: '100%', alignItems: 'center' }}>
							<Grid item>
								<CollapsibleMenu dataSrc={LANDING_HEADER_PRODUCTS} menuText="Products" />
							</Grid>
							<Grid item>
								<Button variant="text" className={classes.tabButton} href={PATHS_BY_ENV[env][LANDING_HEADER_PROMOTIONS.link]}>
									<Typography variant="body1">{LANDING_HEADER_PROMOTIONS.title}</Typography>
								</Button>
							</Grid>
							<Grid item>
								<CollapsibleMenu dataSrc={LANDING_HEADER_COMPANY} menuText="Company" />
							</Grid>
							<Grid item>
								<Button variant="text" className={classes.tabButton} href={PATHS_BY_ENV[env][LANDING_HEADER_SUPPORT.link]}>
									<Typography variant="body1">{LANDING_HEADER_SUPPORT?.title}</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid>
						<Button onClick={openContactDialogForm} className={classes.contactButton} disableRipple variant="contained">
							<Typography variant="body1">{t('HEADER_TAB_CONTACT')}</Typography>
						</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid
			container
			className={classes.headerContainer}
			sx={{ display: { xs: 'flex', md: 'none' } }}
			direction="row"
			justifyContent="center"
			alignItems="center">
			<MobileHeader mMLogo={MMLogoSVG} openContactDialogForm={openContactDialogForm} />
		</Grid>
	);
};

export default memo(Header);
