import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
	wrapGridItem: {
		width: '1030px',
		[theme.breakpoints.down('lg')]: {
			width: '90%'
		}
	}
}));

const CustomGrid = ({ children, className, gridContainerStyle }: any) => {
	const { classes, cx } = useStyles();

	return (
		<Grid container justifyContent="center" sx={{ width: '100%', ...gridContainerStyle }}>
			<Grid item className={cx(classes.wrapGridItem, className)}>
				{children}
			</Grid>
		</Grid>
	);
};

export default CustomGrid;
