import EastIcon from '@mui/icons-material/East';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import publicLiabilityInsurancePng from '../../assets/images/insurance/publicLiabilityInsurance.png';
import { INSURANCE_TYPE, INS_ASSETS_LIA_PRODUCT_DETAILS } from '../../constant/insurance';
import { ProductDescAccordions } from '../ProductDescCards';
import { useInsFeatureStyles } from './useInsFeatureStyles';

const InsuranceHeader = () => {
	const { t } = useTranslation();
	const { classes } = useInsFeatureStyles();

	return (
		<Stack className={classes.businessInsuranceHeader} id="forYourBusiness">
			<Typography variant="h6" color="textSecondary">
				{t('FOR_YOUR_BUSINESS')}
			</Typography>
			<Typography variant="h2">{t('SHIELD_YOUR_BUSINESS_FROM_RISK')}</Typography>
			<Typography variant="h4" color="textSecondary">
				{t('VARIOUS_PLANS_TAILORED_FOR_YOUR_NEEDS')}
			</Typography>
		</Stack>
	);
};

const InsuranceCta = () => {
	const { t } = useTranslation();
	const { classes } = useInsFeatureStyles();
	const navigate = useNavigate();

	const navigateToGetQuote = () => {
		navigate(`/quote/${INSURANCE_TYPE.OTHERS}`);
	};

	return (
		<Stack className={classes.insuranceStackContainer}>
			<img src={publicLiabilityInsurancePng} alt="public-liability-insurance" className={classes.img} />
			<Stack className={classes.insuranceCtaContainer}>
				<Typography variant="h3">{t('FOR_MY_ASSETS_AND_LIABILITIES')}</Typography>
				<Typography variant="h5">{t('FOR_MY_ASSETS_AND_LIABILITIES_DESC')}</Typography>
				<Button color="primary" variant="contained" endIcon={<EastIcon />} className={classes.getQuoteBtn} disableElevation>
					<Typography variant="button" onClick={navigateToGetQuote}>
						{t('GET_A_FREE_QUOTE_LBL')}
					</Typography>
				</Button>
			</Stack>
		</Stack>
	);
};

const AssetAndLiabilityFeatures = () => {
	return (
		<Stack rowGap={2}>
			<InsuranceHeader />
			<InsuranceCta />
			<ProductDescAccordions productList={INS_ASSETS_LIA_PRODUCT_DETAILS} cardColor={'#F7F7F7'} />
		</Stack>
	);
};

export default AssetAndLiabilityFeatures;
