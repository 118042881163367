import { makeStyles } from 'tss-react/mui';
import { Box, Divider, Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { experimentalStyled as styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import {
	FOOTER_BUSINESS,
	FOOTER_COMPANY,
	FOOTER_ENTERPRISE,
	FOOTER_PERSONAL,
	FOOTER_RESOURCES,
	SOCIAL_MEDIA_ICON_LINKS
} from '../../constant/landing';
import { PATHS_BY_ENV } from '../../constant/paths';
import { useScreenViews } from '../../effects/useScreenViews';
import { env } from '../../helpers/EnvHelper';
import MobileFooter from './MobileFooter';
import CustomGrid from '../CustomGrid';
import { Trans, useTranslation } from 'react-i18next';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(4),
	textAlign: 'left',
	fontWeight: '400',
	fontSize: '14px',
	lineHeight: '35px',
	color: theme.palette.text.primary,
	boxShadow: 'none',
	[theme.breakpoints.down('md')]: {
		textAlign: 'center'
	}
}));

const useStyles = makeStyles()((theme) => ({
	boxColor: {
		background: theme.surfaces?.level1,
		color: '#9E9E9E'
	},
	wrapFotter: {
		flexDirection: 'column',
		background: theme.palette.background.paper,
		alignItems: 'center',
		'& .MuiPaper-root': {
			padding: '32px 0px',
			[theme.breakpoints.down('md')]: {
				padding: '0px'
			}
		}
	},
	wrapBox: {
		width: 1030,
		[theme.breakpoints.down('lg')]: {
			width: '90%'
		}
	},
	logo: {
		marginRight: '1rem'
	},
	linkText: {
		color: theme.palette.grey[900],
		lineHeight: '21px',
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	textLink: {
		color: '#9E9E9E',
		textDecoration: 'none',
		'&:hover': {
			color: theme.palette.grey[900],
			textDecoration: 'underline'
		}
	},
	regionRow: {
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center'
		}
	}
}));

const FooterLinks = (props) => {
	const { data } = props || {};
	const { classes } = useStyles();

	if (!data) return <></>;

	return (
		<>
			{data?.map((item, index) =>
				item.link && item?.link ? (
					<React.Fragment key={item?.title}>
						<Typography variant="body1" mb={1.5} component="div">
							<Link className={classes.linkText} to={`${PATHS_BY_ENV[env][item?.link]}${item?.propsLink ?? ''}`}>
								{item?.title}
							</Link>
						</Typography>
					</React.Fragment>
				) : (
					<React.Fragment key={index}></React.Fragment>
				)
			)}
		</>
	);
};

const FooterList = (props: any) => {
	const { t } = props || {};
	const { classes } = useStyles();

	return (
		<div className={classes.wrapBox}>
			<Box
				display="flex"
				sx={{
					flexDirection: 'row',
					justifyContent: 'space-between'
				}}
				gridTemplateColumns="repeat(12, 1fr)">
				<Box>
					<Item>
						<Typography variant="h4" mb={2}>
							{t('PERSONAL')}
						</Typography>
						<FooterLinks data={FOOTER_PERSONAL} />
					</Item>
				</Box>

				<Box>
					<Item>
						<Typography variant="h4" mb={2}>
							{t('BUSINESS')}
						</Typography>
						<FooterLinks data={FOOTER_BUSINESS} />
					</Item>
				</Box>

				<Box>
					<Item>
						<Typography variant="h4" mb={2}>
							{t('ENTERPRISE')}
						</Typography>
						<FooterLinks data={FOOTER_ENTERPRISE} />
					</Item>
				</Box>

				<Box>
					<Item>
						<Typography variant="h4" mb={2}>
							{t('RESOURCES')}
						</Typography>
						<FooterLinks data={FOOTER_RESOURCES} />
					</Item>
				</Box>

				<Box>
					<Item>
						<Typography variant="h4" mb={2}>
							{t('COMPANY')}
						</Typography>
						<FooterLinks data={FOOTER_COMPANY} />
					</Item>
				</Box>
			</Box>
		</div>
	);
};

const SocialIconsRow = () => {
	const { classes } = useStyles();

	return (
		<Grid container justifyContent="flex-end">
			{SOCIAL_MEDIA_ICON_LINKS.map((item) => {
				return (
					item?.link && (
						<React.Fragment key={item?.link}>
							<Link to={PATHS_BY_ENV[env][item?.link]} target="_blank" rel="noopener noreferrer">
								{item?.img && <img className={classes.logo} alt={`${PATHS_BY_ENV[env][item?.link]}`} src={item.img} width={20} height={20} />}
							</Link>
						</React.Fragment>
					)
				);
			})}
		</Grid>
	);
};

const FooterContainer = (props: any) => {
	const { countryNameToDisplay }: any = props || {};
	const { classes } = useStyles();
	const { belowTabletView } = useScreenViews();
	const { t } = useTranslation();
	const year = new Date().getFullYear().toString();

	const bull = (
		<Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
			•
		</Box>
	);

	return (
		<>
			<Grid container className={classes.wrapFotter}>
				{belowTabletView ? <MobileFooter /> : <FooterList t={t} />}

				<CustomGrid>
					<Grid container className={classes.regionRow}>
						<Grid item lg={6} md={6} sm={6} xs={12}>
							{/* <RegionPickerMenu data={MMT_REGION_DROP_DOWN} value={countryNameToDisplay} /> */}
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<SocialIconsRow />
						</Grid>
					</Grid>
				</CustomGrid>
			</Grid>

			<Box className={classes.boxColor}>
				<CustomGrid>
					<Grid
						container
						marginTop="1rem"
						direction="row"
						textAlign={belowTabletView ? 'center' : 'left'}
						justifyContent={belowTabletView ? 'center' : 'space-between'}>
						<Grid item lg={6} md={6} sm={12}>
							<Typography variant="body1">{t('COPYRIGHT_FOOTER', { year })}</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12}>
							<Grid container direction="row" justifyContent={belowTabletView ? 'center' : 'end'}>
								<Typography variant="body1">{t('SITEMAP')}</Typography>
								{bull}
								<Link to={PATHS_BY_ENV[env]?.legalLink} className={classes.textLink}>
									<Typography variant="body1">{t('LEGAL')}</Typography>
								</Link>
								{bull}
								<Link to={PATHS_BY_ENV[env]?.privacyLink} className={classes.textLink}>
									<Typography variant="body1">{t('PRIVACY_POLICY')}</Typography>
								</Link>
							</Grid>
						</Grid>
					</Grid>
					<Divider sx={{ margin: '1rem 0' }} />
					<Grid container direction="column" rowGap={2} justifyContent={belowTabletView ? 'center' : 'left'} paddingBottom="16px">
						<Typography variant="body2" textAlign={belowTabletView ? 'center' : 'left'}>
							{t('MM_INSURTECH')}
						</Typography>

						<Typography variant="body2" textAlign={belowTabletView ? 'center' : 'left'}>
							<Trans i18nKey={'MM_REGULATORY'} components={[<br />]} />
						</Typography>
					</Grid>
				</CustomGrid>
			</Box>
		</>
	);
};

export default FooterContainer;
