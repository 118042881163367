import { Divider, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { INSURED_ADDRESS_STATE_DATA_SRC, INSURED_BUILDING_TYPE_DATA_SRC } from '../../../../constant/insurance';
import { useInsuranceFormContext } from '../../../../context/InsuranceFormContext';
import i18n from '../../../../i18n';
import { BasicAutoComplete } from '../../../generalFormComponents/BasicAutoComplete';
import BasicTextInput from '../../../generalFormComponents/BasicTextInput';
import ToggleButtons from '../../../ToggleButtons';
import NavigationButtons from '../NavigationButtons';

const useStyles = makeStyles()((theme) => ({
	title: {
		color: '#D1D1D1'
	},
	divider: {
		marginTop: 10
	}
}));

const BuildingDetails = (props) => {
	const { setCurrentStep, prevTab } = props || {};
	const { classes } = useStyles();
	const { t } = useTranslation();

	const { applicantDetails, setApplicantDetails, setActiveMenu }: any = useInsuranceFormContext();
	const { buildingType: ctxBuildingType, addressDetails: ctxAddressDetails }: any = applicantDetails || {};
	const { address1: ctxAddress1, city: ctxCity, state: ctxState, postcode: ctxPostcode }: any = ctxAddressDetails || {};

	const validationSchema = yup.object({
		buildingType: yup.string().required(i18n.t('REQUIRED')),
		address1: yup.string().required(i18n.t('REQUIRED')),
		city: yup.string().required(i18n.t('REQUIRED')),
		state: yup.object().typeError(i18n.t('REQUIRED')).required(i18n.t('REQUIRED')),
		postcode: yup.number().positive(i18n.t('PRICE_MUST_POSITIVE_ERR')).integer().required(i18n.t('REQUIRED'))
	});

	const formik = useFormik({
		initialValues: {
			buildingType: ctxBuildingType ?? INSURED_BUILDING_TYPE_DATA_SRC[0].value,
			address1: ctxAddress1 ?? '',
			city: ctxCity ?? '',
			state: INSURED_ADDRESS_STATE_DATA_SRC.find((x) => x.value === ctxState) ?? null,
			postcode: ctxPostcode ?? ''
		},
		validationSchema,
		onSubmit: async (values) => {
			const { buildingType, address1, city, state, postcode } = values || {};
			const { value: stateValue }: any = state || {};

			setApplicantDetails((prevState) => {
				return {
					...prevState,
					buildingType,
					addressDetails: {
						address1,
						city,
						state: stateValue,
						postcode: +postcode
					}
				};
			});
			setCurrentStep((prevState) => prevState + 1);
		}
	});
	const handlePrev = () => {
		setCurrentStep((prevState) => prevState - 1);
	};
	const handleNext = () => {
		formik.handleSubmit();
	};
	return (
		<Grid item>
			<Grid container spacing={2} direction="column" justifyContent="space-evenly">
				<Grid item xs={12}>
					<ToggleButtons
						exclusive
						label={'TYPE_OF_BUILDING'}
						dataSrc={INSURED_BUILDING_TYPE_DATA_SRC}
						onValueChange={(value) => {
							formik.setFieldValue('buildingType', value);
						}}
						value={formik.values.buildingType}
					/>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" className={classes.title}>
						{t('ADDRESS_DETAILS_LBL')}
					</Typography>
					<Divider className={classes.divider} />
				</Grid>

				<Grid item xs={12}>
					<BasicTextInput
						label={'ADDRESS_DETAILS_ADDRESS_LBL'}
						value={formik.values.address1}
						onChange={(e) => {
							formik.setFieldValue('address1', e.target.value);
						}}
						error={formik.touched.address1 && Boolean(formik.errors.address1)}
						helperText={formik.errors.address1}
					/>
				</Grid>

				<Grid item xs={12}>
					<BasicTextInput
						label={'ADDRESS_DETAILS_CITY_LBL'}
						value={formik.values.city}
						onChange={(e) => {
							formik.setFieldValue('city', e.target.value);
						}}
						error={formik.touched.city && Boolean(formik.errors.city)}
						helperText={formik.errors.city}
					/>
				</Grid>

				<Grid item xs={12}>
					<BasicAutoComplete
						label={'ADDRESS_DETAILS_STATE_LBL'}
						placeholder={'PLEASE_SPECIFY'}
						options={INSURED_ADDRESS_STATE_DATA_SRC}
						value={formik.values.state}
						onChange={(value) => {
							formik.setFieldValue('state', value);
						}}
						error={formik.touched.state && Boolean(formik.errors.state)}
						helperText={formik.errors.state}
					/>
				</Grid>

				<Grid item xs={12}>
					<BasicTextInput
						label={'ADDRESS_DETAILS_POSTCODE_LBL'}
						type="number"
						value={formik.values.postcode}
						onChange={(e) => {
							formik.setFieldValue('postcode', e.target.value);
						}}
						error={formik.touched.postcode && Boolean(formik.errors.postcode)}
						helperText={formik.errors.postcode}
					/>
				</Grid>
				<Grid item xs={12}>
					<NavigationButtons handlePrev={handlePrev} handleNext={handleNext} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default BuildingDetails;
