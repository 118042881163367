import React from 'react';
import { useTranslation } from 'react-i18next';
import ApplicantDetailsEmployee from '../../component/insuranceFormComponents/applicantDetails/employee';
import ContactDetails from '../../component/insuranceFormComponents/contact/ContactDetails';
import TypeOfInsurance from '../../component/insuranceFormComponents/typeOfInsurance/TypeOfInsurance';
import { INSURANCE_TYPE, QUOTATION_FORM_TABS } from '../../constant/insurance';
import { useInsuranceFormContext } from '../../context/InsuranceFormContext';

const Employee = (props) => {
	const { t } = useTranslation();
	const { activeMenu }: any = useInsuranceFormContext();

	const renderFormType = () => {
		switch (activeMenu) {
			case QUOTATION_FORM_TABS.TYPE_OF_INSURANCE:
				return <TypeOfInsurance type={INSURANCE_TYPE.EMPLOYEE} nextTab={QUOTATION_FORM_TABS.APPLICATION_DETAILS} />;
			case QUOTATION_FORM_TABS.APPLICATION_DETAILS:
				return <ApplicantDetailsEmployee prevTab={QUOTATION_FORM_TABS.TYPE_OF_INSURANCE} nextTab={QUOTATION_FORM_TABS.CONTACT_DETAILS} />;
			case QUOTATION_FORM_TABS.CONTACT_DETAILS:
				return <ContactDetails prevTab={QUOTATION_FORM_TABS.APPLICATION_DETAILS} />;
		}
	};

	return <>{renderFormType()}</>;
};

export default Employee;
