import { Box, FormHelperText, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	label: {
		width: '100%'
	},
	textInput: {
		width: '100%',
		'& input': {
			height: 29,
			borderRadius: 8,
			padding: 8
		}
	}
}));

const BasicTextInput = (props) => {
	const { label, value, onChange, error, helperText, showHelperText, helperTextStyle, ...rest } = props || {};
	const { classes } = useStyles();
	const { t } = useTranslation();

	return (
		<Box sx={{ width: '100%' }}>
			<Typography variant="h6" className={classes.label}>
				{t(label)}
			</Typography>
			<TextField className={classes.textInput} {...rest} variant="outlined" value={value} onChange={onChange} />
			{showHelperText && <FormHelperText style={helperTextStyle || { textAlignLast: 'right', margin: 0 }}>{helperText}</FormHelperText>}
			{error && <FormHelperText style={{ color: 'red' }}>{helperText}</FormHelperText>}
		</Box>
	);
};

export default BasicTextInput;
