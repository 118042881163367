import EastIcon from '@mui/icons-material/East';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import employeeBenefitsPng from '../../assets/images/insurance/employeeBenefits.png';
import { INSURANCE_TYPE, INS_EMP_PRODUCT_DETAILS } from '../../constant/insurance';
import { ProductDescAccordions } from '../ProductDescCards';
import { useInsFeatureStyles } from './useInsFeatureStyles';

const EmployeeInsuranceCta = () => {
	const { t } = useTranslation();
	const { classes, cx } = useInsFeatureStyles();
	const navigate = useNavigate();

	const navigateToGetQuote = () => {
		navigate(`/quote/${INSURANCE_TYPE.EMPLOYEE}`);
	};

	return (
		<Stack className={cx(classes.insuranceStackContainer, classes.reverseOrderMobile)}>
			<Stack className={classes.insuranceCtaContainer}>
				<Typography variant="h3">{t('FOR_MY_EMPLOYEES')}</Typography>
				<Typography variant="h5">{t('FOR_MY_EMPLOYEES_DESC')}</Typography>
				<Button color="primary" variant="contained" endIcon={<EastIcon />} className={classes.getQuoteBtn} disableElevation>
					<Typography variant="button" onClick={navigateToGetQuote}>
						{t('GET_A_FREE_QUOTE_LBL')}
					</Typography>
				</Button>
			</Stack>
			<img src={employeeBenefitsPng} alt="employee-benefits-insurance" className={classes.img} />
		</Stack>
	);
};

const EmployeeInsuranceProductAccordions = () => {
	return <ProductDescAccordions productList={INS_EMP_PRODUCT_DETAILS} cardColor={'#F7F7F7'} />;
};

const EmployeeFeatures = () => {
	return (
		<Stack rowGap={2}>
			<EmployeeInsuranceCta />
			<EmployeeInsuranceProductAccordions />
		</Stack>
	);
};

export default EmployeeFeatures;
