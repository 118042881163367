import { Box, FormHelperText, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	label: {
		width: '100%'
	},
	container: {
		minHeight: 56
	},
	autoComplete: {
		'& .MuiAutocomplete-tag': {
			background: 'rgba(247, 226, 30, 0.2)'
		},
		'&  .MuiSvgIcon-root': {
			color: '#333333'
		}
	}
}));

const MultiSelectDropdown = (props) => {
	const { classes } = useStyles();
	const { t } = useTranslation();

	const { label, options, error, onChange, value: inputValue, placeholder, helperText, style, ...rest } = props || {};

	return (
		<Box sx={{ width: '100%' }}>
			<Typography variant="h6" className={classes.label}>
				{t(label)}
			</Typography>
			<Autocomplete
				className={classes.autoComplete}
				style={{ root: classes.container, ...style }}
				options={options}
				getOptionLabel={(option) => option}
				value={inputValue === '' ? null : inputValue}
				onChange={onChange}
				isOptionEqualToValue={(option: any, curValue: any) => option?.value === curValue?.value}
				renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t(placeholder)} color="primary" />}
				renderOption={(optionProp: any, option: any, optionState: any) => {
					return (
						<li {...optionProp}>
							<Typography variant="subtitle1">{t(option?.label ?? option)}</Typography>
						</li>
					);
				}}
				componentsProps={{
					popper: {
						placement: 'auto'
					}
				}}
				{...rest}
			/>
			{error && <FormHelperText style={{ color: 'red' }}>{helperText}</FormHelperText>}
		</Box>
	);
};
export default MultiSelectDropdown;
