import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { makeStyles } from 'tss-react/mui';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material';
import { useScreenViews } from '../effects/useScreenViews';

const useStyles = makeStyles()((theme) => ({
	arrowIcons: {
		width: 32,
		height: 32,
		position: 'absolute',
		zIndex: 2,
		top: 'calc(50% - 15px)',
		cursor: 'pointer'
	},
	indicator: {
		width: '8px',
		height: '8px',
		display: 'inline-block',
		margin: '0 8px',
		borderRadius: '50%',
		background: 'beige'
	}
}));

const CarouselAutoplay = (prop) => {
	const { children, isHeader } = prop || {};
	const { classes } = useStyles();
	const theme = useTheme();
	const { belowMobileView } = useScreenViews();

	const HandleArrowPrev = (props) => {
		const { clickHandler, hasPrev } = props || {};

		return (
			hasPrev && (
				<ChevronLeftIcon
					onClick={clickHandler}
					className={classes.arrowIcons}
					style={{
						background: ' rgba(255, 255, 255, 0.50)',
						color: theme.surfaces.contrastText,
						left: 0,
						width: '40px',
						height: '40px',
						borderRadius: '40px',
						top: belowMobileView && isHeader ? '35%' : '50%'
					}}
				/>
			)
		);
	};

	const HandleArrowNext = (props) => {
		const { clickHandler, hasNext } = props || {};

		return (
			hasNext && (
				<ChevronRightIcon
					onClick={clickHandler}
					className={classes.arrowIcons}
					style={{
						background: ' rgba(255, 255, 255, 0.50)',
						color: theme.surfaces.contrastText,
						right: 0,
						width: '40px',
						height: '40px',
						borderRadius: '40px',
						top: belowMobileView && isHeader ? '35%' : '50%'
					}}
				/>
			)
		);
	};

	const HandleIndicator = (props) => {
		const { clickHandler, isSelected, index, label } = props || {};

		if (isSelected) {
			return (
				<li
					className={classes.indicator}
					style={{ background: theme.palette.primary.main }}
					aria-label={`Selected: ${label} ${index + 1}`}
					title={`Selected: ${label} ${index + 1}`}
				/>
			);
		} else {
			return (
				<li
					style={{ textAlign: 'right' }}
					className={classes.indicator}
					onClick={clickHandler}
					onKeyDown={clickHandler}
					value={index}
					key={index}
					role="button"
					tabIndex={0}
					title={`${label} ${index + 1}`}
					aria-label={`${label} ${index + 1}`}
				/>
			);
		}
	};

	return (
		<Carousel
			showThumbs={false}
			autoPlay
			interval={5000}
			transitionTime={1500}
			infiniteLoop
			emulateTouch
			preventMovementUntilSwipeScrollTolerance
			renderArrowPrev={(clickHandler, hasPrev) => <HandleArrowPrev clickHandler={clickHandler} hasPrev={hasPrev} />}
			renderArrowNext={(clickHandler, hasNext) => <HandleArrowNext clickHandler={clickHandler} hasNext={hasNext} />}
			renderIndicator={(clickHandler, isSelected, index, label) => (
				<HandleIndicator clickHandler={clickHandler} isSelected={isSelected} index={index} label={label} />
			)}>
			{children}
		</Carousel>
	);
};

export default CarouselAutoplay;
