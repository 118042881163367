/* eslint-disable max-len */
import Slide1 from '../assets/images/landing/slide1.png';
import Slide2 from '../assets/images/landing/slide2.png';
import Slide3 from '../assets/images/landing/slide3.png';
import Slide4 from '../assets/images/landing/slide4.png';
import Feedback1 from '../assets/svg/landing/feedback1.svg';
import Feedback2 from '../assets/svg/landing/feedback2.svg';
import Feedback3 from '../assets/svg/landing/feedback3.svg';
import Feedback4 from '../assets/svg/landing/feedback4.svg';

export const LANDING_HEADER_SLIDESHOW = [
	{
		img: Slide1,
		backgroundColor: `var(--linear, linear-gradient(90deg, #DFE0F3 11.46%, #EAE7D2 65.62%, #F2EAA4 100%))`,
		subTitle: 'SLIDE_BANNER_SUBTITLE1',
		mainTitle: 'SLIDE_BANNER_MAINTITLE1',
		description: 'SLIDE_BANNER_DESCRIPTION1',
		btnTitle: 'LEARN_MORE_BTN',
		btnLink: '#forYourBusiness'
	},
	{
		img: Slide2,
		backgroundColor: `var(--linear, linear-gradient(90deg, #DFE0F3 11.46%, #EAE7D2 65.62%, #F2EAA4 100%))`,
		subTitle: 'SLIDE_BANNER_SUBTITLE2',
		mainTitle: 'SLIDE_BANNER_MAINTITLE2',
		description: 'SLIDE_BANNER_DESCRIPTION2',
		btnTitle: 'LEARN_MORE_BTN',
		btnLink: '#forPersonalNeed'
	},
	{
		img: Slide3,
		backgroundColor: `var(--MUI-palette-light-surface-variant, #EEE);`,
		subTitle: 'SLIDE_BANNER_SUBTITLE3',
		mainTitle: 'SLIDE_BANNER_MAINTITLE3',
		description: 'SLIDE_BANNER_DESCRIPTION3',
		btnTitle: 'FIND_OUT_MORE_BTN',
		btnLink: 'contactDialogForm'
	},
	{
		img: Slide4,
		backgroundColor: `var(--MUI-palette-light-surface-variant, #EEE);`,
		subTitle: 'SLIDE_BANNER_SUBTITLE4',
		mainTitle: 'SLIDE_BANNER_MAINTITLE4',
		description: 'SLIDE_BANNER_DESCRIPTION4',
		btnTitle: 'GET_A_FREE_CONSULTANT_BTN',
		btnLink: 'contactDialogForm'
	}
];

export const CUSTOMER_FEEDBACK_SLIDESHOW = [
	{
		img: Feedback1,
		feedback: 'CUSTOMER_STORIES_FEEDBACK1',
		name: 'CUSTOMER_STORIES_NAME1',
		descLeft: 'CUSTOMER_STORIES_DESCLEFT1',
		rating: 5
	},
	{
		img: Feedback2,
		feedback: 'CUSTOMER_STORIES_FEEDBACK2',
		name: 'CUSTOMER_STORIES_NAME2',
		descLeft: 'CUSTOMER_STORIES_DESCLEFT2',
		rating: 5
	},
	{
		img: Feedback3,
		feedback: 'CUSTOMER_STORIES_FEEDBACK3',
		name: 'CUSTOMER_STORIES_NAME3',
		descLeft: 'CUSTOMER_STORIES_DESCLEFT3',
		rating: 5
	},
	{
		img: Feedback4,
		feedback: 'CUSTOMER_STORIES_FEEDBACK4',
		name: 'CUSTOMER_STORIES_NAME4',
		descLeft: 'CUSTOMER_STORIES_DESCLEFT4',
		rating: 5
	}
];
