import EastIcon from '@mui/icons-material/East';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { useScreenViews } from '../effects/useScreenViews';
import ProductAccordion from './ProductAccordion';
import TickedBulletList from './TickedBulletList';

const useStyles = makeStyles<{ cardColor?: any; cardWidth?: any; gridColumnCount?: any }>()((theme, { cardColor, cardWidth, gridColumnCount }) => ({
	stackContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(${gridColumnCount}, 1fr)`,
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '1fr'
		},
		gap: '20px',
		justifyContent: 'center'
	},
	card: {
		minHeight: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: '24px',
		textAlign: 'left',
		backgroundColor: cardColor,
		width: cardWidth ?? '100%',
		[theme.breakpoints.down('md')]: {
			width: '100%'
		}
	},
	cardContent: {
		padding: 0,
		'&:last-child': {
			paddingBottom: 0
		}
	},
	subItemListStack: {
		'& > .MuiTypography-root , p': {
			color: theme.surfaces.contrastText
		}
	}
}));

export const DescStacks = (props: any) => {
	const { desc, itemList, subtitle, subItemList } = props || {};
	const { classes } = useStyles({});
	const { t } = useTranslation();

	return (
		<>
			<Stack>
				{desc && <Typography variant="body1">{t(desc)}</Typography>}
				{itemList && <TickedBulletList listItems={itemList} textVariant={'body1'} />}
			</Stack>
			{subtitle && subItemList && (
				<Stack className={classes.subItemListStack}>
					{subtitle && <Typography variant="h6">{t(subtitle)}</Typography>}
					{subItemList && <TickedBulletList listItems={subItemList} textVariant={'body1'} />}
				</Stack>
			)}
		</>
	);
};

const ProductCard = (props: any) => {
	const { title, cardColor, cardWidth, isCTAEnabled, ctaLabel, titleFontSize, openContactDialogForm, ...descStackProps } = props || {};
	const { classes } = useStyles({ cardWidth, cardColor });
	const { t } = useTranslation();

	return (
		<Card className={classes.card} elevation={0}>
			<CardContent className={classes.cardContent}>
				<Stack gap={2}>
					{title && (
						<Typography variant="h4" sx={{ fontSize: titleFontSize ?? '20px' }}>
							{t(title)}
						</Typography>
					)}
					<DescStacks {...descStackProps} />
				</Stack>
			</CardContent>
			{isCTAEnabled && (
				<Button
					onClick={openContactDialogForm}
					endIcon={<EastIcon />}
					variant="text"
					color="inherit"
					sx={{ width: 'fit-content', padding: '0px', minHeight: '0px', ':hover': { backgroundColor: 'transparent' } }}>
					<Typography variant="h4" borderBottom={`2px solid black`}>
						{t(ctaLabel)}
					</Typography>
				</Button>
			)}
		</Card>
	);
};

export const ProductDescAccordions = (props: any) => {
	const { children, productList, gridColumnCount, ...rest }: any = props || {};
	const { belowTabletView } = useScreenViews();

	if (belowTabletView) {
		return (
			<Stack justifyContent={'center'} rowGap={'20px'}>
				{productList?.map((product, index) => {
					return <ProductAccordion key={index} {...product} {...rest} />;
				})}
			</Stack>
		);
	}

	return <ProductDescCards {...props} />;
};

const ProductDescCards = (props: any) => {
	const { children, productList, gridColumnCount, ...rest }: any = props || {};
	const { classes } = useStyles({ gridColumnCount: gridColumnCount || 3 });

	return (
		<Stack className={classes.stackContainer}>
			{productList?.map((product, index) => {
				return <ProductCard key={index} {...product} {...rest} />;
			})}
		</Stack>
	);
};

export default ProductDescCards;
