import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	AppBar,
	Box,
	Button,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Typography
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { withStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { env } from '../../helpers/EnvHelper';
import { PATHS_BY_ENV } from '../../constant/paths';
import { LANDING_HEADER_COMPANY, LANDING_HEADER_PRODUCTS, LANDING_HEADER_PROMOTIONS, LANDING_HEADER_SUPPORT } from '../../constant/landing';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()((theme) => ({
	menu: {
		top: '-3px',
		height: '87%',
		'& .MuiButtonBase-root': {
			padding: '0px'
		},
		'& .MuiPaper-root': {
			minWidth: '-webkit-fill-available',
			left: '0px !important'
		},
		'& ul.MuiList-padding': {
			padding: 0,
			height: '100%',
			width: '100%',
			display: 'flex !important',
			flexDirection: 'column',
			justifyContent: 'space-between !important'
		},
		'& .MuiMenu-paper': {
			height: '100%',
			display: 'flex !important',
			justifyContent: 'space-between !important'
		}
	},
	menuButton: {
		paddingLeft: 0,
		paddingRight: 0
	},
	accordion: {
		width: '100%',
		boxShadow: 'none',
		border: 'none',
		margin: 0,
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0,0.0)'
		},
		'& .Mui-expanded': {
			margin: 0,
			padding: 0
		},
		'& .MuiButtonBase-root': {
			padding: '0px'
		},
		'& .MuiSvgIcon-root': {
			color: 'black'
		}
	},
	accordionSummary: {
		border: 'none !important',
		width: 'auto !important',
		paddingLeft: 0,
		paddingRight: '24px !important',
		[theme.breakpoints.down('md')]: {
			width: '97%'
		},
		[theme.breakpoints.down('sm')]: {
			paddingRight: '16px !important',
			width: '96%'
		}
	},
	accordionDetails: {
		width: 'auto',
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		'& > a': {
			display: 'flex',
			padding: 8,
			flexDirection: 'column',
			width: '90%',
			borderRadius: 8,
			textDecoration: 'none',
			color: `${theme.palette.secondary.main} !important`,
			'&:hover': {
				'& .MuiTypography-h4': {
					color: theme.palette.secondary.main
				}
			}
		}
	},
	wrapAccordion: {
		overflowY: 'auto',
		WebkitOverflowScrolling: 'touch',
		scrollbarWidth: 'none',
		padding: '0px !important',
		'&::-webkit-scrollbar': {
			display: 'none'
		}
	},
	link: {
		textDecoration: 'none',
		color: 'black',
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	contactBtn: {
		borderRadius: '320px',
		width: '138px',
		marginTop: 4,
		height: '48px',
		padding: '12px 24px',
		background: theme.surfaceVariants.primary.onSurface,
		color: 'white'
	}
}));

const StyledToolbar = withStyles((theme: any) => ({
	root: {
		justifyContent: 'space-between',
		height: '56px',
		minHeight: '56px'
	}
}))(Toolbar);

const AccordionMenu = (props: any) => {
	const { menuText, dataSrc, closeMenu } = props;
	const { classes, cx } = useStyles();

	return (
		<MenuItem disableRipple>
			<Accordion className={classes.accordion}>
				<AccordionSummary disableRipple className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
					<Typography padding="3px 1rem">{menuText}</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.accordionDetails}>
					{dataSrc?.map((item: any) => {
						return (
							<Box key={`${item?.title}`}>
								<Typography variant="h4">{item?.title}</Typography>
								{item?.components &&
									item?.components.map((comp, index) => (
										<Box key={index}>
											<Link to={`${PATHS_BY_ENV[env][comp?.link]}${comp?.propsLink ?? ''}`} onClick={closeMenu} className={classes.link}>
												<Typography padding={1}>{comp?.title}</Typography>
											</Link>
										</Box>
									))}
								<Divider />
							</Box>
						);
					})}
				</AccordionDetails>
			</Accordion>
		</MenuItem>
	);
};

const MobileHeader = (props: any) => {
	const { mMLogo, openContactDialogForm }: any = props || {};
	const { classes, cx } = useStyles();
	const appBarRef = useRef(null);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isFixed, setIsFixed] = useState(false);
	const [isShowNavi, setIsShowNavi] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;

			if (scrollPosition === 0) {
				setIsFixed(false);
			} else {
				setIsFixed(true);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClick = () => {
		isShowNavi ? closeMenu() : openMenu();
	};

	const openMenu = () => {
		setAnchorEl(appBarRef.current);
		setIsShowNavi(true);
	};

	const closeMenu = () => {
		setAnchorEl(null);
		setIsShowNavi(false);
	};

	return (
		<AppBar
			position="fixed"
			sx={{
				boxShadow: isFixed ? '0 8px 24px rgb(0 0 0 / 13%)' : '',
				backdropFilter: isFixed ? 'blur(20px)' : 'blur(0px)',
				backgroundColor: isShowNavi ? 'white' : 'none'
			}}
			color="inherit"
			ref={appBarRef}>
			<StyledToolbar>
				<Link to={PATHS_BY_ENV[env]?.landingPageLink} style={{ textDecoration: 'none' }} color="inherit">
					<img src={mMLogo} alt="MoneyMatchLogo" width={160} height={40} />
				</Link>
				<IconButton className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleClick}>
					{isShowNavi ? <CloseIcon /> : <MenuIcon />}
				</IconButton>
			</StyledToolbar>
			<Menu
				className={classes.menu}
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={closeMenu}
				disableScrollLock={true}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
				<div className={classes.wrapAccordion}>
					<AccordionMenu menuText={'Products'} dataSrc={LANDING_HEADER_PRODUCTS} closeMenu={closeMenu} />
					<MenuItem>
						<Link className={classes.link} to={PATHS_BY_ENV[env][LANDING_HEADER_PROMOTIONS.link]} onClick={closeMenu}>
							<Typography padding="1rem">{LANDING_HEADER_PROMOTIONS.title}</Typography>
						</Link>
					</MenuItem>
					<AccordionMenu menuText={'Company'} dataSrc={LANDING_HEADER_COMPANY} closeMenu={closeMenu} />
					<MenuItem>
						<Link className={classes.link} to={PATHS_BY_ENV[env][LANDING_HEADER_SUPPORT.link]} onClick={closeMenu}>
							<Typography padding="1rem">{LANDING_HEADER_SUPPORT.title}</Typography>
						</Link>
					</MenuItem>
				</div>

				<Grid container direction="row" columnGap={2} justifyContent="center" marginBottom={2}>
					<Grid item xs={5}>
						<Button fullWidth variant="contained" onClick={openContactDialogForm} className={classes.contactBtn} color="primary">
							<Typography variant="body1">{t('CONTACT_US')}</Typography>
						</Button>
					</Grid>
				</Grid>
			</Menu>
		</AppBar>
	);
};

export default MobileHeader;
