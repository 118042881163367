import { Divider, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useInsuranceFormContext } from '../../../../context/InsuranceFormContext';
import i18n from '../../../../i18n';
import { useCommonStyles } from '../../../../styles/common';
import BasicTextInput from '../../../generalFormComponents/BasicTextInput';
import PriceTextInput from '../../../generalFormComponents/PriceTextInput';
import NavigationButtons from '../NavigationButtons';

const useStyles = makeStyles()((theme) => ({
	title: {
		color: '#D1D1D1'
	},
	priceInput: {
		width: '100%'
	},
	dateInput: {
		width: '100%'
	}
}));

const OtherSumInsuredDetails = (props) => {
	const { classes: commonClasses } = useCommonStyles({});
	const { currentStep, setCurrentStep, prevTab, nextTab } = props;
	const { classes } = useStyles();
	const { t } = useTranslation();
	const MAX_CHARS = 120;
	const { applicantDetails, setActiveMenu, setApplicantDetails, setIsAppDetailsComplete }: any = useInsuranceFormContext();
	const { sumToInsure: ctxSumToInsure, additionalInfo: ctxAdditionalInfo }: any = applicantDetails || {};

	const handlePrev = () => {
		if (currentStep === 2) {
			setCurrentStep(1);
			return;
		}
		setActiveMenu(prevTab);
	};
	const handleNext = async () => {
		formik.handleSubmit();
	};

	const validationSchema = yup.object({
		sumToInsure: yup.number().min(1).positive(i18n.t('PRICE_MUST_POSITIVE_ERR')).integer().required(i18n.t('REQUIRED')),
		additionalInfo: yup.string().max(MAX_CHARS, t('MAX_CHARS'))
	});

	const formik = useFormik({
		initialValues: {
			sumToInsure: ctxSumToInsure ?? 0,
			additionalInfo: ctxAdditionalInfo ?? ''
		},
		validationSchema,
		onSubmit: async (values) => {
			const { sumToInsure, additionalInfo } = values || {};

			setApplicantDetails((prev) => {
				return {
					...prev,
					sumToInsure,
					additionalInfo
				};
			});
			setIsAppDetailsComplete(true);
			setActiveMenu(nextTab);
		}
	});
	return (
		<Grid item>
			<Grid container spacing={2} direction="column" justifyContent="space-evenly">
				<Grid item xs={12}>
					<Typography variant="h6" className={classes.title}>
						{t('OTHER_SUM_INSURED_DETAILS_LBL')}
					</Typography>
					<Divider />
				</Grid>
				<Grid item xs>
					<PriceTextInput
						label={'OTHERS_SUM_TO_INSURED_LBL'}
						currency={'MYR'}
						infoTipsText={''}
						value={formik.values.sumToInsure}
						onChange={(e) => {
							formik.setFieldValue('sumToInsure', e.target.value);
						}}
						error={formik.touched.sumToInsure && Boolean(formik.errors.sumToInsure)}
						helperText={formik.errors.sumToInsure}
						className={classes.priceInput}
					/>
				</Grid>
				<Grid item xs={12}>
					<BasicTextInput
						label={'ADDITIONAL_INFO_LBL'}
						inputProps={{ maxLength: MAX_CHARS }}
						minRows="3"
						multiline
						value={formik.values.additionalInfo}
						placeholder={t('ADDITIONAL_INFO_PLACEHOLDER')}
						showHelperText
						helperText={`${formik.values.additionalInfo.length}/${MAX_CHARS}`}
						onChange={(e) => {
							formik.setFieldValue('additionalInfo', e.target.value);
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<NavigationButtons handlePrev={handlePrev} handleNext={handleNext} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default OtherSumInsuredDetails;
