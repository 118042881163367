import { ListItem, ListItemIcon, ListItemText, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as checkCircle } from '../assets/svg/checkCircleIcon.svg';

const useStyles = makeStyles<{ props?: any }>()((theme, { props }) => ({
	listItem: {
		padding: 0
	},
	listItemIcon: {
		minWidth: '20px'
	},
	checkCircle: {
		width: 15,
		height: 15,
		verticalAlign: 'middle'
	}
}));

const TickedBulletList = (props) => {
	const { classes } = useStyles({ ...props });
	const { t } = useTranslation();
	const { listItems, textVariant } = props || {};

	return listItems?.map((item, index) => (
		<ListItem key={index} className={classes.listItem}>
			<ListItemIcon className={classes.listItemIcon}>
				<SvgIcon component={checkCircle} className={classes.checkCircle} viewBox="0 0 15 14" />
			</ListItemIcon>
			<ListItemText primary={<Typography variant={textVariant || 'caption'}>{t(item)}</Typography>} />
		</ListItem>
	));
};

export default TickedBulletList;
