import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { LANDING_HEADER_SLIDESHOW } from '../constant/carouselSlides';
import { useScreenViews } from '../effects/useScreenViews';
import CarouselAutoplay from './CarouselAutoplay';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()((theme) => ({
	wrapBanner: {
		width: '1240px',
		[theme.breakpoints.down('lg')]: {
			width: '90%'
		},
		[theme.breakpoints.down('md')]: {
			width: '100%'
		}
	},
	wrapImgGrid: {
		[theme.breakpoints.down('sm')]: {
			marginTop: '2rem'
		}
	},
	wrapContent: {
		minHeight: '440px',
		[theme.breakpoints.down('md')]: {
			minHeight: '500px'
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: '750px'
		}
	},
	wrapBox: {
		padding: '72px 0px 72px 64px',
		width: '100%',
		textAlign: 'left',
		[theme.breakpoints.down('md')]: {
			padding: '72px 0px 72px 50px'
		},
		[theme.breakpoints.down('sm')]: {
			height: '300px',
			marginLeft: '0rem',
			padding: '24px 16px'
		}
	},
	btn: {
		marginTop: '32px',
		padding: '12px 16px'
	}
}));

const MainCTA = ({ openContactDialogForm }) => {
	const { classes } = useStyles();
	const { belowMobileView, belowTabletView } = useScreenViews();
	const { t } = useTranslation();
	const theme = useTheme();

	const onClickBtn = (linkID) => {
		if (linkID === 'contactDialogForm') {
			openContactDialogForm();
		} else {
			document.querySelector(linkID).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
		}
	};

	return (
		<Grid container width="100%" marginTop={belowTabletView ? 7 : 12} justifyContent="center" id="bannerSlideShow">
			<Grid item className={classes.wrapBanner}>
				<CarouselAutoplay isHeader={true}>
					{LANDING_HEADER_SLIDESHOW.map((item, index) => (
						<Grid
							key={index}
							container
							direction={belowMobileView ? 'column-reverse' : 'row'}
							alignItems="center"
							justifyContent="right"
							className={classes.wrapContent}
							sx={{ background: item?.backgroundColor }}>
							<Grid item xs={12} sm={7} md={6}>
								<Box className={classes.wrapBox}>
									<Typography variant="h4" color={theme.surfaces.contrastText}>
										{t(item?.subTitle)}
									</Typography>
									<Typography
										variant="inherit"
										fontSize={{ lg: '52px', xs: '40px' }}
										lineHeight={{ lg: '60px', sm: '48px', xs: '42px' }}
										fontWeight="700">
										{t(item?.mainTitle)}
									</Typography>
									<Typography variant="inherit" fontSize={{ lg: '20px', xs: '18px' }} fontWeight="600" color={theme.surfaces.contrastText}>
										{t(item?.description)}
									</Typography>
									<Button onClick={() => onClickBtn(item?.btnLink)} variant="contained" color="primary" className={classes.btn}>
										{t(item?.btnTitle)}
									</Button>
								</Box>
							</Grid>
							<Grid item xs={12} sm={5} md={6} display="flex" justifyContent="end" className={classes.wrapImgGrid}>
								<img src={item?.img} alt={item?.mainTitle} style={{ height: '100%', width: '100%' }} />
							</Grid>
						</Grid>
					))}
				</CarouselAutoplay>
			</Grid>
		</Grid>
	);
};

export default MainCTA;
