import autoBind from 'auto-bind';
import { AxiosInstance } from 'axios';
import { API } from '../../baseUrl';
import create from '../../common.http';

export default class QuotationService {
	private insApi: AxiosInstance;

	constructor() {
		this.insApi = create(API.INS);
		autoBind(this);
	}

	public async postContactForm(req: any, token: string): Promise<any> {
		const response = await this.insApi.post(`/web/contact-form/submit`, req, { headers: { 'x-captcha-token': token } });
		return response;
	}

	public async getProtectionTypes(): Promise<any> {
		const response = await this.insApi.get(`/web/contact-form/protection-types`);
		return response.data;
	}

	public async postQuotationForm(req: any, token: string): Promise<any> {
		const response = await this.insApi.post(`/web/quotation/submit`, req, { headers: { 'x-captcha-token': token } });
		return response;
	}
}
