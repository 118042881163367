import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from 'tss-react/mui';
import { isEmptyArray } from '../../helpers/Array';
import { isEmpty } from '../../helpers/String';

const useStyles = makeStyles()((theme) => ({
	label: {
		marginTop: 20,
		marginBottom: 10
	},
	radioButton: {
		color: 'black'
	},
	textInput: {
		marginBottom: 10
	}
}));

const StyledFormLabel = withStyles(FormLabel, (theme: any, _params, classes) => ({
	root: {
		color: 'black',
		[`&.${classes.focused}`]: {
			color: 'black'
		}
	},
	focused: {
		color: 'black'
	}
}));

const RadioButtons = (props) => {
	const { label, defaultValue, dataSrc, handleChange, radioGroupName } = props || {};
	const { classes } = useStyles();
	const { t } = useTranslation();

	const renderRadioButtons = () => {
		if (isEmpty(dataSrc) || isEmptyArray(dataSrc)) {
			return <></>;
		}

		return dataSrc.map((item, index) => {
			const { value: itemValue, label: itemLabel } = item || {};
			return (
				<FormControlLabel
					key={index}
					className={classes.radioButton}
					value={itemValue.toString()}
					control={<Radio color="secondary" />}
					label={<Typography variant="subtitle1">{t(itemLabel)}</Typography>}
				/>
			);
		});
	};

	return (
		<FormControl component="fieldset">
			<StyledFormLabel className={classes.label}>
				<Typography variant="h6">{t(label)}</Typography>
			</StyledFormLabel>
			<RadioGroup defaultValue={defaultValue} name={radioGroupName} onChange={handleChange}>
				{renderRadioButtons()}
			</RadioGroup>
		</FormControl>
	);
};

export default RadioButtons;
