import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
	Button,
	Card,
	CardHeader,
	CardMedia,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Grid,
	Link,
	List,
	Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';
import {
	INSURANCE_OTHERS_CHECKBOX,
	INSURANCE_TYPE,
	INSURANCE_TYPE_DESC,
	KEY_COVERAGES_SELLING_POINTS,
	QUEST_FOR_EXISTING_POLICY_BY_TYPE
} from '../../../constant/insurance';
import { useInsuranceFormContext } from '../../../context/InsuranceFormContext';
import { useStateSafe } from '../../../effects/useStateSafe';
import { isEmptyArray } from '../../../helpers/Array';
import TickedBulletList from '../../TickedBulletList';
import FormButton from '../../generalFormComponents/FormButton';
import RadioButtons from '../../generalFormComponents/RadioButtons';

const useStyles = makeStyles<{ props?: any }>()((theme, { props }) => ({
	card: {
		display: 'flex',
		minHeight: 'auto',
		height: '100%',
		padding: props?.padding ?? '26px 36px',
		border: props?.border ?? '1px solid #F7E21E',
		boxShadow: '0px 3px 8px #F2F2F2',
		[theme.breakpoints.down('sm')]: {
			padding: '0px'
		}
	},
	cardHeader: {
		[theme.breakpoints.up('md')]: {
			padding: '0px',
			paddingLeft: '16px'
		}
	},
	media: {
		width: '30%',
		display: 'inline-flex',
		objectFit: 'contain',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '8px',
			width: '25%'
		}
	},
	changeInsuranceType: {
		textAlignLast: 'right',
		cursor: 'pointer',
		marginTop: 10
	},
	KEYCOVERAGESSection: {
		marginTop: 20
	}
}));

export const InsuranceInfoCard = (props) => {
	const { img, title, desc, customStyle } = props || {};
	const { classes } = useStyles({ props: customStyle });
	const { t } = useTranslation();
	return (
		<Grid container>
			<Card className={classes.card} variant="outlined">
				<CardMedia component="img" className={classes.media} image={img} />
				<CardHeader
					className={classes.cardHeader}
					title={<Typography variant="h4">{t(title)}</Typography>}
					subheader={<Typography variant="body2">{t(desc)}</Typography>}
				/>
			</Card>
		</Grid>
	);
};

const KeyCoverageSection = (props) => {
	const { classes } = useStyles({ ...props });
	const { t } = useTranslation();
	const { dataSrc } = props || {};
	const gridSize = dataSrc && dataSrc.length > 1 ? 6 : 12;

	const renderListItem = (sellingPoints) => {
		return <TickedBulletList listItems={sellingPoints} />;
	};

	return (
		<Grid container className={classes.KEYCOVERAGESSection}>
			<Typography variant="h6">{t('KEY_COVERAGES_LBL')}</Typography>
			<Grid container spacing={2}>
				{dataSrc &&
					dataSrc.map((item, index) => {
						const { main, sellingPoints } = item;
						return (
							<Grid item key={index} xs={gridSize}>
								<Typography variant="subtitle2">{t(main)}</Typography>
								<List>{renderListItem(sellingPoints)}</List>
							</Grid>
						);
					})}
			</Grid>
		</Grid>
	);
};

const ProtectionCheckboxes = (props) => {
	const { formik, protectionTypes, setProtectionTypes } = props;

	useEffect(() => {
		formik.setFieldValue('protectionTypes', protectionTypes);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [protectionTypes]);

	const handleChange = (e) => {
		setProtectionTypes((prev) => {
			if (e.target.checked && !prev.includes(e.target.value)) return [...prev, e.target.value];
			return [...prev.filter((value) => value !== e.target.value)];
		});
	};

	return (
		<Grid container direction="column">
			<FormControl>
				<Typography variant="h6">I am looking for a plan protects me from</Typography>
				<FormGroup>
					{INSURANCE_OTHERS_CHECKBOX.map((value, index) => {
						return (
							<FormControlLabel
								key={value.CHECKBOX_DESC + index}
								control={
									<Checkbox color="default" onChange={handleChange} checked={Boolean(protectionTypes.includes(value.CHECKBOX_DESC)) || false} />
								}
								value={value.CHECKBOX_DESC}
								label={value.CHECKBOX_DESC}
							/>
						);
					})}
				</FormGroup>
				{formik.touched.protectionTypes && Boolean(formik.errors.protectionTypes) && (
					<FormHelperText style={{ color: 'red' }}>{formik.errors.protectionTypes}</FormHelperText>
				)}
			</FormControl>
		</Grid>
	);
};

const TypeOfInsurance = (props) => {
	const { type, nextTab } = props || {};
	const { classes } = useStyles({ ...props });
	const { t } = useTranslation();
	const navigate = useNavigate();
	const INSURANCE_DESC = INSURANCE_TYPE_DESC[type];
	const KEYCOVERAGES = KEY_COVERAGES_SELLING_POINTS[type];
	const {
		applicantDetails,
		setApplicantDetails,
		setInsuranceType,
		resetQuotationForm,
		setActiveMenu,
		setIsTypeOfInsComplete,
		isBuyNowPayLater,
		setIsBuyNowPayLater,
		hasExistingPolicy,
		setHasExistingPolicy
	}: any = useInsuranceFormContext();
	const { planType } = applicantDetails || null;
	const [bnplEligibility, setBnplEligibility] = useStateSafe(isBuyNowPayLater ?? 'false');
	const [protectionTypes, setProtectionTypes] = useStateSafe(planType ?? []);

	const validationSchema = yup.object({
		protectionTypes: type === INSURANCE_TYPE.ASSET_LIABILITY && yup.array().min(1, t('REQUIRED')).required(t('REQUIRED'))
	});

	const formik = useFormik({
		initialValues: { protectionTypes: protectionTypes ?? null },
		validationSchema,
		onSubmit: (values) => {
			setHasExistingPolicy(hasExistingPolicy);
			setIsBuyNowPayLater(bnplEligibility);
			setInsuranceType(type);
			setIsTypeOfInsComplete(true);
			setApplicantDetails((prev) => {
				return { ...prev, planType: protectionTypes };
			});
			setActiveMenu && setActiveMenu(nextTab);
		}
	});

	return (
		<Grid item>
			<Grid container spacing={1} direction="column" justifyContent="space-evenly">
				<Grid item container xs={12} zeroMinWidth>
					<Grid item>
						<Typography variant="h6" noWrap>
							{t('WHAT_TO_PROTECT_LBL')}
						</Typography>
					</Grid>
					<Grid item>
						<InsuranceInfoCard {...INSURANCE_DESC} />
					</Grid>
					<Grid item xs={12}>
						<Link
							className={classes.changeInsuranceType}
							component={'a'}
							color="inherit"
							onClick={() => {
								resetQuotationForm && resetQuotationForm();
								if (type === INSURANCE_TYPE.EMPLOYEE) navigate('/quote/others');
								else navigate('/quote/employee');
							}}>
							<Typography variant="h6">{t('CHANGE_INSURANCE_TYPE')}</Typography>
						</Link>
					</Grid>
				</Grid>
				{KEYCOVERAGES && !isEmptyArray(KEYCOVERAGES) && (
					<Grid item xs={12}>
						<KeyCoverageSection dataSrc={KEYCOVERAGES} />
					</Grid>
				)}
				{type === INSURANCE_TYPE.ASSET_LIABILITY && (
					<Grid item xs={12}>
						<ProtectionCheckboxes formik={formik} protectionTypes={protectionTypes} setProtectionTypes={setProtectionTypes} />
					</Grid>
				)}
				<Grid item xs={12}>
					<RadioButtons
						label={QUEST_FOR_EXISTING_POLICY_BY_TYPE[type]}
						dataSrc={[
							{ value: 'true', label: 'HAS_EXISTING_YES' },
							{ value: 'false', label: 'HAS_EXISTING_NO' }
						]}
						value={hasExistingPolicy.toString()}
						defaultValue={hasExistingPolicy.toString()}
						radioGroupName="has-old-policy-radio-group"
						handleChange={(e) => {
							setHasExistingPolicy(e.target.value === 'true');
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<RadioButtons
						label={t('DO_YOU_WANT_BNPL_ELIGIBILITY_CHECK')}
						dataSrc={[
							{ value: 'true', label: 'HAS_EXISTING_YES' },
							{ value: 'false', label: 'No' }
						]}
						value={bnplEligibility.toString()}
						defaultValue={bnplEligibility.toString()}
						radioGroupName="bnpl-radio-group"
						handleChange={(e) => {
							setBnplEligibility(e.target.value === 'true');
						}}
					/>
				</Grid>
				<Grid item container spacing={2} direction="row" mt={1}>
					<Grid item xs={6}>
						<Button
							variant="outlined"
							fullWidth
							color="inherit"
							size="large"
							startIcon={<ChevronLeftIcon />}
							children={t('BACK_LBL')}
							onClick={() => {
								navigate('/');
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormButton
							fullWidth
							onClick={() => {
								formik.handleSubmit();
							}}
							buttonText={'GET_A_QUOTE_LBL'}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TypeOfInsurance;
