import { useStateSafe } from '../../../../effects/useStateSafe';
import BuildingDetails from './BuildingDetails';
import BusinessNature from './BusinessNature';
import OtherSumInsuredDetails from './OtherSumInsuredDetails';

const ApplicantDetailsOthers = (props) => {
	const { prevTab, nextTab } = props;
	const [currentStep, setCurrentStep] = useStateSafe(1);

	const renderComp = () => {
		switch (currentStep) {
			case 1:
				return <BusinessNature setCurrentStep={setCurrentStep} prevTab={prevTab} />;
			case 2:
				return <BuildingDetails setCurrentStep={setCurrentStep} />;
			case 3:
				return <OtherSumInsuredDetails nextTab={nextTab} />;
			default:
				return <></>;
		}
	};

	return <>{renderComp()}</>;
};

export default ApplicantDetailsOthers;
