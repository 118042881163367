import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useScreenViews } from '../effects/useScreenViews';
import CustomGrid from './CustomGrid';
import EastIcon from '@mui/icons-material/East';
import { PATHS_BY_ENV } from '../constant/paths';
import { env } from '../helpers/EnvHelper';
import CustomerSupportImg from '../assets/images/landing/customerSupportImg.png';
import { useTranslation } from 'react-i18next';
import { CONTACT_INFO } from '../constant/insurance';

const useStyles = makeStyles()((theme) => ({
	wrapInfoBox: {
		background: theme.palette.background.paper,
		padding: '64px 0px',
		width: '100%',
		[theme.breakpoints.down('md')]: {
			padding: '32px 0px !important'
		},
		[theme.breakpoints.down('sm')]: {
			padding: '16px 0px !important'
		}
	},
	img: {
		width: 400,
		height: 260,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%'
		}
	},
	btnCS: {
		width: 'fit-content',
		padding: '0px'
	}
}));

const CustomerSupport = (props: any) => {
	const { slots }: any = props || {};
	const { classes } = useStyles();
	const { belowMobileView } = useScreenViews();
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<Box className={classes.wrapInfoBox}>
			<CustomGrid className={slots?.customGrid?.className}>
				<Grid container direction="row">
					<Grid item xs={12} md={6} padding="32px 0px" alignSelf="center">
						<img className={classes.img} src={CustomerSupportImg} alt="contact-consultant-header-svg" />
					</Grid>
					<Grid item xs={12} md={6} padding="16px 0px">
						<Stack direction="column" rowGap={4}>
							<Stack direction="column" rowGap={1}>
								<Typography variant="h6" color={theme.surfaces?.contrastText}>
									{t('CUSTOMER_SUPPORT')}
								</Typography>
								<Typography variant="h3">{t('CUSTOMER_SUPPORT_SUBTITLE')}</Typography>
								<Typography variant={belowMobileView ? 'subtitle1' : 'h5'}>{t('CUSTOMER_SUPPORT_DESC')}</Typography>
							</Stack>
							<Stack direction={{ xs: 'column', md: 'row' }} rowGap={4}>
								<Stack direction="column" rowGap={0.5} width={'100%'}>
									<Typography variant="h6" color={theme.surfaces?.contrastText}>
										{t('CONTACT_OUR_SUPPORT')}
									</Typography>
									<Box minHeight={{ md: '0rem', lg: '2.8rem' }}>
										{CONTACT_INFO.map((contact, index) => (
											<Typography variant="h6" key={index}>
												{t(contact)}
											</Typography>
										))}
									</Box>
								</Stack>
								<Stack direction="column" rowGap={0.5} width={'100%'}>
									<Typography variant="h6" color={theme.surfaces?.contrastText}>
										{t('SUPPORT_HOURS')}
									</Typography>
									<Box minHeight="2rem">
										<Typography variant="h4">{t('OPERATING_TIME')}</Typography>
										<Typography variant="subtitle1">{t('OPERATING_DAYS')}</Typography>
									</Box>
								</Stack>
							</Stack>
							<Stack direction="column">
								<Typography variant="h6" color={theme.surfaces?.contrastText}>
									{t('SUPPORT_CENTER')}
								</Typography>
								<Typography variant="subtitle1">{t('SUPPORT_CENTER_FOR_BUSINESS')}</Typography>
								<Button endIcon={<EastIcon />} href={PATHS_BY_ENV[env].supportCenterLink} variant="text" color="inherit" className={classes.btnCS}>
									<Typography variant="h6" borderBottom={`2px solid ${theme.palette.text.primary}`}>
										{t('VIEW_ARTICLES')}
									</Typography>
								</Button>
							</Stack>
						</Stack>
					</Grid>
				</Grid>
			</CustomGrid>
		</Box>
	);
};

export default CustomerSupport;
