import { Box, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as AllianzLogo } from '../assets/svg/insurance/allianzLogo.svg';
import { ReactComponent as BerjayaSompoLogo } from '../assets/svg/insurance/berjayaSompoLogo.svg';
import { ReactComponent as PrudentialLogo } from '../assets/svg/insurance/prudentialLogo.svg';
import { ReactComponent as ZurichLogo } from '../assets/svg/insurance/zurichLogo.svg';
import CustomGrid from './CustomGrid';

const useStyles = makeStyles()((theme) => ({
	mainContainer: {
		padding: '32px 96px',
		background: theme.palette.background.paper,
		[theme.breakpoints.down('md')]: {
			padding: '16px 0px 32px'
		}
	}
}));

const OurPartners = () => {
	const { classes } = useStyles();

	return (
		<CustomGrid className={classes.mainContainer}>
			<Grid container direction="row" justifyContent="center" alignItems="center">
				<Grid item xs={6} md={3}>
					<Grid container alignItems="center" justifyContent="center">
						<AllianzLogo width="135px" />
					</Grid>
				</Grid>
				<Grid item xs={6} md={3}>
					<Grid container alignItems="center" justifyContent="center">
						<PrudentialLogo width="135px" />
					</Grid>
				</Grid>
				<Grid item xs={6} md={3}>
					<Grid container alignItems="center" justifyContent="center">
						<ZurichLogo width="135px" />
					</Grid>
				</Grid>
				<Grid item xs={6} md={3}>
					<Grid container alignItems="center" justifyContent="center">
						<BerjayaSompoLogo width="157px" />
					</Grid>
				</Grid>
			</Grid>
		</CustomGrid>
	);
};

export default OurPartners;
