import { Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { PERSONAL_INS_PRODUCT_DETAILS } from '../constant/insurance';
import CustomGrid from './CustomGrid';
import ProductDescCard from './ProductDescCards';

const useStyles = makeStyles()((theme) => ({
	titleContainer: {
		[theme.breakpoints.up('md')]: {
			textAlign: 'center'
		}
	},
	mainContainer: {
		padding: '32px 0px'
	}
}));

const PersonalNeedsSection = ({ openContactDialogForm }) => {
	const { classes } = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<CustomGrid gridContainerStyle={{ background: theme.palette.background.default }} className={classes.mainContainer}>
			<Stack gap={'32px'} id="forPersonalNeed">
				<Stack direction={'column'} gap={1} className={classes.titleContainer}>
					<Typography variant="h6" color={theme.surfaces.contrastText}>
						{t('FOR_YOUR_PERSONAL_NEEDS')}
					</Typography>
					<Typography variant="h2" color={theme.surfaceVariants.primary.onSurface}>
						{t('PROTECT_WHAT_IMPORTANT')}
					</Typography>
					<Typography variant="h4" color={theme.surfaces.contrastText}>
						{t('PERSONAL_COVERAGE_DESC')}
					</Typography>
				</Stack>
				<ProductDescCard productList={PERSONAL_INS_PRODUCT_DETAILS} openContactDialogForm={openContactDialogForm} />
			</Stack>
		</CustomGrid>
	);
};

export default PersonalNeedsSection;
