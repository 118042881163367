/* eslint-disable max-len */
const insurance = {
	INSURANCE_MAIN_TITLE: 'Get excellent benefits and comprehensive<0/>protection for your employees and your assets.',
	INSURANCE_GET_QUOTE: 'Get Quote',
	INSURANCE_COMING_SOON: 'Coming Soon',

	INSURANCE_EMP_BEN_TITLE: 'Employee Benefits',
	INSURANCE_EMP_BEN_SUBTITLE: `Protect your company's most valuable assets`,
	INSURANCE_EMP_BEN_DESC: `Obtain coverage upon hospitalization and few selected outpatient treatments or obtain medical cards for medical attention at registered panel clinics for your employees. Additionally, you may get coverage against death, total or partial and permanent disability.`,
	INSURANCE_EMP_BEN_HIGHLIGHT: `"Get coverage up to RM1,000,000"`,

	INSURANCE_FIRE_TITLE: 'Fire Insurance',
	INSURANCE_FIRE_SUBTITLE: 'Protect your property against losses or damages by fire',
	INSURANCE_FIRE_DESC: 'Obtain coverage against other disasters, such as floods or impact damages are available.',
	INSURANCE_FIRE_HIGHLIGHT: '"Eliminate your worries"',

	INSURANCE_BURGLAR_TITLE: 'Burglary Insurance',
	INSURANCE_BURGLAR_SUBTITLE: `Protect your business against losses from incidents of theft`,
	INSURANCE_BURGLAR_DESC: `Obtain coverage in case your property and its contents are stolen through actual forcible or violent entry, theft by hold-up or armed robbery.`,
	INSURANCE_BURGLAR_HIGHLIGHT: `"Coverage up to full value"`,

	INSURANCE_MARINE_CARGO_TITLE: 'Marine Cargo Insurance',
	INSURANCE_MARINE_CARGO_SUBTITLE: 'Protect your goods shipped against losses or damages during the transit',
	INSURANCE_MARINE_CARGO_DESC:
		'Protect your goods shipped against losses or damages during the transit, from the time your goods leave the premises of the seller until the time they arrive at the buyer’s destination.',
	INSURANCE_MARINE_CARGO_HIGHLIGHT: '',

	INSURANCE_MARINE_HULL_TITLE: 'Marine Hull Insurance',
	INSURANCE_MARINE_HULL_SUBTITLE: 'Protect your ships and vessels against losses or damages during operations',
	INSURANCE_MARINE_HULL_DESC: 'Protect your ships and vessels against losses or damages during operations.',
	INSURANCE_MARINE_HULL_HIGHLIGHT: '',

	INSURANCE_PUBLIC_LIABILITY_TITLE: 'Public Liability Insurance',
	INSURANCE_PUBLIC_LIABILITY_SUBTITLE: 'Protect your business against legal liabilities to a 3rd party in connection with your business operations',
	INSURANCE_PUBLIC_LIABILITY_DESC:
		'Protect your business against legal liabilities to a 3rd party as a result of an accident happening in connection with your business operations.',
	INSURANCE_PUBLIC_LIABILITY_HIGHLIGHT: '',

	INSURANCE_OTHERS_TITLE: 'Others',
	INSURANCE_OTHERS_SUBTITLE: 'More coverages made just for you',
	INSURANCE_OTHERS_DESC: `Have any other assets you wish to protect? From marine insurance, electronics equipment insurance to machinery insurance - everything for your business' needs are here.`,
	INSURANCE_OTHERS_HIGHLIGHT: `"We've got you covered"`,

	INSURANCE_FOOTER_TITLE: 'Powered by',
	INSURANCE_FOOTER_DESC: `Kindly be informed that MoneyMatch insurance is offered to you in collaboration with MM Tech(a wholly owned subsidiary of Money Match Sdn Bhd) with our partner agencies. Onward & Upwards Consulting Sdn Bhd licensed under Allianz for Life Insurance (BUSS1) and Peri Risk Sdn Bhd licensed under Allianz for General Insurance (KL39825).`,

	INSURANCE_TYPE_EMPLOYEE: 'Employee Benefits',
	INSURANCE_TYPE_FIRE: 'Fire Insurance',
	INSURANCE_TYPE_BURGLARY: 'Burglary Insurance',
	INSURANCE_TYPE_MARITIME: 'Maritime Insurance',
	INSURANCE_TYPE_PUBLIC_LIABILITY: 'Public Liability Insurance',
	INSURANCE_TYPE_OTHERS: 'Others',

	INSURANCE_PROVIDER_GE: 'Great Eastern',
	INSURANCE_EMPLOYEE_SUB_CON: 'Conventional',
	INSURANCE_EMPLOYEE_SUB_TAKAFUL: 'Takaful',

	INSURANCE_PROVIDER_AIA: 'AIA Bhd.',
	INSURANCE_PROVIDER_AIA_GEN: 'AIA General Berhad',
	INSURANCE_PROVIDER_AIA_MY: 'AIG Malaysia Insurance Berhad',
	INSURANCE_PROVIDER_ALIANZ_GEN: 'Allianz General Insurance Company (Malaysia) Berhad',
	INSURANCE_PROVIDER_ALIANZ_LIFE: 'Allianz Life Insurance Malaysia Berhad',
	INSURANCE_PROVIDER_AM_GEN: 'AmGeneral Insurance Berhad',
	INSURANCE_PROVIDER_AM_MET_LIFE: 'AmMetLife Insurance Berhad',
	INSURANCE_PROVIDER_AXA_AFF_GEN: 'AXA Affin General Insurance Berhad',
	INSURANCE_PROVIDER_AXA_AFF_LIFE: 'AXA Affin Life Insurance Berhad',
	INSURANCE_PROVIDER_BERJAYA: 'Berjaya Sompo Insurance Berhad',
	INSURANCE_PROVIDER_CHUB: 'Chubb Insurance Malaysia Berhad',
	INSURANCE_PROVIDER_ETIQA_GEN: 'Etiqa General Insurance Berhad',
	INSURANCE_PROVIDER_ETIQA_LIFE: 'Etiqa Life Insurance Berhad',
	INSURANCE_PROVIDER_GIBRA: 'Gibraltar BSN Life Berhad',
	INSURANCE_PROVIDER_GE_GEN: 'Great Eastern General Insurance (Malaysia) Berhad',
	INSURANCE_PROVIDER_GE_LIFE: 'Great Eastern Life Assurance (Malaysia) Berhad',
	INSURANCE_PROVIDER_HLA: 'Hong Leong Assurance Berhad',
	INSURANCE_PROVIDER_LIB: 'Liberty Insurance Berhad',
	INSURANCE_PROVIDER_LON: 'Lonpac Insurance Berhad',
	INSURANCE_PROVIDER_MANULIFE: 'Manulife Insurance Berhad',
	INSURANCE_PROVIDER_MCIS: 'MCIS Insurance Berhad',
	INSURANCE_PROVIDER_MPI: 'MPI Generali Insurans Berhad',
	INSURANCE_PROVIDER_MSIG: 'MSIG Insurance (Malaysia) Bhd.',
	INSURANCE_PROVIDER_PACNO: 'Pacific & Orient Insurance Co. Bhd',
	INSURANCE_PROVIDER_PROG: 'Progressive Insurance Bhd.',
	INSURANCE_PROVIDER_PRU: 'Prudential Assurance Malaysia Berhad',
	INSURANCE_PROVIDER_QBE: 'QBE Insurance (Malaysia) Berhad',
	INSURANCE_PROVIDER_RHB: 'RHB Insurance Berhad',
	INSURANCE_PROVIDER_SUN: 'Sun Life Malaysia Assurance Berhad',
	INSURANCE_PROVIDER_TPAC: 'The Pacific Insurance Berhad',
	INSURANCE_PROVIDER_TOKM: 'Tokio Marine Insurans (Malaysia) Berhad',
	INSURANCE_PROVIDER_TOKML: 'Tokio Marine Life Insurance Malaysia Bhd.',
	INSURANCE_PROVIDER_TUNE: 'Tune Insurance Malaysia Berhad',
	INSURANCE_PROVIDER_ZUCGEN: 'Zurich General Insurance Malaysia Berhad',
	INSURANCE_PROVIDER_ZUCLIFE: 'Zurich Life Insurance Malaysia Berhad',

	QUOTATION_MAIN_HEADER: 'Get a Quote',
	QUOTATION_MAIN_SUB_HEADER: 'Help us with the details below!',

	TAB_TYPE_OF_INSURANCE: 'Type of Insurance',
	TAB_APPLICATION_DETAILS: 'Application Details',
	TAB_ADD_ONS: 'Add Ons',
	TAB_CONTACT_DETAILS: 'Contact Details',

	WHAT_TO_PROTECT_LBL: 'What would you like to protect today?',

	INSURANCE_TYPE_DESC_EMP_BEN_TITLE: 'Your Employees',
	INSURANCE_TYPE_DESC_EMP_BEN_DESC: `Obtain coverage upon hospitalization and few selected outpatient treatments or obtain medical cards for medical attention at registered panel clinics for your employees. Additionally, you may get coverage against death, total or partial and permanent disability.`,
	INSURANCE_TYPE_DESC_FIRE_TITLE: 'Your Premises against Fire',
	INSURANCE_TYPE_DESC_FIRE_DESC: `Protect your property against losses or damages by fire. Coverage against other disasters such as floods or impact damages are available.`,
	INSURANCE_TYPE_DESC_BURGLAR_TITLE: 'Your Physical Assets against Theft',
	INSURANCE_TYPE_DESC_BURGLAR_DESC: `Protect your business against losses from incidents of theft. Obtain coverage in case your property and its contents are stolen through actual, forcible or violent entry, theft by hold-up, or armed robbery.`,
	INSURANCE_TYPE_DESC_MARINE_CARGO_TITLE: 'Your Marine Cargo',
	INSURANCE_TYPE_DESC_MARINE_CARGO_DESC:
		'Protect your goods shipped against losses or damages during the transit, from the time your goods leave the premises of the seller until the time they arrive at the buyer’s destination.',
	INSURANCE_TYPE_DESC_MARINE_HULL_TITLE: 'Your Marine Hull',
	INSURANCE_TYPE_DESC_MARINE_HULL_DESC: 'Protect your ships and vessels against losses or damages during operations.',
	INSURANCE_TYPE_DESC_PUBLIC_LIABILITY_TITLE: 'Your Day-to-Day Operations',
	INSURANCE_TYPE_DESC_PUBLIC_LIABILITY_DESC:
		'Protect your business against legal liabilities to a 3rd party as a result of an accident happening in connection with your business operations.',
	INSURANCE_TYPE_DESC_OTHERS_TITLE: 'Asset & Liability Protection',
	INSURANCE_TYPE_DESC_OTHERS_DESC:
		'Securing your business assets from unwanted occurrences. Something special or unique you would like to get insured? No worries, we’ve got you covered.',

	INSURANCE_STEP_APP_BUSINESS_DETAILS_LBL: 'Business Details',
	INSURANCE_STEP_APP_BUSINESS_NATURE_QUES: 'What is your business nature?',

	BUSINESS_LAUNDRY: 'Laundry Shop',
	BUSINESS_BEAUTY: 'Beauty or Hair',
	BUSINESS_HOSP: 'Hospitality',
	BUSINESS_OFFICE: 'Office',
	BUSINESS_EDU: 'Education',
	BUSINESS_MED: 'Medical Center',
	BUSINESS_FNB: 'F&B',
	BUSINESS_ROAD_STALL: 'Road-side Stall',
	BUSINESS_RETAIL_STORE: 'Retail Store',
	BUSINESS_OTHERS: 'Others',

	LAUNDRY_SUB_LAUNDRY: 'Laundry',
	LAUNDRY_SUB_DRY_CLEAN: 'Dry cleaner',
	BEAUTY_SUB_HAIR: 'Hair saloon',
	BEAUTY_SUB_BEAUTY: 'Beauty saloon',
	HOSP_SUB_HOTEL: 'Hotel',
	HOSP_SUB_BUD_HOTEL: 'Budget hotel',
	HOSP_SUB_HOSTEL: 'Hostel',
	OFFICE_SUB_CO_WORK: 'Co-working space',
	OFFICE_SUB_LOT: 'Office lot',
	EDU_SUB_KINDER: 'Kindergarten',
	EDU_SUB_TRAINING: 'Training center',
	EDU_SUB_TUITION: 'Tuition center',
	MED_SUB_NURSE: 'Nursing home',
	MED_SUB_CLINIC: 'Clinic',
	MED_SUB_DENTIST: 'Dentist',
	FNB_SUB_FINE: 'Fine dining',
	FNB_SUB_CASUAL: 'Casual dining',
	FNB_SUB_FAST: 'Fast food',
	FNB_SUB_COFFEE: 'Coffee shop',
	FNB_SUB_MAMAK: 'Mamak stall',
	ROAD_STALL_SUB_WITH_ROOF: 'With metal roof',
	ROAD_STALL_SUB_WITHOUT_ROOF: 'Without metal roof',
	RETAIL_STORE_SUB_BRICK_ZINC_ROOF: 'With brick and zinc roof',
	RETAIL_STORE_SUB_BRICK_NON_COMB_ROOF: 'With brick and non-combustible roof',

	TYPE_OF_BUILDING: 'Type of building',
	BUILDING_TYPE_RENTAL: 'Rental',
	BUILDING_TYPE_OWNED: 'Owned',
	BUILDING_TYPE_HOME_OFFICE: 'Home Office',

	LOCATION_OF_PREMISES_DETAILS_LBL: 'Location of Premises Details',
	ADDRESS_DETAILS_LBL: 'Address Details',
	ADDRESS_DETAILS_ADDRESS_LBL: 'Address',
	ADDRESS_DETAILS_CITY_LBL: 'City',
	ADDRESS_DETAILS_STATE_LBL: 'State',
	ADDRESS_DETAILS_POSTCODE_LBL: 'Postcode',

	OTHER_SUM_INSURED_DETAILS_LBL: 'Others Insurance Details',
	SUM_INSURED_DETAILS_LBL: 'Sum Insured Details',
	SUM_INSURED_DETAILS_BUILDING_LBL: 'Sum to insure - Building',
	SUM_INSURED_DETAILS_STOCK_IN_TRADE_LBL: 'Sum to insure - Value for stock in trade',
	SUM_INSURED_DETAILS_MACHINERY_LBL: 'Sum of value for machinery & equipments',
	SUM_INSURED_DETAILS_FURNITURE_LBL: 'Sum of value for furniture and fittings',
	SUM_INSURED_DETAILS_LIABILITY_EXPOSURE_LBL: 'Liability Exposure',
	SUM_INSURED_DETAILS_NUM_PREMISES_COVERED_LBL: 'Number of stores or premises to be covered',
	SUM_INSURED_DETAILS_NUM_PREMISES_COVERED_INFO_CAPTIONS:
		'Maximum 5 stores only for quote. If you have more premises to be covered, please inform our sales managers after you receive the initial quote.',

	HAS_EXISTING_FIRE_QUES_LBL: `Does your company currently have any insurance against fire?`,
	HAS_EXISTING_BURGLARY_QUES_LBL: 'Does your company currently have any insurance against burglary and theft?',
	HAS_EXISTING_YES: 'Yes',
	HAS_EXISTING_NO: 'No; or prefer not to say',

	INSURANCE_ADD_ON_RECOMMEND_HEADER: 'Recommended Add Ons',
	INSURANCE_ADD_ON_INSURER_PROVIDER_HEADER: 'Add On’s Insurer Provider Details',
	INSURANCE_ADD_ON_RECOMMEND_PROTECT_FROM_BURGLARY_DESC: 'Further protect your business by adding Burglary & Theft Insurance.',
	INSURANCE_ADD_ON_RECOMMEND_PROTECT_FROM_FIRE_DESC: `Further protect your business by adding Fire Insurance.`,

	INSURANCE_ADD_TO_QUOTE_BTN: 'Add to Quote',
	INSURANCE_SKIP_OFFER_LINK: 'Skip this offer',
	INSURANCE_PROVIDER_AC_TITLE: 'Who is your current insurance provider?',
	INSURANCE_PROVIDER_POLICY_EXPIRY_TITLE: `When does your policy expire?`,
	INSURANCE_PROVIDER_CURRENT_SUM_INSURED_TITLE: 'An estimate of your current sum insured',
	INSURANCE_PROVIDER_CURRENT_INSURED_PREMIUM_TITLE: 'An estimate of your current annual insurance premium',
	INSURANCE_PROVIDER_CURRENT_INSURED_DETAILS_HEADER: `Add On’s Sum Insured Details`,
	CHANGE_INSURANCE_TYPE: 'Change insurance type',

	DO_YOU_HAVE_EMPLOYEE_INSURANCE: 'Does your company currently have any life insurance?',
	DO_YOU_WANT_BNPL_ELIGIBILITY_CHECK: 'Check eligibility for our Buy Now Pay Later payment plan?',
	DO_YOU_HAVE_FIRE_INSURANCE: 'Does your company currently have any insurance against fire?',
	DO_YOU_HAVE_BURGLARY_INSURANCE: `Does your company currently have any insurance against burglary and theft?`,
	DO_YOU_HAVE_PUBLIC_LIABILITY_INSURANCE: `Does your company currently have any insurance against public liability?`,
	DO_YOU_HAVE_ASSET_LIABILITY_INSURANCE: `Does your company currently have this insurance?`,

	CURRENT_INSURANCE_PROVIDER_QUESTION: 'Who is your current employer insurance provider?',
	POLICY_EXPIRATION_QUESTION: 'When does your policy expire?',
	ESTIMATE_SUM_INSURED_QUESTION: 'An estimate of your current sum insured ',
	ESTIMATE_SUM_INSURED_PREMIUM_QUESTION: 'An estimate of your current insurance premium',
	DO_YOU_HAVE_OTHERS_INSURANCE: `Does your company currently have this insurance?`,

	EMPLOYEE_COVERAGE_TAKAFUL_BASIC_TITLE: 'Group Term Takaful (Basic)',
	EMPLOYEE_COVERAGE_TAKAFUL_BASIC_DESC: 'Covers basic benefits in the event of death or Total and Permanent Disability (TPD) for your employees',

	EMPLOYEE_COVERAGE_TAKAFUL_DEATH_AND_DISABLEMENT_TITLE: 'Group Accidental Death and Disablement Benefit',
	EMPLOYEE_COVERAGE_TAKAFUL_DEATH_AND_DISABLEMENT_DESC:
		'Provides benefits should employees pass away or sustain physical dismemberment due to an accident',

	EMPLOYEE_COVERAGE_TAKAFUL_CRITICAL_ILLNES_TITLE: 'Group Critical Illness Benefit',
	EMPLOYEE_COVERAGE_TAKAFUL_CRITICAL_ILLNES_DESC: 'Provides financial assistance when diagnosed with a critical illness',

	EMPLOYEE_GROUP_HOSPITALIZATION_TITLE: 'Group Hospitalization',
	EMPLOYEE_GROUP_HOSPITALIZATION_DESC: 'Provides comprehensive medical coverage to your employees, their legal spouse and children',
	EMPLOYEE_GROUP_TAKAFUL_HOSPITALIZATION_DESC: 'Provides medical benefits when confined to a hospital or receives covered treatment as an outpatient',

	EMPLOYEE_COVERAGE_OUTPATIENT_TITLE: 'Group Outpatient',
	EMPLOYEE_COVERAGE_OUTPATIENT_DESC: 'Coverage for out-patient general practitioner care',

	EMPLOYEE_COVERAGE_BASIC_TITLE: 'Group Term Life (Basic)',
	EMPLOYEE_COVERAGE_BASIC_DESC: 'Covers death and total permanent disability (TPD) for your employees',

	EMPLOYEE_INCREASE_COVERAGE_CHECKBOX_LBL: `Would you like to increase the employee’s coverage?`,
	EMPLOYEE_NUM_COVERED_LBL: 'How many employees to be covered?',
	EMPLOYEE_SUM_INSURED_TITLE: 'Sum Insured Details',
	EMPLOYEE_INSURED_MONTHLY_AMT_LBL: 'The monthly amount you are willing to set aside for each employee?',
	EMPLOYEE_INSURED_POLICY_START_LBL: 'When would you like the policy to start?',
	ADDITIONAL_INFO_LBL: 'Additional Information',
	ADDITIONAL_INFO_PLACEHOLDER: `Type here... Include more details of your request and any specifics we should take into consideration.`,

	OLD_INSURER_DETAILS_TITLE: 'Current Insurer Provider Details',
	OLD_INSURER_DETAILS_QUEST_LBL: 'Who is your current insurance provider?',
	OLD_INSURER_DETAILS_POLICY_EXP_LBL: 'When does your policy expire?',
	OLD_INSURER_DETAILS_EST_SUM_INSURED_LBL: 'An estimate of your current sum insured',
	OLD_INSURER_DETAILS_EST_INSURED_PREMIUM_LBL: 'An estimate of your current annual insurance premium',

	PUBLIC_LIABILITY_KEY_COVERAGE_INFO_2: 'All cost and expenses of legal action',
	PUBLIC_LIABILITY_KEY_COVERAGE_INFO_2_SELLING_POINT_1: 'Recovered by any claimant against you',
	PUBLIC_LIABILITY_KEY_COVERAGE_INFO_2_SELLING_POINT_2: 'Incurred with our written consent',

	PUBLIC_LIABILITY_KEY_COVERAGE_INFO_1: 'All sums which you shall become legally liable to pay compensation in respect of',
	PUBLIC_LIABILITY_KEY_COVERAGE_INFO_1_SELLING_POINT_1: 'Bodily injury (including illness) to any person(s)',
	PUBLIC_LIABILITY_KEY_COVERAGE_INFO_1_SELLING_POINT_2: 'Loss of or damage to property',

	BURGLARY_KEY_COVERAGE_INFO_1: 'Covers losses or damages to your property and its contents in your business premise due to',
	BURGLARY_KEY_COVERAGE_INFO_1_SELLING_POINT_1: 'Theft by actual, forcible and violent entry',
	BURGLARY_KEY_COVERAGE_INFO_1_SELLING_POINT_2: 'Theft or attempted theft by a person hiding in the said premises',
	BURGLARY_KEY_COVERAGE_INFO_1_SELLING_POINT_3: 'Hold-up or armed robbery',

	FIRE_KEY_COVERAGE_INFO_1: 'Covers losses or damages to your property due to',
	FIRE_KEY_COVERAGE_INFO_1_SELLING_POINT_1: 'Fire',
	FIRE_KEY_COVERAGE_INFO_1_SELLING_POINT_2: 'Lightning',
	FIRE_KEY_COVERAGE_INFO_1_SELLING_POINT_3: 'Explosion caused by domestic gas',

	EMPLOYEE_KEY_COVERAGE_INFO_1: 'Provides comprehensive medical coverage to your employees, their legal spouse and children',
	EMPLOYEE_KEY_COVERAGE_INFO_1_SELLING_POINT_1: 'Hospitalisation and surgical benefits',
	EMPLOYEE_KEY_COVERAGE_INFO_1_SELLING_POINT_2: 'Coverage for out-patient general practitioner care',
	EMPLOYEE_KEY_COVERAGE_INFO_1_SELLING_POINT_3: 'Death and total and permanent disability for your employees',

	EMPLOYEE_KEY_COVERAGE_INFO_2: 'Coverage and benefits subjected to coverage type',
	EMPLOYEE_KEY_COVERAGE_INFO_2_SELLING_POINT_1: 'Conventional or Takaful',
	EMPLOYEE_KEY_COVERAGE_INFO_2_SELLING_POINT_2: 'Addtional coverages available',
	KEY_COVERAGES_LBL: 'Key Coverages',

	INSURANCE_QUOTE_CONTACT_DETAILS_HEADER: `Please review your registered contact details and we'll get back to you with a quote as soon as we can!`,
	INSURANCE_QUOTE_CONTACT_DETAILS_DEFAULT_COMP_NAME: 'Your company name',
	INSURANCE_QUOTE_CONTACT_DETAILS_DEFAULT_AUTH_NAME: `Authorized Person’s name`,
	INSURANCE_QUOTE_CONTACT_DETAILS_DEFAULT_PHONE_NO: 'Your contact number',
	INSURANCE_QUOTE_CONTACT_DETAILS_DEFAULT_EMAIL: 'Your e-mail address',
	INSURANCE_QUOTE_CONTACT_DETAILS_ADD_ALTERNATIVE: '+ add alternative contact',
	INSURANCE_QUOTE_CONTACT_DETAILS_ALTERNATIVE_HEADER: 'Alternative Contact Details',
	INSURANCE_QUOTE_CONTACT_DETAILS_REMOVE_ALTERNATIVE: '- remove contact',

	INSURANCE_QUOTE_CONTACT_DETAILS_ALTERNATIVE_NAME: 'Name',
	INSURANCE_QUOTE_CONTACT_DETAILS_ALTERNATIVE_CONTACT: 'Contact number',
	INSURANCE_QUOTE_CONTACT_DETAILS_ALTERNATIVE_EMAIL: 'E-mail address',

	INSURANCE_QUOTE_CONTACT_DETAILS_REMARKS: `By submitting this request,  I understand that this is an illustration of the insurance product. The insurance quotes provided through this website are for pricing comparisons and quotation estimate purposes only. The insurance rate quotes provided are based on general assumptions that may or may not be applicable to you and are subject to change at any time. `,

	EMPLOYEE_APPLICATIONS_DETAILS_TITLE: 'Which type of insurance are you interested in?',

	SUBMIT_QUOTATION_SUCCESS_TITLE: `Registration Complete!`,
	SUBMIT_QUOTATION_SUCCESS_CONTENT: `Thank you, we will revert a quote based on your information within 2 working days.`,

	QUOTATION_FURTHER_ENQUIRY_TITLE: 'In the meantime...',
	QUOTATION_FURTHER_ENQUIRY_CONTENT: 'Can we interest you in other packages to protect your business or assets?',
	CONFIRM_MODAL_SHOWMORE_LBL: 'Show me more!',
	CONFIRM_MODAL_MAYBE_LATER_LBL: 'No, maybe later.',

	STEP_BUSINESS_OTHERS_SPECIFY: 'If Others, please specify',
	STEP_BUSINESS_WEB_LINK_LBL: 'Your website URL link',
	STEP_BUSINESS_WEB_LINK_QUES_LBL: 'Do you have a website or online presence?',

	SUM_INSURED_BUILDING_INFO_TIPS: `What is the estimated value of the property of your company? (e.g. total value your building including renovation). This is not applicable for rental properties`,
	SUM_INSURED_STOCK_IN_TRADE_INFO_TIPS: 'What is the estimated value of the physical products used for trade?(e.g. total value of your inventory)',
	SUM_INSURED_MACHINERY_INFO_TIPS: `What is the estimated value of the total assets of your company? (e.g. machinery used in producing your end product)`,
	SUM_INSURED_FURNITURE_INFO_TIPS: `What is the estimated value of all furniture and fittings? (e.g. laptops, tables, chairs, furniture in your office space)`,

	EST_CURRENT_SUM_INSURED_INFO_TIPS: `Sum Insured means the maximum amount of coverage, as specified in the Policy Schedule, that you are entitled to in respect of each benefit and as applicable under the Policy.`,
	EST_CURRENT_ANNUAL_PREMIUM_INFO_TIPS: `An annual insurance premium is the annual payment made to the insurance company that keeps your policy active.`,

	FIRE_ADD_ON_BURGLARY_DESC: `Protect your business against losses from incidents of theft. Obtain coverage in case your property and its contents are stolen through actual, forcible or violent entry, theft by hold-up, or armed robbery.`,
	BURGLARY_ADD_ON_FIRE_DESC: `Protect your property against losses or damages by fire. Coverage against other disasters such as floods or impact damages are available.`,

	PREMISE_INSURED_LIABILITY_AMT_LBL: `Amount of indemnity liability to insure for this premise`,
	PREMISE_INSURED_LIABILITY_AMT_INFO_TIPS: `You have to decide based on your liability exposure in relation to your business operations as this sets the
	maximum amount payable for any one accident.`,
	PREMISES_ADDRESS_DETAILS_LBL: 'Premises Address Details',
	PREMISE_COLLAPSE_LBL: 'Premise {{number}}',
	OTHERS_PLAN_LBL: `I'm looking for a plan that helps me`,
	OTHERS_INSURED_DETAILS_LBL: 'Others Insurance Details',
	OTHERS_SUM_TO_INSURED_LBL: 'Sum to Insure',
	INSURANCE_VIEWDRAFT_CTA_TITLE: 'Continue where you left off...',
	INSURANCE_VIEWDRAFT_CTA_DESC: 'Finishing submitting your quote.',
	INSURANCE_VIEWDRAFT_CTA_DESC2: 'You’ll need to add a few more details before you can submit!',
	INSURANCE_VIEWDRAFT_CTA_BTN_LBL: 'View my Drafts',

	INSURANCE_CONTACT_HEADER: 'Please provide your contact details <0/> and we will get back to you with a quote as soon as we can!',
	INSURANCE_FORM_DISCLAIMER:
		'By submitting this request, I understand that this is an illustration of the insurance product. The insurance quotes provided through this website are for pricing comparisons and quotation estimate purposes only. The insurance rate quotes provided are based on general assumptions that may or may not be applicable to you and are subject to change at any time.',
	INSURANCE_SUBMISSION_QUOTE_BACKDROP: 'Thank you for submitting a quote! Our team will reach out to you within 3 working days!',

	GET_A_QUOTE_LBL: 'Get a Quote',

	CONSULTATION_TITLE: 'Rethinking Business Protection',
	CONSULTATION_SUBTITLE: 'with MoneyMatch',
	GET_FREE_CONSULTATION_LBL: 'Get a free consultation',
	INS_PROD_TITLE_PROPERTY: 'Property',
	INS_PROD_TITLE_MARINE: 'Marine',
	INS_PROD_TITLE_ENGINEERING: 'Engineering',
	INS_PROD_TITLE_LIABILITIES: 'Liabilities',
	INS_PROD_TITLE_SPECIALIZED_PROTECT: 'Specialized Protection',
	INS_PROD_TITLE_OTHERS: 'Others',

	INS_PROD_DESC_PROPERTY: 'Cover your business premises against any mishaps.',
	INS_PROD_DESC_MARINE: 'Protect your cargo and shipment.',
	INS_PROD_DESC_LIABILITIES: 'Protect your business against unexpected setbacks.',
	INS_PROD_DESC_SPECIALIZED_PROTECT: 'Protect your business from financial costs due to negligence.',

	INS_PROD_ITEM_1_PROPERTY: 'Fire Insurance',
	INS_PROD_ITEM_2_PROPERTY: 'Burglary Insurance',
	INS_PROD_ITEM_3_PROPERTY: 'Consequential Loss',
	INS_PROD_ITEM_4_PROPERTY: 'Equipment All Risk',

	INS_PROD_ITEM_1_MARINE: 'Cargo Insurance',
	INS_PROD_ITEM_2_MARINE: 'Logistics Insurance',

	INS_PROD_ITEM_1_ENGINEERING: 'Contractors’ All Risks (CAR)',
	INS_PROD_ITEM_2_ENGINEERING: 'Erection All Risks (EAR)',

	INS_PROD_ITEM_1_LIABILITIES: 'Public Liability Insurance',
	INS_PROD_ITEM_2_LIABILITIES: 'Workmen’s Compensation',
	INS_PROD_ITEM_3_LIABILITIES: 'Product Liability',

	INS_PROD_ITEM_1_SPECIALIZED_PROTECT: 'Manufacturer’s Error and Omissions Insurance',
	INS_PROD_ITEM_2_SPECIALIZED_PROTECT: 'Professional Indemnity Insurance',

	INS_PROD_ITEM_1_OTHERS: 'Fidelity Guarantee',
	INS_PROD_ITEM_2_OTHERS: 'Keyman Personal Accident',

	INS_PROD_TITLE_EMP_GTL: 'Group Term Life',
	INS_PROD_TITLE_EMP_GHS: 'Group Hospitalisation & Surgical',
	INS_PROD_TITLE_EMP_GPA: 'Group Personal Accident',

	INS_PROD_ITEM_1_EMP_GTL: 'Total and Permanent Disability',
	INS_PROD_ITEM_2_EMP_GTL: 'Terminal Illnesses',
	INS_PROD_ITEM_3_EMP_GTL: 'Death',

	INS_PROD_SUB_TITLE_EMP_GTL: 'Additional coverages:',
	INS_PROD_SUB_ITEM_1_EMP_GTL: 'Up to MYR 500,000 against critical illnesses',
	INS_PROD_SUB_ITEM_2_EMP_GTL: 'Up to MYR 500,000 against disablement and death caused by accidents',

	INS_PROD_ITEM_1_EMP_GHS: 'Medical coverage with a high overall annual limit',
	INS_PROD_ITEM_2_EMP_GHS: 'Fully-covered hospital admissions',

	INS_PROD_SUB_TITLE_EMP_GHS: 'Add Ons:',
	INS_PROD_SUB_ITEM_1_EMP_GHS: 'Cashless medical card',
	INS_PROD_SUB_ITEM_2_EMP_GHS: 'Outpatient general and Specialist practitioner care',

	INS_PROD_ITEM_1_EMP_GPA: 'Accidental Medical Expenses',
	INS_PROD_ITEM_2_EMP_GPA: 'Accidental Hospital Income',
	INS_PROD_ITEM_3_EMP_GPA: 'Total and Permanent Disability',
	INS_PROD_ITEM_4_EMP_GPA: 'Death and funeral allowance',
	INS_PROD_ITEM_5_EMP_GPA: 'Travelling allowance',

	INS_CLAIM_PROCEDURES_TITLE_1: '1. Report your Claim',
	INS_CLAIM_PROCEDURES_DESC_1:
		'Submit your claim to us within 24 hours, but no later than 30 days following the occurrence of the loss or damage. Be sure to provide as many details as you can.',
	INS_CLAIM_PROCEDURES_TITLE_2: '2. Review by Insurance Expert',
	INS_CLAIM_PROCEDURES_DESC_2: 'Our insurance expert will contact you to get facts and explain how your claim will be handled.',
	INS_CLAIM_PROCEDURES_TITLE_3: '3. Submitted for Insurer Evaluation',
	INS_CLAIM_PROCEDURES_DESC_3: 'Our insurance expert wil hand over the claim documents to the insurer for evaluation.',
	INS_CLAIM_PROCEDURES_TITLE_4: '4. Claim Resolution',
	INS_CLAIM_PROCEDURES_DESC_4: 'Insurer will determine whether your claim is approved or rejected.',

	INS_PERSONAL_MOTOR_TITLE: 'Motor',
	INS_PERSONAL_MOTOR_ITEM_1: 'Third-party and comprehensive coverage',
	INS_PERSONAL_MOTOR_ITEM_2: 'Special perils and windscreen enhancement',
	INS_PERSONAL_TRAVEL_TITLE: 'Travel',
	INS_PERSONAL_TRAVEL_1: 'Domestic Travel',
	INS_PERSONAL_TRAVEL_2: 'International Travel',
	INS_PERSONAL_ACCIDENT_TITLE: 'Personal Accident',
	INS_PERSONAL_ACCIDENT_1: 'Death or disability',
	INS_PERSONAL_ACCIDENT_2: 'Weekly Indemnity',
	INS_PERSONAL_ACCIDENT_3: 'Travelling allowance',
	INS_PERSONAL_ACCIDENT_4: 'Medical expenses',
	GET_A_FREE_QUOTE_LBL: 'Get a Free Quote',

	FOR_YOUR_PERSONAL_NEEDS: 'FOR YOUR  PERSONAL NEEDS',
	PROTECT_WHAT_IMPORTANT: `Protect what’s important to you`,
	PERSONAL_COVERAGE_DESC: 'Coverage that safeguards you and your loved ones against unexpected events.',
	CLAIMS_AND_SUPPORT: 'CLAIMS AND SUPPORT',
	CLAIMS_AND_SUPPORT_TITLE: 'We are here to help when you’re in need',
	CLAIMS_AND_SUPPORT_SUBTITLE: 'We will guide you step-by-step through the claims process.',
	CLAIMS: 'CLAIMS',
	EASY_CLAIM_WITH_MM: 'Easy Claims with MoneyMatch',
	SUBMIT_YOUR_CLAIMS_VIA: 'Submit your claims via email and we will guide you step-by-step through the claims process.',
	INSURANCE_EMAIL: 'insurance@moneymatch.co',
	FOR_YOUR_BUSINESS: 'FOR YOUR BUSINESS',
	SHIELD_YOUR_BUSINESS_FROM_RISK: 'Shield your business from risk',
	VARIOUS_PLANS_TAILORED_FOR_YOUR_NEEDS: 'Various plans tailored for your needs',
	FOR_MY_ASSETS_AND_LIABILITIES: 'For My Assets & Liabilities',
	FOR_MY_ASSETS_AND_LIABILITIES_DESC: 'Secure your business assets from unwanted occurrences and unexpected setbacks.',
	FOR_MY_EMPLOYEES: 'For My Employees',
	FOR_MY_EMPLOYEES_DESC: 'Protect one of your company’s most valuable assets — People.'
};
export default insurance;
