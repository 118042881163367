import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { withStyles } from 'tss-react/mui';
import { DescStacks } from './ProductDescCards';

const StyledAccordion = withStyles(Accordion, (theme: any) => ({
	root: {
		backgroundColor: '#F7F7F7',
		'&::before': {
			backgroundColor: '#F7F7F7'
		}
	}
}));

const ProductAccordion = (props: any) => {
	const { title, titleFontSize, cardColor, ...descStackProps } = props || {};
	const { t } = useTranslation();

	return (
		<StyledAccordion elevation={0} disableGutters>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="h4" sx={{ fontSize: titleFontSize ?? '20px' }}>
					{t(title)}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Stack gap={2}>
					<DescStacks {...descStackProps} />
				</Stack>
			</AccordionDetails>
		</StyledAccordion>
	);
};

export default ProductAccordion;
