import { Button, Grid } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NavigationButtons = ({ handlePrev, handleNext }) => {
	const { t } = useTranslation();
	return (
		<Grid item container spacing={2} direction="row">
			<Grid item xs={6}>
				<Button
					variant="outlined"
					fullWidth
					color="inherit"
					size="large"
					startIcon={<ChevronLeftIcon />}
					children={t('BACK_LBL')}
					onClick={handlePrev}
				/>
			</Grid>
			<Grid item xs={6}>
				<Button variant="contained" fullWidth color="primary" size="large" children={t('CONTINUE_LBL')} onClick={handleNext} />
			</Grid>
		</Grid>
	);
};

export default NavigationButtons;
