import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';
import { useInsuranceFormContext } from '../../../context/InsuranceFormContext';
import { useStateSafe } from '../../../effects/useStateSafe';
import { applicantDetailsObjectConstructor } from '../../../helpers/insuranceHelper';
import QuotationService from '../../../network/api/insurance/insuranceAPI';
import BasicTextInput from '../../generalFormComponents/BasicTextInput';
import SubmissionBackDrop from './SubmissionBackDrop';

const useStyles = makeStyles()((theme) => ({
	centerText: {
		textAlign: 'center'
	},
	circularProgress: {
		color: '#FFFFFF'
	},
	button: {
		lineHeight: 1.1,
		height: '100%',
		minHeight: 46
	}
}));

const ContactDetails = (props) => {
	const { t } = useTranslation();
	const { classes } = useStyles();
	const {
		insuranceType,
		applicantDetails,
		contactDetails,
		setContactDetails,
		resetQuotationForm,
		isAppDetailsComplete,
		isTypeOfInsComplete,
		setActiveMenu,
		isBuyNowPayLater,
		hasExistingPolicy
	}: any = useInsuranceFormContext();
	const { companyName: ctxCompany, authorizedPerson: ctxName, contactNo: ctxContactNo, email: ctxEmail }: any = contactDetails || {};
	const { prevTab } = props;
	const [isBackDropOpen, setIsBackDropOpen] = useStateSafe<boolean>(false);
	const { executeRecaptcha } = useGoogleReCaptcha();
	const quotationService = new QuotationService();

	const onFormSubmit = async (replaceFields) => {
		try {
			const newToken = executeRecaptcha && (await executeRecaptcha('quotationform'));
			contactFormik.setSubmitting(true);
			const data = {
				insuranceType,
				isBuyNowPayLater,
				hasExistingPolicy,
				applicantDetails: applicantDetailsObjectConstructor(applicantDetails, insuranceType),
				contactDetails: { ...replaceFields }
			};
			const res = await quotationService.postQuotationForm(data, newToken);
			resetQuotationForm && resetQuotationForm();
			contactFormik.setSubmitting(false);
			contactFormik.resetForm();
			setIsBackDropOpen(true);
		} catch (err: any) {
			contactFormik.setSubmitting(false);
			const errResponse = err?.response;
			const { data: resData }: any = errResponse || {};
			const { message: errorMsg, status: errorStatus } = resData || {};
			// eslint-disable-next-line no-console
			console.error('Insurance Quotation onFormSubmit Error Status: ', errorStatus);
			// eslint-disable-next-line no-console
			console.error('Insurance Quotation onFormSubmit Error Message: ', errorMsg);
		}
	};

	const contactValidationSchema = yup.object({
		companyName: yup.string().max(240).required(t('REQUIRED')),
		authorizedPerson: yup.string().max(240).required(t('REQUIRED')),
		contactNo: yup
			.string()
			.max(20)
			.matches(/^(\+)?[0-9 ]{1,}$/, t('PHONE_NUMBER_ERR'))
			.required(t('REQUIRED')),
		email: yup.string().email(t('EMAIL_ERR')).required(t('REQUIRED'))
	});

	const contactFormik = useFormik({
		initialValues: {
			companyName: ctxCompany ?? '',
			authorizedPerson: ctxName ?? '',
			contactNo: ctxContactNo ?? '',
			email: ctxEmail ?? ''
		},
		validationSchema: contactValidationSchema,
		onSubmit: async (values) => {
			const { companyName, authorizedPerson, contactNo, email }: any = values || {};
			await setContactDetails((prev) => {
				return { ...prev, companyName, authorizedPerson, contactNo, email };
			});
			const applicantContactDetails = {
				...contactDetails,
				companyName,
				authorizedPerson,
				contactNo,
				email
			};
			await onFormSubmit(applicantContactDetails);
		}
	});

	return (
		<Grid item>
			<SubmissionBackDrop isBackDropOpen={isBackDropOpen} setIsBackDropOpen={setIsBackDropOpen} />
			<Grid container spacing={2} direction="column" justifyContent="space-evenly">
				<Grid item xs={12} container justifyContent="center">
					<Typography variant="h6" className={classes.centerText}>
						<Trans i18nKey={'INSURANCE_CONTACT_HEADER'} components={[<br />]} />
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<BasicTextInput
						label="CTA_COMPANY_NAME"
						placeholder={t('CTA_COMPANY_NAME_PH')}
						type="text"
						value={contactFormik.values.companyName}
						onChange={(e) => {
							contactFormik.setFieldValue('companyName', e.target.value);
						}}
						onBlur={contactFormik.handleBlur}
						error={contactFormik.touched.companyName && Boolean(contactFormik.errors.companyName)}
						helperText={contactFormik.errors.companyName}
					/>
				</Grid>
				<Grid item xs={12}>
					<BasicTextInput
						label="CTA_NAME"
						placeholder={t('CTA_NAME_PH')}
						type="text"
						value={contactFormik.values.authorizedPerson}
						onChange={(e) => {
							contactFormik.setFieldValue('authorizedPerson', e.target.value);
						}}
						onBlur={contactFormik.handleBlur}
						error={contactFormik.touched.authorizedPerson && Boolean(contactFormik.errors.authorizedPerson)}
						helperText={contactFormik.errors.authorizedPerson}
					/>
				</Grid>
				<Grid item xs={12}>
					<BasicTextInput
						label="CTA_CONTACT_NUM"
						placeholder={t('CTA_CONTACT_NUM_PH')}
						type="text"
						value={contactFormik.values.contactNo}
						onChange={(e) => {
							contactFormik.setFieldValue('contactNo', e.target.value);
						}}
						onBlur={contactFormik.handleBlur}
						error={contactFormik.touched.contactNo && Boolean(contactFormik.errors.contactNo)}
						helperText={contactFormik.errors.contactNo}
					/>
				</Grid>
				<Grid item xs={12}>
					<BasicTextInput
						label="CTA_CONTACT_EMAIL"
						placeholder={t('CTA_CONTACT_EMAIL_PH')}
						type="text"
						value={contactFormik.values.email}
						onChange={(e) => {
							contactFormik.setFieldValue('email', e.target.value);
						}}
						onBlur={contactFormik.handleBlur}
						error={contactFormik.touched.email && Boolean(contactFormik.errors.email)}
						helperText={contactFormik.errors.email}
					/>
				</Grid>
				<Grid item container spacing={2} direction="row">
					<Grid item xs={6}>
						<Button
							variant="outlined"
							fullWidth
							color="inherit"
							size="large"
							startIcon={<ChevronLeftIcon />}
							children={t('BACK_LBL')}
							onClick={() => setActiveMenu(prevTab)}
							className={classes.button}
						/>
					</Grid>
					<Grid item xs={6}>
						<Button
							variant="contained"
							fullWidth
							startIcon={
								contactFormik.isSubmitting && <CircularProgress className={classes.circularProgress} disableShrink size={20} color="inherit" />
							}
							color="primary"
							size="large"
							children={t('SUBMIT_QUOTE')}
							onClick={() => !contactFormik.isSubmitting && contactFormik.handleSubmit()}
							disabled={!isAppDetailsComplete || !isTypeOfInsComplete}
							className={classes.button}
						/>
					</Grid>
				</Grid>
				<Grid item>
					<Typography variant="body2">{t('INSURANCE_FORM_DISCLAIMER')}</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ContactDetails;
