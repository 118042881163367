import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Grid, IconButton, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';
import { ReactComponent as SubmissionCompleteSVG } from '../assets/svg/insurance/submissionComplete.svg';
import { COMPANY_SIZE_DROPDOWN } from '../constant/landing';
import { useScreenViews } from '../effects/useScreenViews';
import { useStateSafe } from '../effects/useStateSafe';
import QuotationService from '../network/api/insurance/insuranceAPI';
import theme from '../theme/globalTheme';
import MmtButton from './MmtButton';
import BasicTextInput from './generalFormComponents/BasicTextInput';
import MultiSelectDropdown from './generalFormComponents/MultiSelectDropdown';

const useStyles = makeStyles()(() => ({
	completedCTA: {
		width: '100%',
		padding: '32px',
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			padding: '0px'
		}
	},
	formContainerStack: {
		width: '90%',
		maxWidth: '600px',
		margin: 'auto',
		padding: 'auto',
		gap: '16px',
		[theme.breakpoints.down('md')]: {
			gap: '8px',
			paddingBottom: '32px'
		}
	},
	dialogPaper: {
		padding: '32px auto',
		width: '80vw',
		height: '95vh',
		maxHeight: '900px',
		[theme.breakpoints.down('md')]: {
			width: '100vw',
			height: '100vh',
			maxHeight: '100vh'
		}
	},
	dialog: {
		zIndex: 1501
	},
	closeButton: {
		color: theme.palette.text.primary,
		height: '32px',
		width: '32px'
	},
	closeButtonContainer: {
		padding: '16px 16px 8px 0px'
	}
}));

const CloseButton = ({ closeDialog }) => {
	const { classes } = useStyles();
	return (
		<Grid item container justifyContent="flex-end" className={classes.closeButtonContainer}>
			<IconButton onClick={closeDialog} className={classes.closeButton}>
				<CloseIcon />
			</IconButton>
		</Grid>
	);
};

const CompletedCTA = (props) => {
	const { handleClose, setIsFormSubmitted } = props;
	const { t } = useTranslation();
	const { classes } = useStyles();

	const closeDialogAndReset = () => {
		setIsFormSubmitted(false);
		handleClose();
	};

	return (
		<Grid container rowGap={3} className={classes.completedCTA} direction="row" justifyContent="center" alignItems="center">
			<Grid item xs={12}>
				<SubmissionCompleteSVG />
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h3" gutterBottom>
					{t('CTA_SUBMISSION_OK_TITLE')}
				</Typography>
				<Typography variant="h5" color="textSecondary">
					{t('CTA_SUBMISSION_OK_DESC')}
				</Typography>
			</Grid>
			<Grid item xs={12} mt={2}>
				<MmtButton fullWidth onClick={closeDialogAndReset}>
					{t('OKAY_GOT_IT')}
				</MmtButton>
			</Grid>
		</Grid>
	);
};

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ContactDialogForm = ({ isOpen, handleClose }) => {
	const { t } = useTranslation();
	const { classes } = useStyles();
	const [protectionTypes, setProtectionTypes] = useStateSafe([]);
	const [isFormSubmitted, setIsFormSubmitted] = useStateSafe(false);
	const quotationService = new QuotationService();
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { belowTabletView } = useScreenViews();

	const inputStyle = {
		backgroundColor: '#FFFFFF'
	};
	const validationSchema = yup.object({
		name: yup.string().max(240).required(t('REQUIRED')),
		company: yup.string().max(240).required(t('REQUIRED')),
		contactNo: yup
			.string()
			.max(20)
			.matches(/^(\+)?[0-9 ]{1,}$/, t('PHONE_NUMBER_ERR'))
			.required(t('REQUIRED')),
		email: yup.string().email(t('EMAIL_ERR')).required(t('REQUIRED')),
		companySize: yup.string().max(50).required(t('REQUIRED')).nullable(true),
		protectionTypes: yup.array().min(1, t('REQUIRED')).required(t('REQUIRED')),
		details: yup.string().max(240, t('MAX_CHARS'))
	});

	const formik = useFormik({
		initialValues: {
			name: '',
			company: '',
			contactNo: '',
			email: '',
			companySize: '',
			protectionTypes: [],
			details: ''
		},
		validationSchema,
		validateOnChange: true,
		validateOnBlur: false,
		onSubmit: async (values) => {
			try {
				const newToken = await executeRecaptcha('contactform');
				const result = await quotationService.postContactForm(values, newToken);
				if (result?.status === 200) {
					formik.resetForm();
					setIsFormSubmitted(true);
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error('Error submiting Contact Form', e);
			}
		}
	});

	useEffect(() => {
		const fetchProtectionTypes = async () => {
			const protectionObj = await quotationService.getProtectionTypes();
			setProtectionTypes(
				protectionObj.map((obj) => {
					return obj?.name;
				})
			);
		};
		try {
			fetchProtectionTypes();
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error fetching Protection Types', error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Dialog
			open={isOpen}
			fullScreen={belowTabletView}
			disableEnforceFocus
			onClose={handleClose}
			maxWidth="md"
			className={classes.dialog}
			TransitionComponent={Transition}
			PaperProps={{ className: classes.dialogPaper }}>
			<CloseButton closeDialog={handleClose} />
			<Stack direction="column" className={classes.formContainerStack}>
				{isFormSubmitted ? (
					<CompletedCTA handleClose={handleClose} setIsFormSubmitted={setIsFormSubmitted} />
				) : (
					<>
						<Typography variant="h3" textAlign="center">
							{t('GET_A_FREE_CONSULTATION')}
						</Typography>
						<Typography variant="h5" textAlign="center">
							{t('GET_A_FREE_CONSULTATION_DESC')}
						</Typography>
						<BasicTextInput
							label="CTA_NAME"
							type="text"
							style={inputStyle}
							value={formik.values.name}
							placeholder={t('CTA_NAME_PH')}
							onChange={(e) => {
								formik.setFieldValue('name', e.target.value);
							}}
							onBlur={formik.handleBlur}
							error={formik.touched.name && Boolean(formik.errors.name)}
							helperText={formik.errors.name}
						/>
						<BasicTextInput
							label="CTA_COMPANY_NAME"
							type="text"
							value={formik.values.company}
							style={inputStyle}
							placeholder={t('CTA_COMPANY_NAME_PH')}
							onChange={(e) => {
								formik.setFieldValue('company', e.target.value);
							}}
							onBlur={formik.handleBlur}
							error={formik.touched.company && Boolean(formik.errors.company)}
							helperText={formik.errors.company}
						/>
						<Stack direction={belowTabletView ? 'column' : 'row'} width="100%" rowGap={belowTabletView ? 2 : 0} columnGap={belowTabletView ? 0 : 2}>
							<BasicTextInput
								label="CTA_CONTACT_EMAIL"
								type="email"
								value={formik.values.email}
								style={inputStyle}
								placeholder={t('CTA_CONTACT_EMAIL_PH')}
								onChange={(e) => {
									formik.setFieldValue('email', e.target.value);
								}}
								error={formik.touched.email && Boolean(formik.errors.email)}
								helperText={formik.errors.email}
							/>
							<BasicTextInput
								label="CTA_CONTACT_NUM"
								type="text"
								style={inputStyle}
								value={formik.values.contactNo}
								placeholder={t('CTA_CONTACT_NUM_PH')}
								onChange={(e) => {
									formik.setFieldValue('contactNo', e.target.value);
								}}
								error={formik.touched.contactNo && Boolean(formik.errors.contactNo)}
								helperText={formik.errors.contactNo}
							/>
						</Stack>
						<MultiSelectDropdown
							label="CTA_COMPANY_SIZE"
							disablePortal
							options={COMPANY_SIZE_DROPDOWN}
							style={inputStyle}
							value={formik.values.companySize}
							placeholder={t('CTA_COMPANY_SIZE_PH')}
							onChange={(event, values) => {
								formik.setFieldValue('companySize', values);
							}}
							error={formik.touched.companySize && Boolean(formik.errors.companySize)}
							helperText={formik.errors.companySize}
							isOptionEqualToValue={(option, value) => option === value}
						/>
						<MultiSelectDropdown
							multiple
							disablePortal
							disableCloseOnSelect
							label="CTA_PROTECTION"
							style={inputStyle}
							options={protectionTypes}
							value={formik.values.protectionTypes}
							onChange={(event, values) => {
								formik.setFieldValue('protectionTypes', values);
							}}
							error={formik.touched.protectionTypes && Boolean(formik.errors.protectionTypes)}
							helperText={formik.errors.protectionTypes}
							isOptionEqualToValue={(option, value) => option === value}
						/>
						<div>
							<BasicTextInput
								label="ADDITIONAL_INFO_LBL"
								type="text"
								style={inputStyle}
								minRows="4"
								multiline
								placeholder={t('CTA_TELL_US_MORE_PH')}
								value={formik.values.details}
								onChange={(e) => {
									formik.setFieldValue('details', e.target.value);
								}}
								error={formik.touched.details && Boolean(formik.errors.details)}
								helperText={formik.errors.details}
							/>
							<Typography variant="caption" color="textSecondary">
								{t('CTA_TELL_US_MORE_TXTCOUNTER', { len: formik?.values?.details?.length || 0 })}
							</Typography>
						</div>
						<MmtButton fullWidth disabled={formik.isSubmitting} onClick={() => formik.handleSubmit()}>
							{formik.isSubmitting ? t('SUBMITTING') : t('CONTACT_A_CONSULTANT')}
						</MmtButton>
					</>
				)}
			</Stack>
		</Dialog>
	);
};

export default ContactDialogForm;
