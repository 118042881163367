export const PATHS_BY_ENV: any = {
	dev: {
		transferLink: `https://mmt.beta.moneymatch.technology`,
		transferBusinessLink: `https://mmt.beta.moneymatch.technology/business`,
		insuranceLink: 'https://insurance.moneymatch.technology/',
		accountLink: 'https://accounts.beta.moneymatch.technology/login',
		registerLink: 'https://accounts.beta.moneymatch.technology/register',
		promotionsLink: 'https://moneymatch.technology/promotions.html',
		aboutLink: 'https://moneymatch.technology/about-us.html',
		careersLink: 'https://moneymatch.technology/careers.html',
		supportCenterLink: 'https://support.moneymatch.co/hc/en-us',
		blogLink: 'https://blog.moneymatch.co/',
		privacyLink: 'https://transfer.moneymatch.co/privacy-policy/en',
		personalInsLink: 'https://getprotectionbymoneymatch.gr8.com/',
		referralProgramLink: 'https://support.moneymatch.co/hc/en-us/sections/900000189766-Referral-Program',
		remitFXLink: 'https://remitfx.moneymatch.co',
		apiRefLink: 'https://remitfx.moneymatch.co/api/documentation#/',
		contactUsLink: 'https://moneymatch.technology/contact.html',
		newsLink: 'https://moneymatch.technology/newsroom.html',
		legalLink: 'https://moneymatch.technology/legal.html',
		ifscLink: 'https://ifsc.moneymatch.co/',
		beftnLink: 'https://beftn.moneymatch.co/',
		personalRetailLink: 'https://moneymatch.technology/transfers.html',
		businessRetailLink: 'https://moneymatch.technology/business.html',
		linkedInLink: 'https://www.linkedin.com/company/moneymatchapp/',
		fbLink: 'https://www.facebook.com/moneymatchapp/',
		twitterLink: 'https://twitter.com/moneymatchapp',
		instaLink: 'https://www.instagram.com/moneymatchapp/',
		landingPageLink: 'https://moneymatch.technology/',
		enterpriseLink: 'https://moneymatch.technology/enterprise.html'
	},
	staging: {
		transferLink: `https://mmt.staging.moneymatch.technology`,
		transferBusinessLink: `https://mmt.staging.moneymatch.technology/business`,
		accountLink: 'https://accounts.staging.moneymatch.technology/login',
		registerLink: 'https://accounts.staging.moneymatch.technology/register',
		promotionsLink: 'https://moneymatch.technology/promotions.html',
		aboutLink: 'https://moneymatch.technology/about-us.html',
		careersLink: 'https://moneymatch.technology/careers.html',
		supportCenterLink: 'https://support.moneymatch.co/hc/en-us',
		blogLink: 'https://blog.moneymatch.co/',
		insuranceLink: 'https://insurance.moneymatch.technology/',
		privacyLink: 'https://transfer.moneymatch.co/privacy-policy/en',
		personalInsLink: 'https://getprotectionbymoneymatch.gr8.com/',
		referralProgramLink: 'https://support.moneymatch.co/hc/en-us/sections/900000189766-Referral-Program',
		remitFXLink: 'https://remitfx.moneymatch.co',
		apiRefLink: 'https://remitfx.moneymatch.co/api/documentation#/',
		contactUsLink: 'https://moneymatch.technology/contact.html',
		newsLink: 'https://moneymatch.technology/newsroom.html',
		legalLink: 'https://moneymatch.technology/legal.html',
		ifscLink: 'https://ifsc.moneymatch.co/',
		beftnLink: 'https://beftn.moneymatch.co/',
		personalRetailLink: 'https://moneymatch.technology/transfers.html',
		businessRetailLink: 'https://moneymatch.technology/business.html',
		linkedInLink: 'https://www.linkedin.com/company/moneymatchapp/',
		fbLink: 'https://www.facebook.com/moneymatchapp/',
		twitterLink: 'https://twitter.com/moneymatchapp',
		instaLink: 'https://www.instagram.com/moneymatchapp/',
		landingPageLink: 'https://moneymatch.technology/',
		enterpriseLink: 'https://moneymatch.technology/enterprise.html'
	},
	production: {
		transferLink: `https://transfer.moneymatch.co`,
		transferBusinessLink: 'https://transfer.moneymatch.co/business',
		insuranceLink: 'https://insurance.moneymatch.co/',
		supportCenterLink: 'https://support.moneymatch.co/hc/en-us',
		accountLink: 'https://accounts.moneymatch.co/login',
		registerLink: 'https://accounts.moneymatch.co/register',
		promotionsLink: 'https://moneymatch.co/promotions.html',
		aboutLink: 'https://moneymatch.co/about-us.html',
		blogLink: 'https://blog.moneymatch.co/',
		careersLink: 'https://moneymatch.co/careers.html',
		privacyLink: 'https://transfer.moneymatch.co/privacy-policy/en',
		personalInsLink: 'https://getprotectionbymoneymatch.gr8.com/',
		referralProgramLink: 'https://support.moneymatch.co/hc/en-us/sections/900000189766-Referral-Program',
		remitFXLink: 'https://remitfx.moneymatch.co',
		apiRefLink: 'https://remitfx.moneymatch.co/api/documentation#/',
		contactUsLink: 'https://moneymatch.co/contact.html',
		newsLink: 'https://moneymatch.co//newsroom.html',
		legalLink: 'https://moneymatch.co//legal.html',
		ifscLink: 'https://ifsc.moneymatch.co/',
		beftnLink: 'https://beftn.moneymatch.co/',
		personalRetailLink: 'https://moneymatch.co/transfers.html',
		businessRetailLink: 'https://moneymatch.co/business.html',
		linkedInLink: 'https://www.linkedin.com/company/moneymatchapp/',
		fbLink: 'https://www.facebook.com/moneymatchapp/',
		twitterLink: 'https://twitter.com/moneymatchapp',
		instaLink: 'https://www.instagram.com/moneymatchapp/',
		landingPageLink: 'https://moneymatch.co/',
		enterpriseLink: 'https://moneymatch.co/enterprise.html'
	}
};
