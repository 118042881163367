/* eslint-disable max-len */
const faq = {
	FAQ_TITLE: 'Frequently Asked Questions',
	FAQ_DESC: 'Find out the most common questions asked on how to purchase insurance with MoneyMatch.',
	GENERAL_INSURANCE: 'General Insurance',
	EMPLOYEE_BENEFITS: 'Employee Benefits',
	PERSONAL_INSURANCE: 'Personal Insurance',
	GENERAL_INSURANCE_TITLE1: 'Can I cancel my policy?',
	GENERAL_INSURANCE_DESC1:
		'You may cancel your policy by giving a written notice to the insurance company. Upon cancellation, you are entitled to a refund of the premium, based on a short period calculation, on the unexpired period of the insurance. Subject to the minimum premium to be retained by the insurance company.',
	GENERAL_INSURANCE_TITLE2: 'Can fire insurance be bundled with other types of insurances?',
	GENERAL_INSURANCE_DESC2:
		'Yes, fire insurance can often be bundled with other types of property insurance, such as public liability insurance or burglary insurance. Bundling policies can sometimes lead to cost savings.',
	GENERAL_INSURANCE_TITLE3: 'What is Fidelity Insurance?',
	GENERAL_INSURANCE_DESC3:
		'Fidelity guarantee insurance safeguards businesses from financial losses caused by dishonest acts of their employees, including theft, fraud, and embezzlement. Policies have coverage limits and may require evidence to file claims.',
	GENERAL_INSURANCE_TITLE4: 'What are the benefits for Money Insurance?',
	GENERAL_INSURANCE_DESC4:
		'Provides coverage for losses resulting from theft, accidental damage, and unforeseen contingencies, excluding only those that are specifically listed as exclusions.',
	GENERAL_INSURANCE_TITLE5: `What does Contractors' All Risks / Erection All Risks Insurance cover?`,
	GENERAL_INSURANCE_DESC5:
		'Both policies offer comprehensive insurance protection on an "all-risks" basis, covering a wide range of perils such as fire, lightning, water damage, floods, storms, theft, and more. Additional coverage options include strike, riot, inland transit, and various other extensions.',
	GENERAL_INSURANCE_TITLE6: 'What is Machinery Breakdown Insurance Cover?',
	GENERAL_INSURANCE_DESC6: `The Machinery Breakdown Insurance policy provides coverage for unexpected and sudden physical damage or loss resulting from accidental electrical or mechanical failures of the insured machinery. This coverage applies whether the machinery is in operation, at rest, during maintenance, inspection, or when it's being moved to another location within the premises.`,
	GENERAL_INSURANCE_TITLE7: 'What is Professional Indemnity Insurance, and who needs it ?',
	GENERAL_INSURANCE_DESC7:
		'Professional Indemnity Insurance  provides coverage for professionals against claims related to errors, omissions, or professional negligence that result in financial losses for clients. It is often sought by businesses and individuals in professions like legal, medical, and consultancy.',
	EMPLOYEE_BENEFITS_TITLE1: 'Is the ‘Cash before Cover’ option available?',
	EMPLOYEE_BENEFITS_DESC1: `The 'Cash Before Cover' option is applicable to policies with an annual premium totaling less than RM10,000, excluding stamp duty and service tax. 'Cash Before Cover' implies that premiums must be paid prior to the issuance of the insurance policy.`,
	EMPLOYEE_BENEFITS_TITLE2: `Am I able to modify or enhance my employees' benefits?`,
	EMPLOYEE_BENEFITS_DESC2: `Yes, adjustments or improvements to benefits can be made during the policy anniversary or renewal, pending our approval. These changes will apply to employees within the same category. If there is a change in an employee's category due to promotion or demotion, please notify us at least 30 days before the benefit eligibility date. In such cases, a pro-rated premium may be applied or refunded as appropriate.`,
	EMPLOYEE_BENEFITS_TITLE3: `What occurs in the event of an employee's resignation or the addition of new employees?`,
	EMPLOYEE_BENEFITS_DESC3:
		'Please notify us by email at insurance@monyematch.co or contact your servicing agent within 30 days prior to the benefit eligibility date if there are any changes in the status of an insured member. A pro-rated premium will be refunded in the event of employee resignation, and a pro-rated premium will be applied for new employees joining the organization after the policy commencement date.',
	EMPLOYEE_BENEFITS_TITLE4: 'What is the minimum premium required per policy?',
	EMPLOYEE_BENEFITS_DESC4: 'To initiate a new policy, a minimum premium of RM2,000, excluding stamp duty and service tax, is a prerequisite.',
	EMPLOYEE_BENEFITS_TITLE5: 'What are the terms and conditions of cashless facility?',
	EMPLOYEE_BENEFITS_DESC5: `The cashless facility is offered as an option for GHS applications. It's important to note that if the Outpatient Clinical rider is chosen, cashless facility becomes obligatory. Under the cashless facility, the Insured Member is relieved of the need to make upfront payments for hospital admissions and non-emergency visits to Outpatient Clinical General Practitioners at panel hospitals and clinics. Instead, they are provided with a cashless card for seamless transactions at these designated healthcare facilities.`,
	PERSONAL_INSURANCE_TITLE1: 'Can I transfer my Motor Insurance to a new vehicle?',
	PERSONAL_INSURANCE_DESC1:
		'Yes, you can typically transfer your motor insurance to a new vehicle, but you’ll need to inform us and we will update the policy details accordingly.',
	PERSONAL_INSURANCE_TITLE2: 'What is No Claims Discount (NCD)?',
	PERSONAL_INSURANCE_DESC2:
		'No Claims Discount is a reward system offered by insurance companies to policy holders who do not make any claims on their motor insurance policies during a specific period. It’s a way to incentivize safe driving and provide discounts on insurance premiums.',
	PERSONAL_INSURANCE_TITLE3: 'Is there a limit on the maximum travel duration for International Travel Insurance?',
	PERSONAL_INSURANCE_DESC3:
		'Indeed, there are specific limits on travel duration: <0>For Single-Trip coverage, the journey should not extend beyond 200 days</0><0>Under the Annual Trip option, each journey throughout the year should not surpass 100 days.</0>',
	PERSONAL_INSURANCE_TITLE4: 'Can I extend my policy/ certificate if I decided to prolong my stay during my trip?',
	PERSONAL_INSURANCE_DESC4: `Once you have commenced your overseas trip, it's important to note that the policy/certificate cannot be modified or extended.`,
	PERSONAL_INSURANCE_TITLE5: 'Does this Travel Insurance include coverage for hiking, trekking, rock climbing and scuba diving?',
	PERSONAL_INSURANCE_DESC5:
		'Please be aware that mountaineering or any activities conducted at elevations exceeding three thousand (3,000) meters, rock climbing, and any underwater activities that surpass depths of fifty (50) meters are not covered by this policy/certificate.',
	VIEW_SUPPORT_CENTER: 'View Support Center'
};

export default faq;
