import { Container, FormHelperText, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ props: any }>()((theme, { props }) => ({
	label: {
		marginBottom: 5
	},
	container: {
		padding: '0px !important '
	},
	toggleButtonGroup: {
		display: 'grid',
		gridTemplateColumns: props?.gridTemplateColumns ?? '1fr 1fr 1fr',
		gridGap: 10,
		marginBottom: props?.marginBottom ?? 0
	}
}));

const StyledToggleButton = withStyles(ToggleButton, (theme: any) => ({
	root: {
		height: 'auto',
		color: 'black',
		border: '1px solid #D1D1D1 !important',
		borderRadius: '360px !important'
	},
	selected: {
		'&&': {
			color: 'black',
			border: `2px solid ${theme.palette.primary.main} !important`,
			borderRadius: 5,
			backgroundColor: `${theme.palette.primary.main}`,
			boxShadow: '0px 3px 8px #F2F2F2',
			'&:hover': {
				backgroundColor: `${theme.palette.primary.main}`
			}
		}
	}
}));

const ToggleButtons = (props) => {
	const { label, dataSrc, error, onValueChange, value: inputValue, customStyle, ...rest } = props || {};
	const { classes } = useStyles({ props: customStyle });
	const { t } = useTranslation();

	return (
		<>
			<Typography variant="h6" className={classes.label}>
				{t(label)}
			</Typography>
			<Container className={classes.container}>
				<ToggleButtonGroup
					{...rest}
					className={classes.toggleButtonGroup}
					value={inputValue}
					onChange={(e, value) => {
						if (value !== null) onValueChange(value);
					}}>
					{dataSrc &&
						dataSrc.map((x, index) => {
							return (
								<StyledToggleButton key={index} value={x.value}>
									<Typography variant="subtitle1">{t(x.label)}</Typography>
								</StyledToggleButton>
							);
						})}
				</ToggleButtonGroup>
			</Container>
			{error && <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>}
		</>
	);
};

export default ToggleButtons;
