const MuiPickersToolbar: object = {
	styleOverrides: {
		root: {
			backgroundColor: '#F7E21E'
		}
	}
};

const MuiButton: object = {
	styleOverrides: {
		root: {
			height: 40,
			borderRadius: 360,
			boxShadow: 'none'
		},
		containedPrimary: {
			'&:hover': {
				background: '#FFF38B',
				color: '#212121'
			}
		},
		text: {
			'&:hover': {
				borderRadius: '0'
			}
		}
	}
};

const MuiCard: object = {
	styleOverrides: {
		root: {
			borderRadius: 8
		}
	}
};

export const components: object = {
	MuiPickersToolbar,
	MuiButton,
	MuiCard
};
