import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, List, ListItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Trans, useTranslation } from 'react-i18next';
import CustomGrid from './CustomGrid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EMPLOYEE_BENEFITS_FAQ, GENERAL_INSURANCE_FAQ, PERSONAL_INSURANCE } from '../constant/faq';
import EastIcon from '@mui/icons-material/East';
import { PATHS_BY_ENV } from '../constant/paths';
import { env } from '../helpers/EnvHelper';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;
	return (
		<div role="tabpanel" hidden={value !== index} id={`solution-tabpanel-${index}`} aria-labelledby={`solution-tab-${index}`} {...other}>
			{value === index && (
				<Box sx={{ p: 1 }}>
					<div>{children}</div>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `solution-tab-${index}`,
		'aria-controls': `solution-tabpanel-${index}`
	};
}

const useStyles = makeStyles()((theme) => ({
	indicator: {
		borderBottom: `5px solid ${theme.palette.grey[900]}`
	},
	wrapFAQAccordion: {
		width: '100%',
		'& .MuiAccordionSummary-content': {
			margin: '0px'
		},
		'& .MuiButtonBase-root': {
			padding: '16px 24px'
		},
		'& .MuiAccordion-root': {
			marginBottom: '16px',
			position: 'inherit'
		},
		'& .MuiAccordionDetails-root': {
			background: theme.surfaces?.level3,
			padding: '16px 24px'
		},
		'& .MuiAccordionSummary-content.Mui-expanded': {
			margin: '0px'
		}
	},
	listStyle: {
		listStyleType: 'disc',
		pl: 2,
		'& .MuiListItem-root': {
			display: 'list-item',
			padding: '0',
			paddingLeft: '0.8rem'
		}
	},
	textAlign: {
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left'
		}
	},
	wrapFAQContainer: {
		marginTop: '32px',
		marginBottom: '32px'
	},
	scrollBtn: {
		'&.Mui-disabled': {
			width: '5px'
		}
	},
	btn: {
		color: theme.palette.primary.contrastText,
		borderRadius: '0px',
		borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
		marginTop: '32px'
	}
}));

const RenderAccordion = (props: any) => {
	const { data } = props || {};
	const { classes } = useStyles();
	const { t } = useTranslation();

	return (
		<Box className={classes.wrapFAQAccordion}>
			{data?.map((faq, index) => (
				<Accordion key={index}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
						<Typography variant="h6">{t(faq?.title)}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<List className={classes.listStyle}>
							<Trans i18nKey={faq?.description} components={[<ListItem></ListItem>]} />
						</List>
					</AccordionDetails>
				</Accordion>
			))}
		</Box>
	);
};

const Faq = () => {
	const { classes } = useStyles();
	const [value, setValue] = React.useState(0);
	const { t } = useTranslation();

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<CustomGrid className={classes.wrapFAQContainer}>
			<Typography variant="h3" className={classes.textAlign}>
				{t('FAQ_TITLE')}
			</Typography>
			<Typography variant="body1" paddingBottom="32px" className={classes.textAlign}>
				{t('FAQ_DESC')}
			</Typography>

			<Grid container justifyContent="center">
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					allowScrollButtonsMobile
					value={value}
					onChange={handleChange}
					textColor="inherit"
					sx={{ marginBottom: '24px' }}
					classes={{
						scrollButtons: classes.scrollBtn,
						indicator: classes.indicator
					}}>
					<Tab label={t('GENERAL_INSURANCE')} {...a11yProps(0)} />
					<Tab label={t('EMPLOYEE_BENEFITS')} {...a11yProps(1)} />
					<Tab label={t('PERSONAL_INSURANCE')} {...a11yProps(2)} />
				</Tabs>
				<TabPanel value={value} index={0}>
					<RenderAccordion data={GENERAL_INSURANCE_FAQ} />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<RenderAccordion data={EMPLOYEE_BENEFITS_FAQ} />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<RenderAccordion data={PERSONAL_INSURANCE} />
				</TabPanel>
				<Button className={classes.btn} endIcon={<EastIcon />} href={PATHS_BY_ENV[env].supportCenterLink} target="_blank">
					{t('VIEW_SUPPORT_CENTER')}
				</Button>
			</Grid>
		</CustomGrid>
	);
};

export default Faq;
