import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useCommonStyles } from '../styles/common';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useScreenViews } from '../effects/useScreenViews';

const useStyles = makeStyles()((theme) => ({
	consultationBtn: {
		boxShadow: 'none',
		padding: '1.75rem 1.5rem',
		textTransform: 'none',
		width: 'fit-content'
	},
	innerContainer: {
		[theme.breakpoints.down('md')]: {
			margin: '32px 64px'
		}
	},
	mainContainer: {
		background: theme.palette.background.default,
		padding: '64px 0px',
		[theme.breakpoints.down('sm')]: {
			padding: '64px 0px 0px'
		}
	},
	txtCenter: {
		textAlign: 'center'
	},
	btnScrollToTop: {
		marginTop: '64px',
		color: theme.accent.main,
		width: '100%',
		height: '72px',
		borderRadius: 0
	}
}));

const FreeConsultation = ({ openContactDialogForm }) => {
	const { classes } = useStyles();
	const { t } = useTranslation();
	const { cx } = useCommonStyles({});
	const { belowMobileView } = useScreenViews();

	return (
		<Grid container direction="column" alignItems="center" className={classes.mainContainer} gap={{ xs: 0, md: 4 }}>
			<Grid item className={cx(classes.innerContainer, classes.txtCenter)}>
				<Typography variant="h2">{t('CONSULTATION_TITLE')}</Typography>
				<Typography variant="h3">{t('CONSULTATION_SUBTITLE')}</Typography>
			</Grid>
			<Grid item>
				<Button variant="contained" color="primary" onClick={openContactDialogForm} className={classes.consultationBtn}>
					{t('GET_FREE_CONSULTATION_LBL')}
				</Button>
			</Grid>

			{belowMobileView && (
				<Button href="#bannerSlideShow" variant="text" className={classes.btnScrollToTop} endIcon={<KeyboardArrowUpIcon fontSize="large" />}>
					<Typography variant="h4">{t('BACK_TO_TOP')}</Typography>
				</Button>
			)}
		</Grid>
	);
};

export default FreeConsultation;
