/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import GeneralLayout from '..//layouts/GeneralLayout';
import ClaimsAndSupport from '../component/ClaimsAndSupport';
import ContactDialogForm from '../component/ContactDialogForm';
import CustomerFeedbacks from '../component/CustomerFeedback';
import CustomerSupport from '../component/CustomerSupport';
import Faq from '../component/Faq';
import FreeConsultation from '../component/FreeConsultation';
import MainCTA from '../component/MainCTA';
import OurPartners from '../component/OurPartners';
import WhyChooseUs from '../component/WhyChooseUs';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';
import { useStateSafe } from '../effects/useStateSafe';
import InsuranceFeatures from '../component/insuranceFeatures';
import PersonalNeedsSection from '../component/PersonalNeedsSection';

const Content = (props) => {
	const { openContactDialogForm } = props || {};

	return (
		<>
			<MainCTA openContactDialogForm={openContactDialogForm} />
			<OurPartners />
			<WhyChooseUs />
			<PersonalNeedsSection openContactDialogForm={openContactDialogForm} />
			<InsuranceFeatures />
			<ClaimsAndSupport />
			<CustomerSupport />
			<Faq />
			<CustomerFeedbacks />
			<FreeConsultation openContactDialogForm={openContactDialogForm} />
		</>
	);
};

const LandingPage = () => {
	const [isContactFormOpen, setIsContactFormOpen] = useStateSafe(false);

	const openContactDialogForm = useCallback(() => {
		setIsContactFormOpen(true);
	}, []);

	const closeContactDialogForm = useCallback(() => {
		setIsContactFormOpen(false);
	}, []);

	return (
		<GeneralLayout
			header={<Header openContactDialogForm={openContactDialogForm} />}
			content={
				<>
					<Content openContactDialogForm={openContactDialogForm} />
					<ContactDialogForm isOpen={isContactFormOpen} handleClose={closeContactDialogForm} />
				</>
			}
			footer={<Footer />}
		/>
	);
};

export default LandingPage;
