import { FormHelperText, InputAdornment, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import LabelWithInfoTips from './LabelWithInfoTips';

const useStyles = makeStyles<{ labelStyle: any }>()((theme, { labelStyle }) => ({
	label: {
		marginTop: 20,
		...labelStyle
	}
}));

export default function PriceTextInput(props) {
	const { label, labelStyle, currency, value, onChange, error, infoTipsText, helperText, ...rest } = props || {};
	const { classes } = useStyles({ labelStyle });
	const { t } = useTranslation();

	return (
		<>
			{infoTipsText ? (
				<LabelWithInfoTips label={label} labelStyle={labelStyle} infoTipsText={infoTipsText} />
			) : (
				<Typography variant="h6" className={classes.label}>
					{t(label)}
				</Typography>
			)}
			<TextField
				{...rest}
				InputProps={{
					startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
				}}
				type="number"
				variant="outlined"
				value={value}
				onChange={onChange}
			/>
			{error && <FormHelperText style={{ color: 'red' }}>{helperText}</FormHelperText>}
		</>
	);
}
