import { FormHelperText, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()((theme) => ({
	container: {
		minHeight: 56
	}
}));

export const BasicAutoComplete = (props) => {
	const { classes } = useStyles();
	const { t } = useTranslation();

	const { label, error, onChange, value: inputValue, placeholder, helperText, style, ...rest } = props || {};

	return (
		<>
			<Typography variant="h6">{t(label)}</Typography>
			<Autocomplete
				style={{ root: classes.container, ...style }}
				{...rest}
				getOptionLabel={(option: any) => {
					return option.label ? t(option.label.toString()) : '';
				}}
				renderOption={(optionProp: any, option: any, optionState: any) => {
					return (
						<li {...optionProp}>
							<Typography variant="subtitle1">{t(option.label)}</Typography>
						</li>
					);
				}}
				value={inputValue}
				onChange={(item, value) => {
					onChange && onChange(value);
				}}
				isOptionEqualToValue={(option: any, curValue: any) => option?.value === curValue?.value}
				renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t(placeholder)} color="primary" />}
			/>
			{error && <FormHelperText style={{ color: 'red' }}>{helperText}</FormHelperText>}
		</>
	);
};
