import { format, parse, isDate } from 'date-fns';

export const formatDateWithDash = (date) => {
	try {
		return format(new Date(date), 'yyyy-MM-dd');
	} catch (error) {
		return '';
	}
};

export const formatDateWithSlash = (date) => {
	try {
		return format(new Date(date), 'dd/MM/yyyy');
	} catch (error) {
		return '';
	}
};

export const formatCustomDate = (date, customFormat) => {
	try {
		return format(new Date(date), customFormat);
	} catch (error) {
		return '';
	}
};

export const parseDateStringOnFormik = (value, originalValue) => {
	const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'yyyy/MM/dd', new Date());

	return parsedDate;
};
