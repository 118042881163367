import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Collapse, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { useStateSafe } from '../../effects/useStateSafe';

const useStyles = makeStyles<{ labelStyle: any }>()((theme, { labelStyle }) => ({
	infoTipButton: {
		cursor: 'pointer',
		fontSize: 16,
		marginLeft: 8
	},
	labelWrapper: {
		display: 'grid',
		gridTemplateColumns: 'auto 1fr',
		alignItems: 'center',
		maxWidth: 'fit-content',
		marginTop: 20,
		marginBottom: 5,
		...labelStyle
	},
	infoTipsContent: {
		backgroundColor: '#F2F2F2',
		padding: 8,
		borderRadius: 8,
		marginBottom: 10
	}
}));

const LabelWithInfoTips = (props) => {
	const { label, labelStyle, infoTipsText } = props || {};
	const { t } = useTranslation();
	const { classes } = useStyles({ labelStyle });
	const [isOpen, setIsOpen] = useStateSafe(false);

	const onToggle = () => {
		return setIsOpen(!isOpen);
	};

	return (
		<>
			<div className={classes.labelWrapper}>
				<Typography variant="h6">{t(label)}</Typography>
				{isOpen ? (
					<CancelOutlinedIcon onClick={onToggle} className={classes.infoTipButton} />
				) : (
					<InfoOutlinedIcon onClick={onToggle} className={classes.infoTipButton} />
				)}
			</div>
			{isOpen && (
				<Collapse in={isOpen} timeout="auto" unmountOnExit className={classes.infoTipsContent}>
					<Typography variant="body2">{t(infoTipsText)}</Typography>
				</Collapse>
			)}
		</>
	);
};

export default LabelWithInfoTips;
