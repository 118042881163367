/* eslint-disable max-len */
export const GENERAL_INSURANCE_FAQ = [
	{
		title: 'GENERAL_INSURANCE_TITLE1',
		description: 'GENERAL_INSURANCE_DESC1'
	},
	{
		title: 'GENERAL_INSURANCE_TITLE2',
		description: 'GENERAL_INSURANCE_DESC2'
	},
	{
		title: 'GENERAL_INSURANCE_TITLE3',
		description: 'GENERAL_INSURANCE_DESC3'
	},
	{
		title: 'GENERAL_INSURANCE_TITLE4',
		description: 'GENERAL_INSURANCE_DESC4'
	},
	{
		title: 'GENERAL_INSURANCE_TITLE5',
		description: 'GENERAL_INSURANCE_DESC5'
	},
	{
		title: 'GENERAL_INSURANCE_TITLE6',
		description: 'GENERAL_INSURANCE_DESC6'
	},
	{
		title: 'GENERAL_INSURANCE_TITLE7',
		description: 'GENERAL_INSURANCE_DESC7'
	}
];

export const EMPLOYEE_BENEFITS_FAQ = [
	{
		title: 'EMPLOYEE_BENEFITS_TITLE1',
		description: 'EMPLOYEE_BENEFITS_DESC1'
	},
	{
		title: 'EMPLOYEE_BENEFITS_TITLE2',
		description: 'EMPLOYEE_BENEFITS_DESC2'
	},
	{
		title: 'EMPLOYEE_BENEFITS_TITLE3',
		description: 'EMPLOYEE_BENEFITS_DESC3'
	},
	{
		title: 'EMPLOYEE_BENEFITS_TITLE4',
		description: 'EMPLOYEE_BENEFITS_DESC4'
	},
	{
		title: 'EMPLOYEE_BENEFITS_TITLE5',
		description: 'EMPLOYEE_BENEFITS_DESC5'
	}
];

export const PERSONAL_INSURANCE = [
	{
		title: 'PERSONAL_INSURANCE_TITLE1',
		description: 'PERSONAL_INSURANCE_DESC1'
	},
	{
		title: 'PERSONAL_INSURANCE_TITLE2',
		description: 'PERSONAL_INSURANCE_DESC2'
	},
	{
		title: 'PERSONAL_INSURANCE_TITLE3',
		description: 'PERSONAL_INSURANCE_DESC3'
	},
	{
		title: 'PERSONAL_INSURANCE_TITLE4',
		description: 'PERSONAL_INSURANCE_DESC4'
	},
	{
		title: 'PERSONAL_INSURANCE_TITLE5',
		description: 'PERSONAL_INSURANCE_DESC5'
	}
];
