import { Badge, Grid, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ props?: any }>()((theme, { props }) => ({
	titleContainerActive: {
		width: '18%',
		padding: '10px 30px',
		textAlign: 'center',
		borderBottom: '6px solid #F7E21E',
		...props?.titleContainerActive,
		[theme.breakpoints.down('md')]: {
			padding: '0px',
			width: '30%',
			textAlign: 'center'
		}
	},
	titleContainer: {
		width: '18%',
		padding: '10px 30px',
		cursor: 'pointer',
		textAlign: 'center',
		borderBottom: '2px solid #d1d1d1',
		color: '#cccccc',
		...props?.titleContainer,
		[theme.breakpoints.down('md')]: {
			padding: '0px',
			width: '30%',
			textAlign: 'center'
		}
	},
	mainContainer: {
		width: '100%',
		marginBottom: 20,
		marginTop: 10,
		display: 'flex',
		justifyContent: 'center',
		...props?.mainContainer,
		[theme.breakpoints.down('md')]: {
			marginBottom: 15
		}
	}
}));

const StyledBadge = withStyles(Badge, () => ({
	root: {
		width: 30
	},
	anchorOriginTopRightRectangle: {
		top: -4,
		right: -8,
		transform: 'none'
	}
}));

const Tabs = (props) => {
	const { onActiveMenuChanged, activeMenu, allTabs, variant, customStyle, withBadge, badgeContents } = props || {};
	const { classes } = useStyles({ props: customStyle });
	const { t } = useTranslation();

	return (
		<Grid item sm={12} className={classes.mainContainer}>
			{allTabs &&
				allTabs.map((tab, index) => (
					<Grid
						key={index}
						item
						className={activeMenu === tab ? classes.titleContainerActive : classes.titleContainer}
						onClick={() => onActiveMenuChanged(tab)}>
						{withBadge ? (
							<>
								<Typography variant={variant || 'h4'}>{t(tab)}</Typography>
								{badgeContents && badgeContents[index] > 0 && <StyledBadge badgeContent={badgeContents[index]} color="primary" />}
							</>
						) : (
							<Typography variant={variant || 'h4'}>{t(tab)}</Typography>
						)}
					</Grid>
				))}
		</Grid>
	);
};

export default Tabs;
