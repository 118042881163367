const typography = {
	fontFamily: 'Poppins',
	h1: {
		fontWeight: 400,
		fontSize: '3rem',
		lineHeight: 1.25,
		letterSpacing: '0px'
	},
	h2: {
		fontWeight: 700,
		fontSize: '2.5rem',
		lineHeight: 1.25,
		letterSpacing: '0px'
	},
	h3: {
		fontWeight: 700,
		fontSize: '1.5rem',
		lineHeight: 1.5,
		letterSpacing: '0px'
	},
	h4: {
		fontWeight: 700,
		fontSize: '1rem',
		lineHeight: 1.5,
		letterSpacing: '0px'
	},
	h5: {
		fontWeight: 400,
		fontSize: '1rem',
		lineHeight: 1.5,
		letterSpacing: '0px'
	},
	h6: {
		fontWeight: 600,
		fontSize: '0.875rem',
		lineHeight: 1.5,
		letterSpacing: '-2%'
	},
	subtitle1: {
		fontWeight: 400,
		fontSize: '0.875rem',
		lineHeight: 1.5,
		letterSpacing: '0.15%'
	},
	subtitle2: {
		fontWeight: 600,
		fontSize: '0.625rem',
		lineHeight: 1.5,
		letterSpacing: '0%'
	},
	body1: {
		fontWeight: 400,
		fontSize: '0.875rem',
		lineHeight: 1.5,
		letterSpacing: '0%'
	},
	body2: {
		fontWeight: 400,
		fontSize: '0.75rem',
		lineHeight: 1.5,
		letterSpacing: '-2%'
	},
	caption: {
		fontWeight: 400,
		fontSize: '0.625rem',
		lineHeight: 1.5,
		letterSpacing: '0.4%'
	},
	button: {
		fontWeight: 600,
		fontSize: '1rem',
		lineHeight: 1.5,
		letterSpacing: '0%',
		textTransform: 'none'
	},
	overline: {
		fontWeight: 400,
		fontSize: '0.625rem',
		lineHeight: 1.5,
		letterSpacing: '1.5%'
	}
};

export default typography;
