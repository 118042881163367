import { Theme } from '@mui/material';
import { useEffect } from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './App.css';
import ZendeskChatbot from './component/ZendeskChatbot';
import InsuranceRoutes from './routes';
import theme from './theme/globalTheme';
import { clarity } from 'clarity-js';

const App = () => {
	useEffect(() => {
		window.process = {
			...window.process
		};
		initClarityTracker();

		return () => {
			clarity.stop();
		};
	}, []);

	const initClarityTracker = () => {
		clarity.consent();
		clarity.start({
			projectId: process.env.REACT_APP_CLARITY_KEY || '',
			upload: 'https://m.clarity.ms/collect',
			track: true,
			content: true
		});
	};

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHAKEY}>
				{InsuranceRoutes()}
				{/* <ZendeskChatbot /> */}
			</GoogleReCaptchaProvider>
		</ThemeProvider>
	);
};

export default App;
