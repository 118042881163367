import { Box, Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { PATHS_BY_ENV } from '../constant/paths';
import { env } from '../helpers/EnvHelper';

const useStyles = makeStyles()((theme) => ({
	container: {
		'&:hover #dropdown-container': {
			display: 'block'
		},
		'&:hover .MuiButton-root': {
			paddingBottom: 3,
			background: 'none',
			borderBottom: `solid 4px ${theme.accent.main}`,
			borderRadius: 0
		}
	},
	menuButton: {
		paddingTop: 17,
		color: `${theme.accent.main}`,
		paddingBottom: '7px'
	},
	dropdownContainer: {
		marginTop: 1,
		padding: 16,
		backgroundColor: '#FAFAFA',
		zIndex: 1,
		display: 'none',
		borderRadius: 10,
		position: 'absolute',
		'& >.MuiListItem-root': {
			alignItems: 'flex-start'
		},
		'& > a': {
			padding: 8,
			borderRadius: 8,
			textDecoration: 'none',
			color: `${theme.accent.main} !important`,
			transition: 'all 0.5s',
			'&:hover': {
				backgroundColor: '#EBEAEA',
				'& .MuiTypography-h4': {
					color: theme.palette.secondary.main
				}
			}
		}
	},
	link: {
		textDecoration: 'none',
		color: 'black',
		'&:hover': {
			textDecoration: 'underline'
		}
	}
}));

const CollapsibleMenu = (props: any) => {
	const { menuText, dataSrc, menuButtonStyles } = props;
	const { classes, cx } = useStyles();

	return (
		<div className={classes.container}>
			<Button className={cx(classes.menuButton, menuButtonStyles)}>
				<Typography variant="body1">{menuText}</Typography>
			</Button>
			<div id="dropdown-container" className={classes.dropdownContainer}>
				<Grid container width="100%" columnGap={5} direction="row" alignItems="flex-start" justifyContent="flex-start">
					{dataSrc?.map((item: any) => {
						return (
							<Grid item key={`${item?.title}`}>
								<Grid container direction="column">
									<Typography variant="h4" marginLeft={1}>
										{item?.title}
									</Typography>
									{item?.components &&
										item?.components.map((comp, index) => (
											<Box key={index}>
												<Link to={`${PATHS_BY_ENV[env][comp?.link]}${comp?.propsLink ?? ''}`} target="_blank" className={classes.link}>
													<Typography padding={1}>{comp?.title}</Typography>
												</Link>
											</Box>
										))}
								</Grid>
							</Grid>
						);
					})}
				</Grid>
			</div>
		</div>
	);
};

export default CollapsibleMenu;
