import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import typography from '../typography';
import { components } from './overrideComponents';

let theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			light: '#FFF38B',
			main: '#F7E21E',
			dark: '#6A5F00',
			contrastText: '#212121'
		},
		secondary: {
			light: '#BEC2FF',
			main: '#5D65F8',
			dark: '#0000AD',
			contrastText: '#FFF'
		},
		error: {
			light: '#FFDAD4',
			main: '#BA1B1B',
			dark: '#680003',
			contrastText: '#FFF'
		},
		warning: {
			light: '#FFDDB6',
			main: '#ED9812',
			dark: '#A96900',
			contrastText: '#FFF'
		},
		info: {
			light: '#C4E7FF',
			main: '#29B6F6',
			dark: '#00658E',
			contrastText: '#FFF'
		},
		success: {
			light: '#9FF79F',
			main: '#83DA85',
			dark: '#116D26',
			contrastText: '#FFF'
		},
		background: {
			paper: '#FFFFFF',
			default: '#F7F7F7'
		},
		grey: {
			A100: '#F2F2F2'
		}
	},
	surfaces: {
		level1: '#FCFCFC',
		level2: '#F5F5F5',
		level3: '#EEEEEE',
		level4: '#E0E0E0',
		level5: '#BDBDBD',
		contrastText: '#757575'
	},
	surfaceVariants: {
		primary: {
			surface: '#FFFFFF',
			onSurface: '#212121'
		},
		secondary: {
			surface: '#EEEEEE',
			onSurface: '#757575'
		}
	},
	accent: {
		main: '#333333',
		light: '#CCCCCC',
		dark: '#9B9A97'
	},
	mmtBackground: {
		light: '#FAFAFA',
		main: '#F2F2F2',
		dark: '#E5E5E5'
	},
	// @ts-ignore
	typography: { ...typography },
	components
});

theme = responsiveFontSizes(theme);

export default theme;
