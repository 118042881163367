import { useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import InsuranceForms from '../views/insuranceForms/';
import LandingPage from '../views/LandingPage';

const ScrollToTopWrapper = ({ children }) => {
	const location = useLocation();
	useLayoutEffect(() => {
		document.documentElement.scrollTo(0, 0);
	}, [location.pathname]);
	return children;
};

const InsuranceRoutes = () => {
	return (
		<Router>
			<ScrollToTopWrapper>
				<Routes>
					<Route path="/" element={<LandingPage />} />
					<Route path="quote/:insurance_type" element={<InsuranceForms />} />
					<Route path="*" element={<LandingPage />} />
				</Routes>
			</ScrollToTopWrapper>
		</Router>
	);
};

export default InsuranceRoutes;
