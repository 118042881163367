import { Card, CardHeader, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { isEmptyArray } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStateSafe } from '../effects/useStateSafe';
import { isEmpty } from '../helpers/String';

const useStyles = makeStyles()((theme) => ({
	label: {
		marginBottom: 5
	},
	card: {
		display: 'flex',
		minHeight: 'auto',
		height: '100%',
		padding: '5px 20px',
		margin: '5px 0px'
	}
}));

const CheckBoxCard = (props) => {
	const { classes } = useStyles();
	const { title, desc, value, onChange, checkBoxesState } = props || {};
	return (
		<Card className={classes.card} variant="outlined">
			<FormControlLabel
				label=""
				control={<Checkbox color="default" checked={checkBoxesState[value] ?? false} onChange={onChange} name={value ?? ''} />}
			/>
			<CardHeader title={<Typography variant="h6">{title}</Typography>} subheader={<Typography variant="subtitle1">{desc}</Typography>} />
		</Card>
	);
};

const CheckBoxGroup = (props) => {
	const { dataSrc, label, onChange, values } = props || {};
	const { classes } = useStyles();
	const { t } = useTranslation();
	const [checkBoxesState, setCheckBoxesState] = useStateSafe({});

	useEffect(() => {
		onChange && onChange(checkBoxesState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkBoxesState]);

	useEffect(() => {
		const pickedSavedValues = () => {
			let initialSelectedValues = [];
			if (!isEmpty(values) && !isEmptyArray(values)) {
				initialSelectedValues =
					values &&
					values.reduce((initValue, item) => {
						return {
							...initValue,
							[item]: true
						};
					}, {});
			}
			return initialSelectedValues;
		};

		const initializeCheckboxes = async () => {
			const initialCheckboxesValues =
				dataSrc &&
				dataSrc.reduce((initValue, item) => {
					const { value: checkboxValue } = item || {};
					return {
						...initValue,
						[checkboxValue]: false
					};
				}, {});

			const savedValues = pickedSavedValues();

			setCheckBoxesState({ ...initialCheckboxesValues, ...savedValues });
		};

		if (isEmpty(dataSrc) || isEmptyArray(dataSrc)) {
			return;
		}

		initializeCheckboxes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataSrc, values]);

	const handleChange = (e) => {
		setCheckBoxesState((prevState) => {
			return {
				...prevState,
				[e.target.name]: e.target.checked
			};
		});
	};

	const renderCheckBoxes = () => {
		return (
			dataSrc &&
			dataSrc.map((item, index) => {
				const { title, desc, value: checkboxValue } = item || {};
				return (
					<CheckBoxCard
						key={title + index}
						title={t(title)}
						desc={t(desc)}
						onChange={handleChange}
						value={checkboxValue}
						checkBoxesState={checkBoxesState}
					/>
				);
			})
		);
	};

	return (
		<FormGroup>
			<Typography variant="h6" className={classes.label}>
				{t(label)}
			</Typography>
			{renderCheckBoxes()}
		</FormGroup>
	);
};

export default CheckBoxGroup;
