import common from './common';
import insurance from './insurance';
import landing from './landing';
import faq from './faq';

const all = {
	...insurance,
	...common,
	...landing,
	...faq
};

export default all;
