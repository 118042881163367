import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ props: any }>()((theme, { props }) => ({
	validateButton: {
		color: 'white',
		minHeight: 45,
		height: '100%'
	},
	buttonProgress: {
		color: '#d1d1d1',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

const FormButton = (props) => {
	const { buttonText, submitting, onClick, customStyle, ...rest } = props || {};
	const { t } = useTranslation();
	const { classes } = useStyles({ props: customStyle });

	if (submitting) {
		return (
			<Button variant="contained" color="primary" {...rest} disabled={true} className={classes.validateButton}>
				<CircularProgress size={24} className={classes.buttonProgress} />
			</Button>
		);
	}

	return (
		<Button variant="contained" color="primary" {...rest} onClick={onClick}>
			{t(buttonText)}
		</Button>
	);
};

export default FormButton;
