import { makeStyles } from 'tss-react/mui';

export const useInsFeatureStyles = makeStyles()((theme) => ({
	businessInsuranceHeader: {
		textAlign: 'center',
		marginBottom: '2rem',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left'
		}
	},
	img: {
		width: '369px',
		objectFit: 'cover',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			height: '100%'
		}
	},
	insuranceStackContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-around',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column'
		}
	},
	insuranceCtaContainer: {
		maxWidth: '441px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '2.25rem'
		}
	},
	getQuoteBtn: {
		marginTop: '2rem',
		width: 'fit-content'
	},
	reverseOrderMobile: {
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse'
		}
	}
}));
