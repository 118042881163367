import { Card, CardHeader, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import {
	INSURANCE_EMPLOYEE_COVERAGES_DATA_SRC,
	INSURANCE_EMPLOYEE_HOSPITALIZATION_VALUE,
	INSURANCE_EMPLOYEE_SUB_CAT_DATA_SRC,
	INSURANCE_EMPLOYEE_TERM_TAKAFUL_VALUE,
	INSURANCE_EMPLOYEE_TYPE
} from '../../../../constant/insurance';
import { useInsuranceFormContext } from '../../../../context/InsuranceFormContext';
import { useStateSafe } from '../../../../effects/useStateSafe';
import CheckBoxGroup from '../../../CheckBoxGroup';
import ToggleButtons from '../../../ToggleButtons';
import NavigationButtons from '../NavigationButtons';
import SumInsuredDetails from './SumInsuredDetails';

const useStyles = makeStyles()((theme) => ({
	card: {
		textAlign: 'center',
		padding: '26px 56px',
		border: `1px solid ${theme.palette.primary.main}`,
		[theme.breakpoints.down('md')]: {
			padding: '8px'
		}
	},
	subheader: {
		marginTop: 9
	}
}));

const SelectInsuranceType = (props) => {
	const { classes } = useStyles();
	const { t } = useTranslation();
	const { currentStep, setCurrentStep, prevTab, nextTab } = props;

	const { applicantDetails, setApplicantDetails, setActiveMenu }: any = useInsuranceFormContext();
	const { policyType, coverageType, addonCoverages }: any = applicantDetails || {};

	const [addOn, setAddOn] = useStateSafe(addonCoverages);
	const [conventionalOrTakaful, setConventionalOrTakaful] = useStateSafe(policyType || INSURANCE_EMPLOYEE_TYPE.CONVENTIONAL);
	const [defaultCoverage, setDefaultCoverage] = useStateSafe(coverageType);
	const addOnCoveragesDataSrc = INSURANCE_EMPLOYEE_COVERAGES_DATA_SRC[conventionalOrTakaful] || [];

	useEffect(() => {
		INSURANCE_EMPLOYEE_TYPE.CONVENTIONAL === conventionalOrTakaful
			? setDefaultCoverage(INSURANCE_EMPLOYEE_HOSPITALIZATION_VALUE)
			: setDefaultCoverage(INSURANCE_EMPLOYEE_TERM_TAKAFUL_VALUE);
		setAddOn([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conventionalOrTakaful]);

	const onCheckBoxGroupChanged = (values) => {
		const tempValue = [];
		Object.entries(values).forEach(([key, value]) => {
			value === true && tempValue.push(key);
		});
		setAddOn(tempValue);
	};

	const handlePrev = () => {
		if (currentStep === 2) {
			setCurrentStep(1);
			return;
		}
		setActiveMenu(prevTab);
	};
	const handleNext = () => {
		const cleanAddOn = addOnCoveragesDataSrc.map((x) => x.value).filter((obj) => addOn?.indexOf(obj) !== -1);
		if (currentStep === 1) {
			setApplicantDetails((prev) => {
				return {
					...prev,
					policyType: conventionalOrTakaful,
					coverageType: defaultCoverage,
					addonCoverages: cleanAddOn
				};
			});
			setCurrentStep(2);
			return;
		}
		setActiveMenu(nextTab);
	};

	return (
		<Grid item>
			<Grid container spacing={2} direction="column" justifyContent="space-evenly">
				<Grid item xs={12}>
					<ToggleButtons
						exclusive
						label={'EMPLOYEE_APPLICATIONS_DETAILS_TITLE'}
						dataSrc={INSURANCE_EMPLOYEE_SUB_CAT_DATA_SRC}
						onValueChange={(value) => {
							setConventionalOrTakaful(value);
							setAddOn([]);
						}}
						value={conventionalOrTakaful}
						customStyle={{ gridTemplateColumns: '1fr 1fr' }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Card className={classes.card} variant="outlined">
						<CardHeader
							title={
								<Typography variant="h5">
									{INSURANCE_EMPLOYEE_TYPE.CONVENTIONAL === conventionalOrTakaful
										? t('EMPLOYEE_GROUP_HOSPITALIZATION_TITLE')
										: t('EMPLOYEE_COVERAGE_TAKAFUL_BASIC_TITLE')}
								</Typography>
							}
							subheader={
								<Typography className={classes.subheader} variant="subtitle1">
									{INSURANCE_EMPLOYEE_TYPE.CONVENTIONAL === conventionalOrTakaful
										? t('EMPLOYEE_GROUP_HOSPITALIZATION_DESC')
										: t('EMPLOYEE_COVERAGE_TAKAFUL_BASIC_DESC')}
								</Typography>
							}
						/>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<CheckBoxGroup
						dataSrc={addOnCoveragesDataSrc}
						label={'EMPLOYEE_INCREASE_COVERAGE_CHECKBOX_LBL'}
						onChange={onCheckBoxGroupChanged}
						values={addonCoverages}
					/>
				</Grid>
				<NavigationButtons handlePrev={handlePrev} handleNext={handleNext} />
			</Grid>
		</Grid>
	);
};

const ApplicantDetailsEmployee = (props) => {
	const { prevTab, nextTab } = props;
	const { t } = useTranslation();
	const [currentStep, setCurrentStep] = useStateSafe(1);

	return (
		<>
			{currentStep === 1 ? (
				<SelectInsuranceType currentStep={currentStep} setCurrentStep={setCurrentStep} prevTab={prevTab} nextTab={nextTab} />
			) : (
				<SumInsuredDetails currentStep={currentStep} setCurrentStep={setCurrentStep} prevTab={prevTab} nextTab={nextTab} />
			)}
		</>
	);
};

export default ApplicantDetailsEmployee;
