import { Backdrop, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as RegistrationCompleteSVG } from '../../../assets/svg/insurance/registrationComplete.svg';

const useStyles = makeStyles()((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	mainContainer: {
		width: '32%',
		textAlign: 'center',
		backgroundColor: '#FFFFFF',
		filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25))',
		borderRadius: '8px',
		color: theme.accent.main,
		[theme.breakpoints.down('md')]: {
			width: '90%'
		}
	},
	button: {
		marginBottom: 24,
		backgroundColor: theme.palette.secondary.main,
		color: '#FFFFFF',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: 16
		}
	},
	logo: {
		marginTop: 16
	}
}));

const SubmissionBackDrop = (props) => {
	const { isBackDropOpen, setIsBackDropOpen } = props;
	const { classes } = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleClose = () => {
		setIsBackDropOpen(false);
		navigate('/');
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={isBackDropOpen}>
				<Grid container spacing={3} className={classes.mainContainer} direction="column" justifyContent="center" alignItems="center">
					<Grid item>
						<RegistrationCompleteSVG className={classes.logo} />
					</Grid>
					<Grid item>
						<Typography variant="h3">{t('SUBMIT_QUOTATION_SUCCESS_TITLE')}</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle1">{t('INSURANCE_SUBMISSION_QUOTE_BACKDROP')}</Typography>
					</Grid>
					<Grid item>
						<Button className={classes.button} variant="contained" onClick={handleClose}>
							<Typography variant="button">{t('OKAY_LBL')}</Typography>
						</Button>
					</Grid>
				</Grid>
			</Backdrop>
		</>
	);
};

export default SubmissionBackDrop;
