import burglaryInsurance from '../assets/images/insurance/burglaryInsurance.png';
import employeeBenefits from '../assets/images/insurance/employeeBenefits.png';
import fireInsurance from '../assets/images/insurance/fireInsurance.png';
import mariTimeInsurance from '../assets/images/insurance/mariTimeInsurance.png';
import others from '../assets/images/insurance/others.png';
import publicLiabilityInsurance from '../assets/images/insurance/publicLiabilityInsurance.png';

export enum LIABILITY_ADDRESS_FORM_STATUS {
	NEW = 'new',
	EDITING = 'editing',
	DONE = 'done'
}

enum OTHER_TYPES {
	ASSETS_N_PROPERTIES = 'Protect my assets and properties',
	CARGO_N_VESSELS = 'Protect my cargo and vessels',
	MACHINES_N_EQUIP = 'Protect my machines and equipments',
	BUSINESS_LIABILITIES = 'Protect my business from liabilities',
	SECURE_BONDS = 'Secure my bonds',
	OTHERS = 'Others'
}

export const LANDING_HEADER_MENU_SRC = [
	{ title: 'HEADER_TAB_TRANSFER', link: 'transferLink' },
	{ title: 'HEADER_TAB_INSURANCE', link: 'insuranceLink' },
	{ title: 'HEADER_TAB_SUPPORT', link: 'supportCenterLink' }
];

export const LANDING_FREE_QUOTES_DATA_SRC = [
	{
		TITLE: 'EMPLOYEE_BEN_TITLE',
		SUBTITLE: 'EMPLOYEE_BEN_SUBTITLE',
		BUTTON_LABEL: 'GET_FREE_QUOTE_BTN',
		img: employeeBenefits,
		BENEFITS_TITLE: 'EMPLOYEE_BEN_DESC_TITLE',
		BENEFITS_LIST: [
			'EMPLOYEE_BEN_DESC_1',
			'EMPLOYEE_BEN_DESC_2',
			'EMPLOYEE_BEN_DESC_3',
			'EMPLOYEE_BEN_DESC_4',
			'EMPLOYEE_BEN_DESC_5',
			'EMPLOYEE_BEN_DESC_6',
			'EMPLOYEE_BEN_DESC_7',
			'EMPLOYEE_BEN_DESC_8',
			'EMPLOYEE_BEN_DESC_9'
		],
		TYPE: 'employee'
	},
	{
		TITLE: 'ASSET_LIABILITY_TITLE',
		SUBTITLE: 'ASSET_LIABILITY_SUBTITLE',
		BUTTON_LABEL: 'GET_FREE_QUOTE_BTN',
		img: publicLiabilityInsurance,
		BENEFITS_TITLE: 'ASSET_LIABILITY_DESC_TITLE',
		BENEFITS_LIST: [
			'ASSET_LIABILITY_DESC_1',
			'ASSET_LIABILITY_DESC_2',
			'ASSET_LIABILITY_DESC_3',
			'ASSET_LIABILITY_DESC_4',
			'ASSET_LIABILITY_DESC_5',
			'ASSET_LIABILITY_DESC_6',
			'ASSET_LIABILITY_DESC_7',
			'ASSET_LIABILITY_DESC_8',
			'ASSET_LIABILITY_DESC_9'
		],
		TYPE: 'others'
	}
];

export const OTHER_INSURANCE_TYPES_DATA_SRC = [
	{ label: OTHER_TYPES.ASSETS_N_PROPERTIES, value: OTHER_TYPES.ASSETS_N_PROPERTIES },
	{ label: OTHER_TYPES.CARGO_N_VESSELS, value: OTHER_TYPES.CARGO_N_VESSELS },
	{ label: OTHER_TYPES.MACHINES_N_EQUIP, value: OTHER_TYPES.MACHINES_N_EQUIP },
	{ label: OTHER_TYPES.BUSINESS_LIABILITIES, value: OTHER_TYPES.BUSINESS_LIABILITIES },
	{ label: OTHER_TYPES.SECURE_BONDS, value: OTHER_TYPES.SECURE_BONDS },
	{ label: OTHER_TYPES.OTHERS, value: OTHER_TYPES.OTHERS }
];

const ADDRESS_STATES = {
	JOHOR: 'Johor',
	KEDAH: 'Kedah',
	KELANTAN: 'Kelantan',
	MELAKA: 'Melaka',
	NEGERI_SEMBILAN: 'Negeri Sembilan',
	PAHANG: 'Pahang',
	PERAK: 'Perak',
	PERLIS: 'Perlis',
	PULAU_PINANG: 'Pulau Pinang',
	SARAWAK: 'Sarawak',
	SELANGOR: 'Selangor',
	TERENGGANU: 'Terengganu',
	KUALA_LUMPUR: 'Kuala Lumpur',
	LABUAN: 'Labuan',
	SABAH: 'Sabah',
	PUTRAJAYA: 'Putrajaya'
};

export const INSURED_BUILDING_TYPE = {
	RENTAL: 'rental',
	OWNED: 'owned',
	HOME_OFFICE: 'home office'
};

export const INSURED_BUILDING_TYPE_DATA_SRC = [
	{ label: 'BUILDING_TYPE_RENTAL', value: INSURED_BUILDING_TYPE.RENTAL },
	{ label: 'BUILDING_TYPE_OWNED', value: INSURED_BUILDING_TYPE.OWNED },
	{ label: 'BUILDING_TYPE_HOME_OFFICE', value: INSURED_BUILDING_TYPE.HOME_OFFICE }
];

export const INSURED_ADDRESS_STATE_DATA_SRC = [
	{ label: ADDRESS_STATES.JOHOR, value: ADDRESS_STATES.JOHOR },
	{ label: ADDRESS_STATES.KEDAH, value: ADDRESS_STATES.KEDAH },
	{ label: ADDRESS_STATES.KELANTAN, value: ADDRESS_STATES.KELANTAN },
	{ label: ADDRESS_STATES.KUALA_LUMPUR, value: ADDRESS_STATES.KUALA_LUMPUR },
	{ label: ADDRESS_STATES.LABUAN, value: ADDRESS_STATES.LABUAN },
	{ label: ADDRESS_STATES.MELAKA, value: ADDRESS_STATES.MELAKA },
	{ label: ADDRESS_STATES.NEGERI_SEMBILAN, value: ADDRESS_STATES.NEGERI_SEMBILAN },
	{ label: ADDRESS_STATES.PAHANG, value: ADDRESS_STATES.PAHANG },
	{ label: ADDRESS_STATES.PERAK, value: ADDRESS_STATES.PERAK },
	{ label: ADDRESS_STATES.PERLIS, value: ADDRESS_STATES.PERLIS },
	{ label: ADDRESS_STATES.PULAU_PINANG, value: ADDRESS_STATES.PULAU_PINANG },
	{ label: ADDRESS_STATES.PUTRAJAYA, value: ADDRESS_STATES.PUTRAJAYA },
	{ label: ADDRESS_STATES.TERENGGANU, value: ADDRESS_STATES.TERENGGANU },
	{ label: ADDRESS_STATES.SABAH, value: ADDRESS_STATES.SABAH },
	{ label: ADDRESS_STATES.SARAWAK, value: ADDRESS_STATES.SARAWAK },
	{ label: ADDRESS_STATES.SELANGOR, value: ADDRESS_STATES.SELANGOR }
];

export const INSURANCE_EMPLOYEE_TYPE = {
	CONVENTIONAL: 'conventional',
	TAKAFUL: 'takaful'
};
export const INSURANCE_OTHERS_CHECKBOX = [
	{ CHECKBOX_DESC: 'Fire' },
	{ CHECKBOX_DESC: 'Flood' },
	{ CHECKBOX_DESC: 'Burglary' },
	{ CHECKBOX_DESC: 'Marine' },
	{ CHECKBOX_DESC: 'Machinery Breakdown' },
	{ CHECKBOX_DESC: 'Money' },
	{ CHECKBOX_DESC: 'Consequential Loss' },
	{ CHECKBOX_DESC: 'Motor' },
	{ CHECKBOX_DESC: 'Public Liability' }
];

export const BUSINESS_TYPE = {
	LAUNDRY: 'laundry shop',
	BEAUTY: 'beauty/hair',
	HOSP: 'hospitality',
	OFFICE: 'office',
	EDU: 'education',
	MED: 'medical center',
	FNB: 'f&b',
	ROAD_STALL: 'road-side stall',
	RETAIL_STORE: 'retail store',
	OTHERS: 'others'
};

export const INSURANCE_TYPE = {
	EMPLOYEE: 'employee',
	FIRE: 'fire',
	BURGLARY: 'burglary',
	MARINE_CARGO: 'marine_cargo',
	MARINE_HULL: 'marine_hull',
	PUBLIC_LIABILITY: 'public_liability',
	OTHERS: 'others',
	ASSET_LIABILITY: 'asset_liability'
};

export const INSURANCE_TYPES_DATA_SRC = [
	{ label: 'INSURANCE_TYPE_EMPLOYEE', value: INSURANCE_TYPE.EMPLOYEE },
	{ label: 'INSURANCE_TYPE_FIRE', value: INSURANCE_TYPE.FIRE },
	{ label: 'INSURANCE_TYPE_BURGLARY', value: INSURANCE_TYPE.BURGLARY },
	{ label: 'INSURANCE_TYPE_MARINE_CARGO', value: INSURANCE_TYPE.MARINE_CARGO },
	{ label: 'INSURANCE_TYPE_MARINE_HULL', value: INSURANCE_TYPE.MARINE_HULL },
	{ label: 'INSURANCE_TYPE_PUBLIC_LIABILITY', value: INSURANCE_TYPE.PUBLIC_LIABILITY },
	{ label: 'INSURANCE_TYPE_OTHERS', value: INSURANCE_TYPE.OTHERS }
];

export const INSURANCE_PROVIDERS_DATA_SRC = [
	{ label: 'INSURANCE_PROVIDER_AIA', value: 'AIA Bhd.' },
	{ label: 'INSURANCE_PROVIDER_AIA_GEN', value: 'AIA General Berhad' },
	{ label: 'INSURANCE_PROVIDER_AIA_MY', value: 'AIG Malaysia Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_ALIANZ_GEN', value: 'Allianz General Insurance Company (Malaysia) Berhad' },
	{ label: 'INSURANCE_PROVIDER_ALIANZ_LIFE', value: 'Allianz Life Insurance Malaysia Berhad' },
	{ label: 'INSURANCE_PROVIDER_AM_GEN', value: 'AmGeneral Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_AM_MET_LIFE', value: 'AmMetLife Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_AXA_AFF_GEN', value: 'AXA Affin General Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_AXA_AFF_LIFE', value: 'AXA Affin Life Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_BERJAYA', value: 'Berjaya Sompo Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_CHUB', value: 'Chubb Insurance Malaysia Berhad' },
	{ label: 'INSURANCE_PROVIDER_ETIQA_GEN', value: 'Etiqa General Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_ETIQA_LIFE', value: 'Etiqa Life Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_GIBRA', value: 'Gibraltar BSN Life Berhad' },
	{ label: 'INSURANCE_PROVIDER_GE_GEN', value: 'Great Eastern General Insurance (Malaysia) Berhad' },
	{ label: 'INSURANCE_PROVIDER_GE_LIFE', value: 'Great Eastern Life Assurance (Malaysia) Berhad' },
	{ label: 'INSURANCE_PROVIDER_HLA', value: 'Hong Leong Assurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_LIB', value: 'Liberty Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_LON', value: 'Lonpac Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_MANULIFE', value: 'Manulife Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_MCIS', value: 'MCIS Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_MPI', value: 'MPI Generali Insurans Berhad' },
	{ label: 'INSURANCE_PROVIDER_MSIG', value: 'MSIG Insurance (Malaysia) Bhd.' },
	{ label: 'INSURANCE_PROVIDER_PACNO', value: 'Pacific & Orient Insurance Co. Bhd' },
	{ label: 'INSURANCE_PROVIDER_PROG', value: 'Progressive Insurance Bhd.' },
	{ label: 'INSURANCE_PROVIDER_PRU', value: 'Prudential Assurance Malaysia Berhad' },
	{ label: 'INSURANCE_PROVIDER_QBE', value: 'QBE Insurance (Malaysia) Berhad' },
	{ label: 'INSURANCE_PROVIDER_RHB', value: 'RHB Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_SUN', value: 'Sun Life Malaysia Assurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_TPAC', value: 'The Pacific Insurance Berhad' },
	{ label: 'INSURANCE_PROVIDER_TOKM', value: 'Tokio Marine Insurans (Malaysia) Berhad' },
	{ label: 'INSURANCE_PROVIDER_TOKML', value: 'Tokio Marine Life Insurance Malaysia Bhd.' },
	{ label: 'INSURANCE_PROVIDER_TUNE', value: 'Tune Insurance Malaysia Berhad' },
	{ label: 'INSURANCE_PROVIDER_ZUCGEN', value: 'Zurich General Insurance Malaysia Berhad' },
	{ label: 'INSURANCE_PROVIDER_ZUCLIFE', value: 'Zurich Life Insurance Malaysia Berhad' }
];

export const INSURANCE_EMPLOYEE_SUB_CAT_DATA_SRC = [
	{ label: 'INSURANCE_EMPLOYEE_SUB_CON', value: INSURANCE_EMPLOYEE_TYPE.CONVENTIONAL },
	{ label: 'INSURANCE_EMPLOYEE_SUB_TAKAFUL', value: INSURANCE_EMPLOYEE_TYPE.TAKAFUL }
];

export const INSURANCE_EMPLOYEE_HOSPITALIZATION_VALUE = 'group hospitalization';
export const INSURANCE_EMPLOYEE_TERM_TAKAFUL_VALUE = 'group term takaful';

const INSURANCE_EMPLOYEE_TERM_LIFE_VALUE = 'group term life';
const INSURANCE_EMPLOYEE_OUTPATIENT_VALUE = 'group outpatient';
const INSURANCE_EMPLOYEE_DEATH_AND_DISABLEMENT_VALUE = 'group accidental death and disablement benefit';
const INSURANCE_EMPLOYEE_CRITICAL_ILLNES_VALUE = 'group critical illness benefit';

export const INSURANCE_EMPLOYEE_COVERAGES_DATA_SRC = {
	[INSURANCE_EMPLOYEE_TYPE.CONVENTIONAL]: [
		{ title: 'EMPLOYEE_COVERAGE_OUTPATIENT_TITLE', desc: 'EMPLOYEE_COVERAGE_OUTPATIENT_DESC', value: INSURANCE_EMPLOYEE_OUTPATIENT_VALUE },
		{ title: 'EMPLOYEE_COVERAGE_BASIC_TITLE', desc: 'EMPLOYEE_COVERAGE_BASIC_DESC', value: INSURANCE_EMPLOYEE_TERM_LIFE_VALUE }
	],
	[INSURANCE_EMPLOYEE_TYPE.TAKAFUL]: [
		{
			title: 'EMPLOYEE_GROUP_HOSPITALIZATION_TITLE',
			desc: 'EMPLOYEE_GROUP_TAKAFUL_HOSPITALIZATION_DESC',
			value: INSURANCE_EMPLOYEE_HOSPITALIZATION_VALUE
		},
		{
			title: 'EMPLOYEE_COVERAGE_TAKAFUL_DEATH_AND_DISABLEMENT_TITLE',
			desc: 'EMPLOYEE_COVERAGE_TAKAFUL_DEATH_AND_DISABLEMENT_DESC',
			value: INSURANCE_EMPLOYEE_DEATH_AND_DISABLEMENT_VALUE
		},
		{
			title: 'EMPLOYEE_COVERAGE_TAKAFUL_CRITICAL_ILLNES_TITLE',
			desc: 'EMPLOYEE_COVERAGE_TAKAFUL_CRITICAL_ILLNES_DESC',
			value: INSURANCE_EMPLOYEE_CRITICAL_ILLNES_VALUE
		}
	]
};

export const INSURANCE_BUSINESS_NATURES_DATA_SRC = [
	{ label: 'BUSINESS_LAUNDRY', value: BUSINESS_TYPE.LAUNDRY },
	{ label: 'BUSINESS_BEAUTY', value: BUSINESS_TYPE.BEAUTY },
	{ label: 'BUSINESS_HOSP', value: BUSINESS_TYPE.HOSP },
	{ label: 'BUSINESS_OFFICE', value: BUSINESS_TYPE.OFFICE },
	{ label: 'BUSINESS_EDU', value: BUSINESS_TYPE.EDU },
	{ label: 'BUSINESS_MED', value: BUSINESS_TYPE.MED },
	{ label: 'BUSINESS_FNB', value: BUSINESS_TYPE.FNB },
	{ label: 'BUSINESS_ROAD_STALL', value: BUSINESS_TYPE.ROAD_STALL },
	{ label: 'BUSINESS_RETAIL_STORE', value: BUSINESS_TYPE.RETAIL_STORE },
	{ label: 'BUSINESS_OTHERS', value: BUSINESS_TYPE.OTHERS }
];

export const INSURANCE_BUSINESS_NATURES_SUB_CAT_DATA_SRC = {
	[BUSINESS_TYPE.LAUNDRY]: [
		{ label: 'LAUNDRY_SUB_LAUNDRY', value: 'Laundry' },
		{ label: 'LAUNDRY_SUB_DRY_CLEAN', value: 'Dry cleaner' }
	],
	[BUSINESS_TYPE.BEAUTY]: [
		{ label: 'BEAUTY_SUB_HAIR', value: 'Hair saloon' },
		{ label: 'BEAUTY_SUB_BEAUTY', value: 'Beauty saloon' }
	],
	[BUSINESS_TYPE.HOSP]: [
		{ label: 'HOSP_SUB_HOTEL', value: 'Hotel' },
		{ label: 'HOSP_SUB_BUD_HOTEL', value: 'Budget hotel' },
		{ label: 'HOSP_SUB_HOSTEL', value: 'Hostel' }
	],
	[BUSINESS_TYPE.OFFICE]: [
		{ label: 'OFFICE_SUB_CO_WORK', value: 'Co-working space' },
		{ label: 'OFFICE_SUB_LOT', value: 'Office lot' }
	],
	[BUSINESS_TYPE.EDU]: [
		{ label: 'EDU_SUB_KINDER', value: 'Kindergarten' },
		{ label: 'EDU_SUB_TRAINING', value: 'Training center' },
		{ label: 'EDU_SUB_TUITION', value: 'Tuition center' }
	],
	[BUSINESS_TYPE.MED]: [
		{ label: 'MED_SUB_NURSE', value: 'Nursing home' },
		{ label: 'MED_SUB_CLINIC', value: 'Clinic' },
		{ label: 'MED_SUB_DENTIST', value: 'Dentist' }
	],
	[BUSINESS_TYPE.FNB]: [
		{ label: 'FNB_SUB_FINE', value: 'Fine dining' },
		{ label: 'FNB_SUB_CASUAL', value: 'Casual dining' },
		{ label: 'FNB_SUB_FAST', value: 'Fast food' },
		{ label: 'FNB_SUB_COFFEE', value: 'Coffee shop' },
		{ label: 'FNB_SUB_MAMAK', value: 'Mamak stall' }
	],
	[BUSINESS_TYPE.ROAD_STALL]: [
		{ label: 'ROAD_STALL_SUB_WITH_ROOF', value: 'With metal roof' },
		{ label: 'ROAD_STALL_SUB_WITHOUT_ROOF', value: 'Without metal roof' }
	],
	[BUSINESS_TYPE.RETAIL_STORE]: [
		{ label: 'RETAIL_STORE_SUB_BRICK_ZINC_ROOF', value: 'With brick and zinc roof' },
		{ label: 'RETAIL_STORE_SUB_BRICK_NON_COMB_ROOF', value: 'With brick and non-combustible roof' }
	]
};

export const QUOTATION_FORM_TABS = {
	TYPE_OF_INSURANCE: 'TAB_TYPE_OF_INSURANCE',
	APPLICATION_DETAILS: 'TAB_APPLICATION_DETAILS',
	ADD_ONS: 'TAB_ADD_ONS',
	CONTACT_DETAILS: 'TAB_CONTACT_DETAILS'
};

export const INSURANCE_LANDING_INFO = [
	{
		title: 'INSURANCE_EMP_BEN_TITLE',
		subTitle: 'INSURANCE_EMP_BEN_SUBTITLE',
		img: employeeBenefits,
		highlight: 'INSURANCE_EMP_BEN_HIGHLIGHT',
		getQuote: 'INSURANCE_GET_QUOTE',
		urlParam: INSURANCE_TYPE.EMPLOYEE
	},
	{
		title: 'INSURANCE_FIRE_TITLE',
		subTitle: 'INSURANCE_FIRE_SUBTITLE',
		img: fireInsurance,
		highlight: 'INSURANCE_FIRE_HIGHLIGHT',
		getQuote: 'INSURANCE_GET_QUOTE',
		urlParam: INSURANCE_TYPE.FIRE
	},
	{
		title: 'INSURANCE_BURGLAR_TITLE',
		subTitle: 'INSURANCE_BURGLAR_SUBTITLE',
		img: burglaryInsurance,
		highlight: 'INSURANCE_BURGLAR_HIGHLIGHT',
		getQuote: 'INSURANCE_GET_QUOTE',
		urlParam: INSURANCE_TYPE.BURGLARY
	},
	{
		title: 'INSURANCE_MARINE_CARGO_TITLE',
		subTitle: 'INSURANCE_MARINE_CARGO_SUBTITLE',
		img: mariTimeInsurance,
		highlight: 'INSURANCE_MARINE_CARGO_HIGHLIGHT',
		getQuote: 'INSURANCE_COMING_SOON',
		urlParam: INSURANCE_TYPE.MARINE_CARGO,
		disabled: true
	},
	{
		title: 'INSURANCE_MARINE_HULL_TITLE',
		subTitle: 'INSURANCE_MARINE_HULL_SUBTITLE',
		img: mariTimeInsurance,
		highlight: 'INSURANCE_MARINE_HULL_HIGHLIGHT',
		getQuote: 'INSURANCE_COMING_SOON',
		urlParam: INSURANCE_TYPE.MARINE_HULL,
		disabled: true
	},
	{
		title: 'INSURANCE_PUBLIC_LIABILITY_TITLE',
		subTitle: 'INSURANCE_PUBLIC_LIABILITY_SUBTITLE',
		img: publicLiabilityInsurance,
		highlight: 'INSURANCE_PUBLIC_LIABILITY_HIGHLIGHT',
		getQuote: 'INSURANCE_GET_QUOTE',
		urlParam: INSURANCE_TYPE.PUBLIC_LIABILITY
	},
	{
		title: 'INSURANCE_OTHERS_TITLE',
		subTitle: 'INSURANCE_OTHERS_SUBTITLE',
		img: others,
		getQuote: 'INSURANCE_GET_QUOTE',
		urlParam: INSURANCE_TYPE.OTHERS
	}
];

export const QUEST_FOR_EXISTING_POLICY_BY_TYPE = {
	[INSURANCE_TYPE.EMPLOYEE]: 'DO_YOU_HAVE_EMPLOYEE_INSURANCE',
	[INSURANCE_TYPE.ASSET_LIABILITY]: 'DO_YOU_HAVE_ASSET_LIABILITY_INSURANCE',
	[INSURANCE_TYPE.FIRE]: 'DO_YOU_HAVE_FIRE_INSURANCE',
	[INSURANCE_TYPE.BURGLARY]: 'DO_YOU_HAVE_BURGLARY_INSURANCE',
	[INSURANCE_TYPE.MARINE_CARGO]: '',
	[INSURANCE_TYPE.MARINE_HULL]: '',
	[INSURANCE_TYPE.PUBLIC_LIABILITY]: 'DO_YOU_HAVE_PUBLIC_LIABILITY_INSURANCE',
	[INSURANCE_TYPE.OTHERS]: 'DO_YOU_HAVE_OTHERS_INSURANCE'
};

export const INSURANCE_TYPE_DESC = {
	[INSURANCE_TYPE.EMPLOYEE]: {
		title: 'INSURANCE_TYPE_DESC_EMP_BEN_TITLE',
		desc: 'INSURANCE_TYPE_DESC_EMP_BEN_DESC',
		img: employeeBenefits
	},
	[INSURANCE_TYPE.ASSET_LIABILITY]: {
		title: 'INSURANCE_TYPE_DESC_OTHERS_TITLE',
		desc: 'INSURANCE_TYPE_DESC_OTHERS_DESC',
		img: others
	}
};

export const INSURANCE_EMPLOYEE_MONTHLY_AMOUNT_DATA_SRC = [
	{ label: '< MYR 50', value: '< MYR 50' },
	{ label: 'MYR 50 - MYR 100', value: 'MYR 50 - MYR 100' },
	{ label: 'MYR 150 - MYR 300', value: 'MYR 150 - MYR 300' },
	{ label: 'MYR 300 - MYR 500', value: 'MYR 300 - MYR 500' },
	{ label: 'MYR 500 - MYR 1000', value: 'MYR 500 - MYR 1000' },
	{ label: '> MYR 500', value: '> MYR 500' }
];

export const KEY_COVERAGES_SELLING_POINTS = {
	[INSURANCE_TYPE.EMPLOYEE]: [
		{
			main: 'EMPLOYEE_KEY_COVERAGE_INFO_1',
			sellingPoints: [
				'EMPLOYEE_KEY_COVERAGE_INFO_1_SELLING_POINT_1',
				'EMPLOYEE_KEY_COVERAGE_INFO_1_SELLING_POINT_2',
				'EMPLOYEE_KEY_COVERAGE_INFO_1_SELLING_POINT_3'
			]
		},
		{
			main: 'EMPLOYEE_KEY_COVERAGE_INFO_2',
			sellingPoints: ['EMPLOYEE_KEY_COVERAGE_INFO_2_SELLING_POINT_1', 'EMPLOYEE_KEY_COVERAGE_INFO_2_SELLING_POINT_2']
		}
	],
	[INSURANCE_TYPE.FIRE]: [
		{
			main: 'FIRE_KEY_COVERAGE_INFO_1',
			sellingPoints: [
				'FIRE_KEY_COVERAGE_INFO_1_SELLING_POINT_1',
				'FIRE_KEY_COVERAGE_INFO_1_SELLING_POINT_2',
				'FIRE_KEY_COVERAGE_INFO_1_SELLING_POINT_3'
			]
		}
	],
	[INSURANCE_TYPE.BURGLARY]: [
		{
			main: 'BURGLARY_KEY_COVERAGE_INFO_1',
			sellingPoints: [
				'BURGLARY_KEY_COVERAGE_INFO_1_SELLING_POINT_1',
				'BURGLARY_KEY_COVERAGE_INFO_1_SELLING_POINT_2',
				'BURGLARY_KEY_COVERAGE_INFO_1_SELLING_POINT_3'
			]
		}
	],
	[INSURANCE_TYPE.MARINE_CARGO]: [],
	[INSURANCE_TYPE.MARINE_HULL]: [],
	[INSURANCE_TYPE.PUBLIC_LIABILITY]: [
		{
			main: 'PUBLIC_LIABILITY_KEY_COVERAGE_INFO_1',
			sellingPoints: ['PUBLIC_LIABILITY_KEY_COVERAGE_INFO_1_SELLING_POINT_1', 'PUBLIC_LIABILITY_KEY_COVERAGE_INFO_1_SELLING_POINT_2']
		},
		{
			main: 'PUBLIC_LIABILITY_KEY_COVERAGE_INFO_2',
			sellingPoints: ['PUBLIC_LIABILITY_KEY_COVERAGE_INFO_2_SELLING_POINT_1', 'PUBLIC_LIABILITY_KEY_COVERAGE_INFO_2_SELLING_POINT_2']
		}
	],
	[INSURANCE_TYPE.OTHERS]: []
};

export const INSURED_CURRENCY = {
	MY: 'MYR'
};

export const INS_ASSETS_LIA_PRODUCT_DETAILS = [
	{
		title: 'INS_PROD_TITLE_PROPERTY',
		desc: 'INS_PROD_DESC_PROPERTY',
		itemList: ['INS_PROD_ITEM_1_PROPERTY', 'INS_PROD_ITEM_2_PROPERTY', 'INS_PROD_ITEM_3_PROPERTY', 'INS_PROD_ITEM_4_PROPERTY']
	},
	{
		title: 'INS_PROD_TITLE_MARINE',
		desc: 'INS_PROD_DESC_MARINE',
		itemList: ['INS_PROD_ITEM_1_MARINE', 'INS_PROD_ITEM_2_MARINE']
	},
	{
		title: 'INS_PROD_TITLE_ENGINEERING',
		desc: '',
		itemList: ['INS_PROD_ITEM_1_ENGINEERING', 'INS_PROD_ITEM_2_ENGINEERING']
	},
	{
		title: 'INS_PROD_TITLE_LIABILITIES',
		desc: 'INS_PROD_DESC_LIABILITIES',
		itemList: ['INS_PROD_ITEM_1_LIABILITIES', 'INS_PROD_ITEM_2_LIABILITIES', 'INS_PROD_ITEM_3_LIABILITIES']
	},
	{
		title: 'INS_PROD_TITLE_SPECIALIZED_PROTECT',
		desc: 'INS_PROD_DESC_SPECIALIZED_PROTECT',
		itemList: ['INS_PROD_ITEM_1_SPECIALIZED_PROTECT', 'INS_PROD_ITEM_2_SPECIALIZED_PROTECT']
	},
	{
		title: 'INS_PROD_TITLE_OTHERS',
		desc: '',
		itemList: ['INS_PROD_ITEM_1_OTHERS', 'INS_PROD_ITEM_2_OTHERS']
	}
];

export const INS_EMP_PRODUCT_DETAILS = [
	{
		title: 'INS_PROD_TITLE_EMP_GTL',
		desc: '',
		itemList: ['INS_PROD_ITEM_1_EMP_GTL', 'INS_PROD_ITEM_2_EMP_GTL', 'INS_PROD_ITEM_3_EMP_GTL', 'INS_PROD_ITEM_4_PROPERTY'],
		subtitle: 'INS_PROD_SUB_TITLE_EMP_GTL',
		subItemList: ['INS_PROD_SUB_ITEM_1_EMP_GTL', 'INS_PROD_SUB_ITEM_2_EMP_GTL']
	},
	{
		title: 'INS_PROD_TITLE_EMP_GHS',
		desc: '',
		itemList: ['INS_PROD_ITEM_1_EMP_GHS', 'INS_PROD_ITEM_2_EMP_GHS'],
		subtitle: 'INS_PROD_SUB_TITLE_EMP_GHS',
		subItemList: ['INS_PROD_SUB_ITEM_1_EMP_GHS', 'INS_PROD_SUB_ITEM_2_EMP_GHS']
	},
	{
		title: 'INS_PROD_TITLE_EMP_GPA',
		desc: '',
		itemList: ['INS_PROD_ITEM_1_EMP_GPA', 'INS_PROD_ITEM_2_EMP_GPA', 'INS_PROD_ITEM_3_EMP_GPA', 'INS_PROD_ITEM_4_EMP_GPA', 'INS_PROD_ITEM_5_EMP_GPA']
	}
];

export const INS_CLAIM_PROCEDURES = [
	{
		title: 'INS_CLAIM_PROCEDURES_TITLE_1',
		desc: 'INS_CLAIM_PROCEDURES_DESC_1'
	},
	{
		title: 'INS_CLAIM_PROCEDURES_TITLE_2',
		desc: 'INS_CLAIM_PROCEDURES_DESC_2'
	},
	{
		title: 'INS_CLAIM_PROCEDURES_TITLE_3',
		desc: 'INS_CLAIM_PROCEDURES_DESC_3'
	},
	{
		title: 'INS_CLAIM_PROCEDURES_TITLE_4',
		desc: 'INS_CLAIM_PROCEDURES_DESC_4'
	}
];

export const PERSONAL_INS_PRODUCT_DETAILS = [
	{
		title: 'INS_PERSONAL_MOTOR_TITLE',
		itemList: ['INS_PERSONAL_MOTOR_ITEM_1', 'INS_PERSONAL_MOTOR_ITEM_2'],
		ctaLink: '',
		isCTAEnabled: true,
		ctaLabel: 'GET_A_FREE_QUOTE_LBL'
	},
	{
		title: 'INS_PERSONAL_TRAVEL_TITLE',
		itemList: ['INS_PERSONAL_TRAVEL_1', 'INS_PERSONAL_TRAVEL_2'],
		ctaLink: '',
		isCTAEnabled: true,
		ctaLabel: 'GET_A_FREE_QUOTE_LBL'
	},
	{
		title: 'INS_PERSONAL_ACCIDENT_TITLE',
		itemList: ['INS_PERSONAL_ACCIDENT_1', 'INS_PERSONAL_ACCIDENT_2', 'INS_PERSONAL_ACCIDENT_3', 'INS_PERSONAL_ACCIDENT_4'],
		ctaLink: '',
		isCTAEnabled: true,
		ctaLabel: 'GET_A_FREE_QUOTE_LBL'
	}
];

export const CONTACT_INFO = ['CONTACT_INFO_1', 'CONTACT_INFO_2', 'CONTACT_INFO_3'];
