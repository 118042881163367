import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({}));

const GeneralLayout = (props) => {
	const { header, content, footer } = props;
	const { classes } = useStyles();

	return (
		<Grid container direction="column">
			<Grid item xs={12}>
				{header}
			</Grid>
			<Grid item width="100%">
				<Grid container>{content}</Grid>
			</Grid>
			{footer && (
				<Grid item xs={12}>
					{footer}
				</Grid>
			)}
		</Grid>
	);
};

export default GeneralLayout;
