import { Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import ClaimAndSupporting from '../assets/images/insurance/others.png';
import { INS_CLAIM_PROCEDURES } from '../constant/insurance';
import CustomGrid from './CustomGrid';
import ProductDescCards from './ProductDescCards';

const useStyles = makeStyles()((theme) => ({
	btn: {
		borderBottom: `2px solid ${theme.accent.main}`,
		borderRadius: 0,
		padding: 0,
		width: 'fit-content',
		marginTop: '16px'
	},
	wrapGrid: {
		padding: '48px 0px 16px',
		[theme.breakpoints.down('sm')]: {
			padding: '32px 0px 16px'
		}
	},
	img: {
		width: '380px',
		height: '260px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			height: '100%'
		}
	}
}));

const ClaimsAndSupport = (props) => {
	const theme = useTheme();
	const { classes } = useStyles();
	const { t } = useTranslation();

	return (
		<CustomGrid
			gridContainerStyle={{
				textAlign: 'center',
				padding: '32px 0px 24px',
				[theme.breakpoints.down('sm')]: {
					textAlign: 'left'
				}
			}}>
			<Typography variant="h6" color={theme.surfaces.contrastText}>
				{t('CLAIMS_AND_SUPPORT')}
			</Typography>
			<Typography variant="h2">{t('CLAIMS_AND_SUPPORT_TITLE')}</Typography>
			<Typography variant="h4" color={theme.surfaces.contrastText}>
				{t('CLAIMS_AND_SUPPORT_SUBTITLE')}
			</Typography>

			<Grid container direction={{ md: 'row-reverse', xs: 'column' }} alignItems="center" justifyContent="left" className={classes.wrapGrid}>
				<Grid item xs={12} sm={6}>
					<img src={ClaimAndSupporting} alt={t('CLAIMS_AND_SUPPORT')} className={classes.img} />
				</Grid>
				<Grid item xs={12} sm={6} marginTop="16px">
					<Stack direction="column" textAlign="left">
						<Typography variant="h6" color={theme.surfaces.contrastText}>
							{t('CLAIMS')}
						</Typography>
						<Typography variant="h3">{t('EASY_CLAIM_WITH_MM')}</Typography>
						<Typography variant="h5">{t('SUBMIT_YOUR_CLAIMS_VIA')}</Typography>
						<Button href="mailto: insurance@moneymatch.co" variant="text" color="inherit" className={classes.btn}>
							<Typography variant="h6">{t('INSURANCE_EMAIL')}</Typography>
						</Button>
					</Stack>
				</Grid>
			</Grid>

			<ProductDescCards gridColumnCount={4} productList={INS_CLAIM_PROCEDURES} cardColor={theme.palette.background.default} titleFontSize="16px" />
		</CustomGrid>
	);
};

export default ClaimsAndSupport;
