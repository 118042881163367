import { REGION } from '../constant/locale';

export enum API {
	INS = 'INS'
}

// const baseUrlMap = {
// 	MY: {
// 		MMT: process.env.REACT_APP_URL_MMT
// 	},
// 	BN: {
// 		MMT: process.env.REACT_APP_URL_MMT_BN
// 	},
// 	AU: {
// 		MMT: process.env.REACT_APP_URL_MMT
// 	}
// };

export function getBaseUrl(code: API): string {
	// const region = localStorage.getItem(REGION) || 'MY';
	switch (code) {
		// case API.MMT:
		// 	return baseUrlMap[region].MMT;
		case API.INS:
			return process.env.REACT_APP_URL_INS;
		default:
			return code;
	}
}
