import { Divider, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';
import { BUSINESS_TYPE, INSURANCE_BUSINESS_NATURES_DATA_SRC, INSURANCE_BUSINESS_NATURES_SUB_CAT_DATA_SRC } from '../../../../constant/insurance';
import { useInsuranceFormContext } from '../../../../context/InsuranceFormContext';
import { useStateSafe } from '../../../../effects/useStateSafe';
import { isEmpty } from '../../../../helpers/String';
import i18n from '../../../../i18n';
import ToggleButtons from '../../../ToggleButtons';
import { BasicAutoComplete } from '../../../generalFormComponents/BasicAutoComplete';
import BasicTextInput from '../../../generalFormComponents/BasicTextInput';
import NavigationButtons from '../NavigationButtons';

const useStyles = makeStyles()((theme) => ({
	title: {
		color: '#D1D1D1'
	},
	divider: {
		marginTop: 10
	},
	navigationButtons: {
		marginTop: 10
	},
	marginTop: {
		marginTop: 10
	}
}));

const BusinessNature = (props) => {
	const { setCurrentStep, prevTab } = props || {};
	const { classes } = useStyles();
	const { t } = useTranslation();

	const { applicantDetails, setApplicantDetails, setActiveMenu }: any = useInsuranceFormContext();
	const { hasUrl: ctxHasUrl, url: ctxUrl, business }: any = applicantDetails || {};
	const { nature: ctxNature, listSpecify: ctxListSpecify, otherSpecify: ctxOtherSpecify }: any = business || {};
	const [businessNatureDataSrc, setBusinessNatureDataSrc] = useStateSafe([]);

	const getStoredListSpecify = () => {
		if (isEmpty(ctxNature) || isEmpty(ctxListSpecify)) return null;

		return INSURANCE_BUSINESS_NATURES_SUB_CAT_DATA_SRC[ctxNature].find((x) => x.value === ctxListSpecify);
	};

	const validationSchema = yup.object({
		hasUrl: yup.bool().required(i18n.t('REQUIRED')),
		url: yup.string().when('hasUrl', {
			is: true,
			then: yup.string().required(i18n.t('REQUIRED'))
		}),
		nature: yup.string().required(i18n.t('REQUIRED')),
		listSpecify: yup.object().when('nature', {
			is: (nature) => nature !== BUSINESS_TYPE.OTHERS,
			then: yup.object().typeError(i18n.t('REQUIRED')).required(i18n.t('REQUIRED')),
			otherwise: yup.object().nullable()
		}),
		otherSpecify: yup.string().when('nature', {
			is: (nature) => nature === BUSINESS_TYPE.OTHERS,
			then: yup.string().required(i18n.t('REQUIRED')),
			otherwise: yup.string().nullable()
		})
	});

	const formik = useFormik({
		initialValues: {
			nature: ctxNature ?? INSURANCE_BUSINESS_NATURES_DATA_SRC[0].value,
			listSpecify: ctxListSpecify ?? '',
			otherSpecify: ctxOtherSpecify ?? '',
			hasUrl: ctxHasUrl ?? true,
			url: ctxUrl ?? ''
		},
		validationSchema,
		onSubmit: async (values) => {
			const { hasUrl, url, nature, listSpecify, otherSpecify } = values || {};
			setApplicantDetails((prevState) => {
				return {
					...prevState,
					hasUrl,
					url,
					business: { nature, listSpecify: listSpecify?.value, otherSpecify }
				};
			});
			setCurrentStep((prevState) => prevState + 1);
		}
	});

	const { values: formikValues } = formik || {};
	const { nature: businessNature, hasUrl: formikHasUrl }: any = formikValues || {};

	const handlePrev = () => {
		setActiveMenu(prevTab);
	};
	const handleNext = () => {
		formik.handleSubmit();
	};

	useEffect(() => {
		if (isEmpty(businessNature)) return;
		if (businessNature === BUSINESS_TYPE.OTHERS) {
			formik.setFieldValue('listSpecify', null);
			return;
		}

		setBusinessNatureDataSrc(INSURANCE_BUSINESS_NATURES_SUB_CAT_DATA_SRC[businessNature]);

		if (businessNature === ctxNature) {
			formik.setFieldValue('listSpecify', getStoredListSpecify());
			return;
		}

		formik.setFieldValue('otherSpecify', '');
		formik.setFieldValue('listSpecify', INSURANCE_BUSINESS_NATURES_SUB_CAT_DATA_SRC[businessNature][0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [businessNature]);

	const renderBusinessNatures = () => {
		return (
			<ToggleButtons
				exclusive
				label={'INSURANCE_STEP_APP_BUSINESS_NATURE_QUES'}
				dataSrc={INSURANCE_BUSINESS_NATURES_DATA_SRC}
				onValueChange={(value) => {
					formik.setFieldValue('nature', value);
				}}
				value={formik.values.nature}
			/>
		);
	};

	const renderBusinessDetails = () => {
		if (businessNature === BUSINESS_TYPE.OTHERS) {
			return (
				<BasicTextInput
					label={'STEP_BUSINESS_OTHERS_SPECIFY'}
					value={formik.values.otherSpecify}
					onChange={(e) => {
						formik.setFieldValue('otherSpecify', e.target.value);
					}}
					error={formik.touched.otherSpecify && Boolean(formik.errors.otherSpecify)}
					helperText={formik.errors.otherSpecify}
				/>
			);
		}

		return (
			<BasicAutoComplete
				label={'PLEASE_SPECIFY'}
				placeholder={'PLEASE_SPECIFY'}
				options={businessNatureDataSrc}
				onChange={(value) => {
					formik.setFieldValue('listSpecify', value);
				}}
				value={formik.values.listSpecify}
				error={formik.touched.listSpecify && Boolean(formik.errors.listSpecify)}
				helperText={formik.errors.listSpecify}
			/>
		);
	};

	const renderWebsiteRequest = () => {
		return (
			<>
				<Grid item>
					<ToggleButtons
						exclusive
						className={classes.marginTop}
						label={'STEP_BUSINESS_WEB_LINK_QUES_LBL'}
						dataSrc={[
							{ label: 'YES', value: true },
							{ label: 'NO', value: false }
						]}
						customStyle={{ gridTemplateColumns: '1fr 1fr' }}
						onValueChange={(value) => {
							if (value === false) {
								formik.setFieldValue('url', '');
							}
							formik.setFieldValue('hasUrl', value);
						}}
						value={formik.values.hasUrl}
					/>
				</Grid>

				{formikHasUrl && (
					<Grid item>
						<BasicTextInput
							label={'STEP_BUSINESS_WEB_LINK_LBL'}
							value={formik.values.url}
							onChange={(e) => {
								formik.setFieldValue('url', e.target.value);
							}}
							error={formik.touched.url && Boolean(formik.errors.url)}
							helperText={formik.errors.url}
						/>
					</Grid>
				)}
			</>
		);
	};

	return (
		<Grid item>
			<Grid container spacing={2} direction="column" justifyContent="space-evenly">
				<Grid item xs={12}>
					<Typography variant="h6" className={classes.title}>
						{t('INSURANCE_STEP_APP_BUSINESS_DETAILS_LBL')}
						<Divider className={classes.divider} />
					</Typography>
				</Grid>
				<Grid item xs={12}>
					{renderBusinessNatures()}
				</Grid>
				<Grid item xs={12}>
					{renderBusinessDetails()}
				</Grid>
				{renderWebsiteRequest()}
				<Grid item xs={12}>
					<NavigationButtons handlePrev={handlePrev} handleNext={handleNext} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default BusinessNature;
