/* eslint-disable max-len */
export const FOOTER_PERSONAL = [
	{ title: 'Bank Transfers', link: 'personalRetailLink' },
	{ title: 'Mobile Wallets Transfers', link: 'personalRetailLink' },
	{ title: 'Cash Pickup', link: 'personalRetailLink' },
	{ title: 'Insurance', link: 'insuranceLink' },
	{ title: 'Referral Program', link: 'referralProgramLink' }
];

export const FOOTER_BUSINESS = [
	{ title: 'Business' },
	{ title: 'Transfer', link: 'businessRetailLink' },
	{ title: 'Insurance', link: 'insuranceLink' }
];

export const FOOTER_ENTERPRISE = [{ title: 'Enterprise' }, { title: 'Payment Network', link: 'enterpriseLink' }];

export const FOOTER_RESOURCES = [
	{ title: 'Resources' },
	{ title: 'API Reference', link: 'apiRefLink' },
	{ title: 'BEFTN Routing Number', link: 'beftnLink' },
	{ title: 'IFSC Codes', link: 'ifscLink' }
];

export const FOOTER_COMPANY = [
	{ title: 'Company' },
	{ title: 'About Us', link: 'aboutLink' },
	{ title: 'Careers', link: 'careersLink' },
	{ title: 'News and Media', link: 'newsLink' },
	{ title: 'Blog', link: 'blogLink' },
	{ title: 'Contact Us', link: 'contactUsLink' }
];

export const FOOTER_LEGAL = [
	{ title: 'Legal' },
	{ title: 'Terms of Service', link: 'privacyLink' },
	{ title: 'Privacy Policy', link: 'privacyLink' }
];

export const SOCIAL_MEDIA_ICON_LINKS = [
	{ img: 'https://assets.moneymatch.co/images/linkedin_logo.png', link: 'linkedInLink' },
	{ img: 'https://assets.moneymatch.co/images/facebook_logo.png', link: 'fbLink' },
	{ img: 'https://assets.moneymatch.co/images/twitter_logo.png', link: 'twitterLink' },
	{ img: 'https://assets.moneymatch.co/images/instagram_logo.png', link: 'instaLink' }
];

// ---------------------------------- HEADER NAVBAR STARTS HERE-------------------------------------------------------
export const LANDING_HEADER_SUPPORT = {
	title: 'Support',
	link: 'supportCenterLink'
};

export const LANDING_HEADER_PROMOTIONS = {
	title: 'Promotions',
	link: 'promotionsLink'
};

export const LANDING_HEADER_PRODUCTS = [
	{
		title: 'Personal',
		components: [
			{ title: 'Bank Transfers', link: 'personalRetailLink' },
			{ title: 'Mobile Wallet Transfers', link: 'personalRetailLink', propsLink: '#platform' },
			{ title: 'Cash Pickup', link: 'personalRetailLink' },
			{ title: 'Insurances', link: 'insuranceLink' },
			{ title: 'Referral Program', link: 'referralProgramLink' }
		]
	},
	{
		title: 'Business',
		components: [
			{ title: 'Transfer', link: 'businessRetailLink' },
			{ title: 'Insurance', link: 'insuranceLink' }
		]
	},
	{
		title: 'Enterprise',
		components: [{ title: 'Payment Network', link: 'enterpriseLink' }]
	}
];

export const LANDING_HEADER_COMPANY = [
	{
		title: 'Company',
		components: [
			{ title: 'About Us', link: 'aboutLink' },
			{ title: 'Careers', link: 'careersLink' },
			{ title: 'News and Media', link: 'newsLink' },
			{ title: 'Blog', link: 'blogLink' },
			{ title: 'Contact Us', link: 'contactUsLink' }
		]
	},
	{
		title: 'Resources',
		components: [
			{ title: 'API Reference', link: 'apiRefLink' },
			{ title: 'BEFTN Routing Number', link: 'beftnLink' },
			{ title: 'IFSC Codes', link: 'ifscLink' }
		]
	}
];
// ---------------------------------- HEADER NAVBAR ENDS HERE-------------------------------------------------------

// TO-DO REMOVE BELOW IF ITS UNUSED ONCE EVERYTHING IS COMPLETED
export const FAQ = [
	{
		title: 'What are the insurances offered?',
		subtitle: 'You can get insured with these insurances:',
		desc: 'Employee Benefits such as Group Hospitalization and Surgical, Fire insurance, Flood insurance, Burglary Insurance, Public Liability Insurance & other customisable insurances.'
	},
	{
		title: 'Who are the insurance providers?',
		subtitle: 'Our insurance providers include:',
		desc: 'PruBSN,  Allianz Life, Allianz General, and more to come!'
	},
	{
		title: 'Do we offer Takaful products?',
		desc: 'Yes, we offer Takaful insurance policies that are Shariah compliant such as Takaful Life or Medical Insurance. Our team consists of MTA registered Takaful agents, authorised to promote and distribute Takaful products.'
	},
	{
		title: 'What is the premium charged?',
		desc: 'The premium is calculated based on the insurance policy selected. You can contact our friendly Customer Relationship Manager for more information. Alternatively, you can send us your queries to <0>insurance@moneymatch.co.</0>'
	}
];

export const COMPANY_SIZE_DROPDOWN = ['Less than 50 employees', '50-99 employees', '100-999 employees', '1000+ employees'];

export const CUSTOMIZED_PERSONAL_PLAN_LINKS = [
	'https://getquote.allianz.com.my/motorcycle-plus/?utm_source=MOMTCH',
	'https://getquote.allianz.com.my/motor-online/?utm_source=MOMTCH',
	'https://getquote.allianz.com.my/travel-care-plus/get-info?utm_source=MOMTCH',
	'https://getquote.allianz.com.my/smart-home-cover/?utm_source=MOMTCH',
	'https://getquote.allianz.com.my/lifestyle-protect/?utm_source=MOMTCH',
	'https://getquote.allianz.com.my/shield-plus/get-info?utm_source=MOMTCH'
];

export const SOCIAL_MEDIA_LINKS = [
	'https://www.linkedin.com/company/moneymatchapp/',
	'https://www.facebook.com/moneymatchapp/',
	'https://twitter.com/moneymatchapp',
	'https://www.instagram.com/moneymatchapp/'
];
